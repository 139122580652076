<template>
    <div class="admintitlebar">
        <v-row  justify="space-between">
            <h1>{{title}}</h1>
        </v-row>
        <v-row id='statusMessagesBar'>
      
            <v-spacer></v-spacer>
            <v-col v-if="actions" class='adminactionsbar text-right'>
                <span v-for="(item, idx) in actions" v-bind:key="'action-' + idx" >
                    <v-btn v-if="item.to" small :color="item.color || 'primary'" dark :to="item.to">{{item.text}}</v-btn>
                    <v-btn v-else-if="item.href" small :color="item.color || 'primary'" dark :href="item.href">{{item.text}}</v-btn>
                    <v-btn v-else small :color="item.color || 'primary'" dark @click="handleActionClick(item.key, item)">{{item.text}}</v-btn>
                </span>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "AdminTitleBar",
    props: {
        title: {  default: "Admin" },
        actions: { default: false }
    },
    data () {
        return {
            adminmenu: [
                "Admin Home",
                "Admin Users",
                "VCRM Contacts Integration",
                "Admin Uploaded Files"
            ]
        }
    },
    computed: {
        ...mapState([ 'statusMessages', 'remoteError' ]),
    },
    methods: {
        handleAdminMenu: function ( evt ) {

            let dest = {
                'Admin Home': "/",
                'Admin Users': "/admin_users",
                'VCRM Contacts Integration': "/admin_vcrm",
                'Admin Uploaded Files': "/admin_files"
            }
            if(!dest[evt] || dest[evt] == this.$route.path ) return;

            if(dest[evt] === "legacy"){
                location.href = (evt === 'Original Admin UI') ? "/admin" : "/";
            } else {
                this.$router.push({path: dest[evt]});
            }
        },
        handleActionClick: function (evt, item) {
          // console.log(evt, item);
            this.$emit('actionhandler', item.key);
        }
    }
}
</script>

