<template>
    <v-container>
        <AdminTitleBar
            title="VCRM Individual"
            :actions="titleBarActions"
            v-on:actionhandler="handleAction"
        />

        <v-tabs>
            <v-tab>Contact Individual</v-tab>
            <v-tab>Courses</v-tab>
            <v-tab>JSON</v-tab>

            <v-tab-item>
                <v-container v-if="fetching">
                    <v-card>
                        <v-card-text>Loading...</v-card-text>
                    </v-card>
                </v-container>
                <v-container v-else-if="individual && individual.id">
                    <v-card>
                        <v-card-text>
                            <v-row>
                                <div class="label">Contact ID</div>
                                <div class="value"><a :href="`https://app.virtuoussoftware.com/Generosity/Contact/View/${individual.contactId || individual.id}`" target="_blank">{{individual.contactId || individual.id}}</a></div>
                            </v-row>
                            <v-row>
                                <div class="label">Contact Individual Id</div>
                                <div class="value">{{individual.individual.id || individual.id}}</div>
                            </v-row>
                            <v-row>
                                <div class="label">First Name</div>
                                <div
                                    class="value"
                                >{{individual.individual.firstName || individual.firstName}}</div>
                            </v-row>
                            <v-row>
                                <div class="label">Last Name</div>
                                <div
                                    class="value"
                                >{{individual.individual.lastName || individual.lastName}}</div>
                            </v-row>
                            <v-row>
                                <div class="label">Birthdate</div>
                                <div
                                    class="value"
                                >{{individual.individual.birthDate || individual.birthDate}}</div>
                            </v-row>
                            <v-row>
                                <div class="label">Coram Deo Last Activity</div>
                                <div
                                    class="value"
                                >{{individual.individual["Coram Deo Last Activity"]}}</div>
                            </v-row>
                            <v-row>
                                <div class="label">Coram Deo Legacy Individual ID</div>
                                <div
                                    class="value"
                                >{{individual.individual["Coram Deo Legacy Individual ID"]}}</div>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-container>
                <v-container v-else-if="individual && !stage">
                    <v-card>
                        <v-card-text>
                            <p class="ma-2">
                                No Record found. You can try to sync this account with VCRM, which
                                will attempt to look up the Contact based on its email address. Or, you
                                can click the Add button to push this user in to VCRM's batch processing. If you Sync
                                and get an error, it means no matching contact was found.
                            </p>
                            <div class="ma-2">
                                <v-btn
                                    small
                                    color="primary"
                                    dark
                                    @click="handleAction('syncRecords', individualId)"
                                >Sync with VCRM</v-btn>
                            </div>
                            <div class="ma-2">
                                <v-btn
                                    small
                                    color="primary"
                                    dark
                                    @click="handleAction('addBatch', individualId)"
                                >Add to VCRM</v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-container>
                <v-container v-else-if="individual && stage === 'posted'">
                    <v-card>
                        <v-card-text>
                            <p class="ma-2">
                                The record has been posted to the
                                <a href="https://app.virtuoussoftware.com/Generosity/ContactTransaction" target="_blank" >VCRM Batch</a> processing.
                                The batch will need to be processed there.
                            </p>
                        </v-card-text>
                    </v-card>
                </v-container>
            </v-tab-item>

            <v-tab-item>
                <v-card>
                    <v-card-text>
                        <div v-if="courses">
                            <div v-for="item in courses" :key="item.customCollectionId" class='coursecontainer'>
                                <h3>{{ item.Course }}</h3>
                                <v-row>
                                    <div class="label">Enrolled:</div>
                                    <div class="value" >{{ item.Enrolled }}</div>
                                </v-row>
                                <v-row>
                                    <div class="label">Completed:</div>
                                    <div class="value" >{{ item.Completed }}</div>
                                </v-row>
                                <v-row>
                                    <div class="label">Certified:</div>
                                    <div class="value" >{{ item.Certified }}</div>
                                </v-row>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-tab-item>

            <v-tab-item>
                <v-card>
                    <v-card-text>
                        <h3>JSON</h3>
                        <v-row>
                            <pre>{{integrationVcrm.individual}}</pre>
                        </v-row>
                        <h3>User</h3>
                        <v-row>
                            <pre>{{ userRecord }}</pre>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs>
    </v-container>
</template>

<script>
import { mapState } from "vuex";
import AdminTitleBar from "./Admin_TitleBar";
import _find from "lodash/find";

export default {
    name: "AdminVcrmIndividual",
    props: {
        id: { type: [Object, Number, String] },
        userRecord: { type: [Object, Boolean] },
        stage: { type: [String, Boolean] }
    },
    components: { AdminTitleBar },
    data() {
        return {
            individualId: this.id,
            fetching: false,
            // userRecord: false,
            titleBarActions: [
                {
                    text: "Sync Data",
                    key: "syncRecords"
                },
                {
                    text: "Close",
                    key: "close"
                }
            ]
        };
    },
    computed: {
        ...mapState(["authError", "currentUser", "userList", "integrationVcrm"]),
        individual: function() {
            if (!this.integrationVcrm.individual) return false;
            let contact = Object.assign({}, this.integrationVcrm.individual);
          // console.log("individual is ", contact);

            // sometimes this is the contact, with individuals within it, and sometimes its already just the individual
            if (contact.contactIndividuals) {
                var idx;
                for (let i in contact.contactIndividuals) {
                    if (contact.contactIndividuals[i].id == this.id) {
                        idx = i;
                      // console.log("found", i, "matches", this.id);
                        break;
                    } else {
                        for (let a in contact.contactIndividuals[i]
                            .contactMethods) {
                            if (
                                contact.contactIndividuals[i].contactMethods[a]
                                    .value === this.id
                            ) {
                                idx = i;
                              // console.log("found", i, "matches", this.id);
                                break;
                            }
                        }
                    }
                  // console.log("It's not", i, this.id);
                }
            }

            if (
                idx &&
                contact.contactIndividuals &&
                contact.contactIndividuals[idx]
            ) {
                contact.individual = contact.contactIndividuals[idx];

                for (let cf in contact.individual.customFields) {
                    contact.individual[
                        contact.individual.customFields[cf].name
                    ] = contact.individual.customFields[cf].value;
                }
            } else {
                contact.individual = contact;
            }

            for (let cf in contact.customFields) {
                contact[contact.customFields[cf].name] =
                    contact.customFields[cf].value;
            }

            return contact;
        },
        courses: function() {
            if (!this.integrationVcrm.individual || !this.integrationVcrm.individual.customCollections || !this.integrationVcrm.individual.customCollections.length) return false;
            let outlist = [], collectionslength = this.integrationVcrm.individual.customCollections.length;
            for(let i=0;i<collectionslength;i++){
                let collection = this.integrationVcrm.individual.customCollections[i];
                let course = {
                    Id: collection.collectionInstanceId,
                    Course: "",
                    Enrolled: "",
                    Completed: "",
                    Certified: ""
                };
                for(let k in collection.fields){
                    course[collection.fields[k].name] = collection.fields[k].value;
                }
                outlist.push(course);
            }
            return outlist;
        }
    },
    methods: {
        getRecord: function(id) {
            this.fetching = true;
            id = id || this.individualId;
            if (typeof id === "object") {
                // this.userRecord = id;
                this.individualId = id.email;
            } else {
                // let user = _find(this.userList, { email: id });
                // if (user) this.userRecord = user;
            }

            this.$store.dispatch("integrationVcrm/getIndividual", id)
            .then(() => {
                this.fetching = false;
            });
        },
        handleAction: function(action, params) {
            this.$emit("actionhandler", action, params);
        }
    },
    created() {
        this.getRecord();
    }
};
</script>

<style scoped>
.label {
    width: 260px;
    font-weight: bold;
}
.v-card__text {
    margin: 0.8rem;
}
.coursecontainer {
    display: block;
    margin-bottom: 16px;
    padding-bottom: 12px;
    border-bottom: 1px solid #bbb;
}
.coursecontainer .row {
    padding-top: 12px;
    margin-left: 16px;
}
</style>