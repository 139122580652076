<template>
    <v-container>
        <div v-if="authError">
            {{ loc('Please Login') }}
        </div>
        <div v-else-if="!users.currentUser || !users.currentUser.isAdmin" >
            {{ loc("You do not have permission to do that.") }}
        </div>
        <div v-else>
            <div v-if="isLoading">
                {{ loc('Loading...') }}
            </div>
            <div v-else-if="panel === 'Responses'">
                <AdminSurveysResponses :surveyId="subid"/>
            </div>
            <div v-else-if="panel === 'editRecord'">
                <AdminSurveysEdit :record="editRecord" v-on:actionhandler="handleAction" />
            </div>
            <div v-else-if="panel === 'removeRecord'">
                <AdminSurveysRemove :record="editRecord" v-on:actionhandler="handleAction" />
            </div>            
            <div v-else>
                <AdminSurveysList v-on:actionhandler="handleAction" :viewmain="viewmain" :viewlanguage="viewlanguage" />
            </div>
        </div>
        <!-- <v-row class='mt-4'><pre>{{panel}}</pre></v-row>
        <v-row class='mt-4'><pre>{{editRecord}}</pre></v-row> -->
    </v-container>
</template>

<script>
import { mapState } from "vuex";
// import config_system from '../config/system';
// import AdminTitleBar from "./Admin_TitleBar";
import AdminSurveysList from "./Admin_Surveys_List";
import AdminSurveysEdit from "./Admin_Surveys_Edit";
import AdminSurveysRemove from "./Admin_Surveys_Remove";
import AdminSurveysResponses from "./Admin_Surveys_Responses.vue";
import _filter from 'lodash/filter';
import { filterId } from '../lib/filter';

export default {
    name: "AdminSurveys",
    components: { AdminSurveysEdit, AdminSurveysList, AdminSurveysRemove, AdminSurveysResponses},
    data() {
        return {
            isLoading: true,
            panel: false,
            editRecord: false,
            viewlanguage: false,
            viewmain: false,
            subid: false
        }
    },
    computed: {
        ...mapState(["authError", "users", "surveys", "localize"]),
    },
    methods: {
        getList: function(editid) {
            this.$store.dispatch("surveys/getList")
                .then((result) => {
                    let pathMatch =(this.$route.params && this.$route.params.pathMatch);
                    if(editid || pathMatch){ 
                        let path = pathMatch.split('/');
                        let id = editid || filterId(path[0]);
                        this.subid = filterId(path[1]) || false;
                        console.log('getList checking path', pathMatch, path, id);
                        if(id === 'responses'){
                            this.panel = 'Responses';
                        } else if(id === 'new'){
                            this.handleAction('add')
                        } else if(id && this.surveys.list[id]){
                            console.log("Found it?", this.surveys.list[id]);
                            this.handleAction('edit', this.surveys.list[id]);
                            // this.editRecord = this.surveys.list[id];
                        }
                    }                    
                    this.isLoading = false;
                })
                .catch((e) => {
                    console.log("error getting list of surveys", e);
                })
        }, 
        handleAction: function(action, params, opts) {
            switch(action){
                case 'close':
                    {
                        console.log('closing');
                        this.editRecord = false;
                        this.panel = false;
                        let newpath = '/admin/surveys';
                        console.log(this.$route.path,'to', newpath,'?')
                        if(this.$route.path !== newpath) this.$router.push(newpath);
                    }
                case 'edit':
                    if(params && this.surveys.list[params._id]){
                        this.$store.dispatch('surveys/get', params)
                            .then(() => {
                                this.panel = 'editRecord';
                                this.editRecord = params;
                                let newpath = '/admin/surveys/'+params._id;
                                if(this.$route.path !== newpath) this.$router.push(newpath);
                            })
                    }
                    break;
                case 'remove':
                    if(params && this.surveys.list[params._id]){
                        this.editRecord = this.surveys.list[params._id];
                        this.panel = 'removeRecord';
                    } 
                    break;    
                case 'removeConfirmed':
                    {
                        this.$store.dispatch('surveys/remove', params)
                            .then((result) => {
                                if(result){
                                    this.handleAction('close');
                                    this.$store.commit("surveys/getList", {reset: true});
                                }
                            })
                    }                    
                    break;    
                case 'add':
                    {
                        console.log("Add a survey");
                        this.editRecord = { _id: 'new' };
                        this.panel = 'editRecord';
                        let newpath = "/admin/surveys/new";
                        if(this.$route.path !== newpath){
                            this.$router.push(newpath);
                        }
                    }  
                    break;  
                case 'save':    
                    console.log("Save survey", params, opts);
                    this.$store.dispatch("surveys/save", params )
                        .then((result) => {
                            if(opts){ 
                                this.handleAction('close', params, opts);
                            } else if(result) {
                                let newpath = "/admin/surveys/"+result._id;
                                if(this.$route.path !== newpath){
                                    this.$router.push(newpath);
                                }   
                            } else {
                                this.handleAction('close');
                            }
                        });
                    break;                    
                }                                                  
        },
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },  
        init() {
            if(this.surveys.list){
                this.isLoading = false;
                let pathMatch =(this.$route.params && this.$route.params.pathMatch);
                if(pathMatch){   
                    let path = pathMatch.split('/');
                    let id = filterId(path[0]);
                    this.subid = filterId(path[1]) || false;
                    console.log("Admin_Surveys ID is ", id)
                    if(id === 'responses'){
                        this.panel = 'Responses';
                    } else if(id === 'new'){
                        this.handleAction('add')
                    } else if(id && this.surveys.list[id]){
                        this.handleAction('edit', this.surveys.list[id]);
                        // this.editRecord = this.surveys.list[id];
                    } else {
                        this.handleAction('close');
                    }
                } else {
                    this.handleAction('close')
                }
            } else {
                this.isLoading = true;
                this.getList();
            }
        },
    },
    created() {
        console.log("Creating Admin.Surveys", this.$route)
        this.isLoading = true;
        this.init()
    },
    watch: {
        '$route.params.id': {
            handler: function(s){
                console.log("route watcher triggered", s, this.$route);
                this.init()
            },
            deep: true,
            immediate: false
        }
    }
}
</script>