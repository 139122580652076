var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AdminTitleBar',{attrs:{"title":_vm.loc('Manage Lessons') + ' - ' + _vm.course.title,"actions":_vm.titleBarActions},on:{"actionhandler":_vm.handleAction}}),(_vm.tablePrefs)?_c('v-row',_vm._l((_vm.headers),function(item,idx){return _c('div',{key:idx},[_c('v-checkbox',{staticStyle:{"min-width":"120px"},attrs:{"name":idx,"label":_vm.loc(item.text)},model:{value:(item.show),callback:function ($$v) {_vm.$set(item, "show", $$v)},expression:"item.show"}})],1)}),0):_vm._e(),(_vm.recordList)?_c('div',[_c('v-row',{staticClass:"searchrow"},[_c('v-select',{attrs:{"items":_vm.courseSelect,"value":_vm.course && _vm.course._id,"label":"Course"},on:{"change":_vm.handleCourseChange}}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.loc('Search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"courseList elevation-1 my-8 pb2",attrs:{"items-per-page":_vm.itemsPerPage,"headers":_vm.tableHeaders,"footer-props":{ itemsPerPageOptions:[10,20,30,40,50,100,200] },"items":_vm.lessonList,"search":_vm.search},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'/lessons/' + item.course + '/' + item._id + '/selfpaced'}},[_vm._v(_vm._s(item.title))])]}},{key:"header.actions",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('span',{staticClass:"dtableactionicon",on:{"click":function($event){_vm.tablePrefs=!_vm.tablePrefs}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("edit")]),_vm._v(" "+_vm._s(_vm.loc("Columns")))],1)]}},{key:"item.course",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.course && (_vm.course._id === item.course) && _vm.course.title)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"manageusericons"},[_c('v-btn',{staticClass:"mr-1",attrs:{"small":"","color":"secondary","dark":"","to":'/admin/lessonmaterials/' + item.course + '/' + item._id}},[_vm._v(_vm._s(_vm.loc("Materials")))]),_c('v-btn',{staticClass:"mr-1",attrs:{"small":"","color":"primary","dark":""},on:{"click":function($event){return _vm.handleEdit(item)}}},[_vm._v(_vm._s(_vm.loc("Edit")))]),_c('v-btn',{attrs:{"small":"","color":"error","dark":""},on:{"click":function($event){return _vm.handleRemove(item)}}},[_vm._v(_vm._s(_vm.loc("Remove")))])],1)]}}],null,true)})],1):_c('div',[_vm._v(" "+_vm._s(_vm.loc('No data yet.'))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }