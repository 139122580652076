<template>
    <v-container>
        <div v-if="authError">
            {{ loc('Please Login') }}
        </div>
        <div v-else-if="!users.currentUser || !users.currentUser.isAdmin" >
            {{ loc("You do not have permission to do that.") }}
        </div>    
        <div v-else-if="isLoading">
            {{ loc('Loading...') }}
        </div>  
        <div v-else>
            <AdminTitleBar
                v-if="titleBarActions"
                :title="loc('Manage Comments')"
                :actions="titleBarActions"
                v-on:actionhandler="handleAction"
            />
            <!-- <div v-if="isRemoving && comments.list[isRemoving]">
                <h1>{{ loc('Confirm Comment Removal') }}</h1>
                <v-card class="mb-10">
                    <v-card-text class="removeconfirmation">
                        <v-row>                
                            <div class="label">{{ loc('ID') }}</div>
                            <div class="value">{{comments.list[isRemoving]._id}}</div>
                        </v-row>   
                        <v-row>                
                            <div class="label">{{ loc('Author ID') }}</div>
                            <div class="value">{{comments.list[isRemoving].author || ""}}</div>
                        </v-row>   
                       <v-row>                
                            <div class="label">{{ loc('Author') }}</div>
                            <div class="value">{{comments.list[isRemoving].authorname || ""}}</div>
                        </v-row>    
                       <v-row>                
                            <div class="label">{{ loc('Comment') }}</div>
                            <div class="value">{{comments.list[isRemoving].body || ""}}</div>
                        </v-row>                            
                        <v-row class='actionrow'>
                            <v-btn small color="error" dark @click="handleAction('remove', comments.list[isRemoving])">{{ loc('Remove Record') }}</v-btn>
                            <v-btn small color="secondary" dark @click="handleAction('close')">{{ loc('Cancel') }}</v-btn>
                        </v-row> 
                    </v-card-text>
                </v-card>
            </div> -->
            <div >
                <v-row >
                    <h3 v-if="courseId" class="pt-4">{{course.title}} ({{course.reftitle}}) {{ (classRecord && (" - " + classRecord.name)) || "" }} </h3>
                    <v-select v-if="!courseId" :items="courseSelect" v-bind:value="courseId" label="Course" @change="handleAction('courseSelected', $event)" class='mr-2'></v-select>
                    <v-spacer></v-spacer>
                    <v-select v-if="courseId && !classId" :items="classSelect" v-bind:value="classId" label="Class" @change="handleAction('classSelected', $event)" class='mr-2' style="max-width: 300px;" ></v-select>
                </v-row>
                <v-row class="searchrow">
                    <v-select :items="lessonSelect" v-bind:value="lessonId" label="Lesson" @change="handleAction('lessonSelected', $event)" class='mr-2'  ></v-select>
                    <v-select v-if="lessonId" :items="materialSelect" v-bind:value="parentId" label="Material" @change="handleAction('parentSelected', $event)" class='mr-2'  ></v-select>
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        append-icon="search"
                        :label="loc('Search')"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-row>
                <v-data-table
                    :items-per-page="itemsPerPage"
                    :headers="tableHeaders"
                    :footer-props="{ itemsPerPageOptions:[10,20,30,40,50,100,200] }"
                    :items="recordList"
                    :search="search"
                    item-key="_id"
                    :expanded.sync = "expanded"
                    class="commentsList elevation-1 my-8 pb2"
                >
                    <template v-slot:[`item.body`]="{ item }" class="commentbody"> 
                        <div v-if="isSaving === item._id">
                            <h3>{{ loc("Saving...") }}</h3>
                        </div>
                        <div v-else-if="isRemoving && isRemoving === item._id" class='py-6' >
                            <v-row> 
                                <div class="label">{{ loc('ID') }}</div>
                                <div class="value">{{comments.list[isRemoving]._id}}</div>
                            </v-row>   
                            <v-row>                
                                <div class="label">{{ loc('Author ID') }}</div>
                                <div class="value">{{comments.list[isRemoving].author || ""}}</div>
                            </v-row>   
                            <v-row class='actionrow pt-8' >
                                <v-btn small color="error" dark @click="handleAction('remove', comments.list[isRemoving])">{{ loc('Yes, Remove Record') }}</v-btn>
                                <v-btn small color="secondary" dark @click="handleAction('close')">{{ loc('Cancel') }}</v-btn>
                            </v-row> 
                        </div>
                        <div v-else-if="isEditing && isEditing._id === item._id" >
                            <v-textarea name="post" :label="loc('Comment')" v-model="isEditing.body"></v-textarea>
                            <div v-if="!isSaving">
                                <v-btn color="primary" class="mr-4" @click="handleBodySave(isEditing)">{{ loc("Save") }}</v-btn> 
                                <v-btn @click="isEditing = false; editingBody = false">{{ loc("Cancel") }}</v-btn> 
                            </div>
                        </div>
                        <div v-else v-html="item.body"></div>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }" class="actioncolumn">
                        <span class="manageusericons" v-if="isEditing._id !== item._id && isRemoving !== item._id">
                            <v-btn small color="primary" dark @click="isEditing = item" class="mr-1">{{ loc("Edit") }}</v-btn>
                            <v-btn small color="error" dark @click="isRemoving = item._id">{{ loc("Remove") }}</v-btn>
                        </span>
                    </template>   
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" class="pa-6">
                            <v-row v-for="(reply) in item.replies" :key="reply._id">
                                <v-col>{{reply.authorname}}</v-col>
                                <v-col>{{reply.author}}</v-col>
                                <v-col>{{ filterDate(reply.timestamp) }}</v-col>
                                <v-col cols="4" v-if="reply._id === isEditingReply._id">
                                    <v-textarea name="newpost" :label="loc('Reply')" v-model="isEditingReply.body"></v-textarea>

                                </v-col>
                                <v-col cols="4" v-else>{{reply.body}}</v-col>
                                <v-col>
                                    <v-btn small color="primary" dark @click="isEditingReply = reply" class="mr-1">{{ loc("Edit") }}</v-btn>
                                    <v-btn small color="error" dark @click="isRemovingReply = item">{{ loc("Remove") }}</v-btn>                                    
                                </v-col>
                            </v-row>
                        </td>
                    </template>  
                    <template v-slot:[`item.expand`]="{ item }" style="white-space: nowrap">
                        {{ (item.replies && item.replies.length ) || 0  }} 
                        <v-icon right @click="toggleDrawer(item)" v-if="(item.replies && item.replies.length ) && !expanded.includes(item)">expand_more</v-icon>
                        <v-icon right @click="toggleDrawer()" v-if="expanded.includes(item)">expand_less</v-icon>
                    </template>                                            
                </v-data-table>  
            </div>         
            <div class="d-none">
                <v-row> Course {{courseId}} </v-row>
                <v-row> Lesson {{lessonId}} </v-row>
                <v-row >Class {{classId}} </v-row>
                <v-row> Parent {{parentId}} </v-row>
                <v-row> isRemoving <pre>{{isRemoving}}</pre> </v-row>
                <v-row ><pre>{{recordList}}</pre></v-row>
            </div> 
        </div>
    </v-container>            
</template>

<script>
import { mapState } from "vuex";
import AdminTitleBar from "./Admin_TitleBar";
// import SharedEditorCode from "./Shared_Editor_Code";
import { filterId, filterDateTime } from '../lib/filter';
import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';

var timers = {};
var courseretries = 0, classretries = 0;

export default {
    name: "AdminCommentsList",
    components: { AdminTitleBar },
    data (){
        return {
            isLoading: true,
            isEditing: false,
            isEditingReply: false,
            isRemoving: false,
            isRemovingReply: false,
            editingBody: false,
            isSaving: false,
            panel: false,
            parentId: false,
            courseId: false,
            course: false,
            lessonId: false,
            classId: false,
            classRecord: false,
            schema: {},
            search: "",
            itemsPerPage: 30,
            tablePrefs: false,
            showbody: false,
            expanded: [],
            titleBarActions:[
                { text: "Refresh", key: "refresh" }
            ],
            headers: {
                _id: {
                    text: "ID",
                    value: "_id"
                },
                subject: {
                    text: "Subject",
                    value: "subject", 
                    sortable: true,
                    align: "start",
                    show: false
                },
                lang: {
                    text: "Lang",
                    value: "lang", 
                    sortable: true,
                    align: "start",
                    show: false                    
                },
                author: {
                    text: "Author ID",
                    value: "author", 
                    sortable: true,
                    align: "start",
                    show: false                    
                },
                authorname: {
                    text: "Author",
                    value: "authorname", 
                    sortable: true,
                    align: "start",
                    show: true                    
                },
                access: {
                    text: "Access",
                    value: "access", 
                    sortable: true,
                    align: "start",
                    show: false                    
                },
                timestamp: {
                    text: "Time",
                    value: "timestamp", 
                    sortable: true,
                    align: "start",
                    show: true                    
                },
                body: {
                    text: "Body",
                    value: "body", 
                    sortable: true,
                    align: "start",
                    show: true                    
                },                
            }
        }
    },
    computed: {
        ...mapState(["authError", "users", "localize", "adminstore", "comments", "courses", "classes", "lessons"]),
        tableHeaders: function() {
            var temp = Object.assign({}, this.headers);
            let columns = []
            for(let k in temp){
                if(temp[k].show){
                    temp[k].sortable = true;
                    temp[k].align = temp[k].align || "start";
                    columns.push(temp[k]);
                }
            }
            columns.push({
                text: 'Replies',
                value: 'expand',
                align: 'end',
                sortable: false
            });            

            columns.push({ text: "Actions", sortable: false, value: "actions" });
            return columns;   
        },        
        courseSelect: function(){
            let list = [];
            for(let k in this.courses.list){
                list.push({value: this.courses.list[k]._id, text: this.courses.list[k].reftitle + " - " + this.courses.list[k].title});
            }
            return list;            
        },
        lessonSelect: function(){
            let list = [];
            if(!this.courseId){
                return [{text: "Please Select a Course", value: false}]
            } else if(this.lessons && this.lessons.list) {
                list = _filter(this.lessons.list, {course: this.courseId});
                list = _sortBy(list, 'sortorder');
                // console.log("building lessonSelect", list);
                for(let i in list){
                    list[i] = {
                        text: list[i].order + " " +list[i].title,
                        value: list[i]._id
                    }
                }
            }
            return list;
        },
        materialSelect: function(){ 
            let list = [];
            if(!this.lessonId){
                return [{text: "Please Select a Lesson", value: false}]
            } else if(this.lessons && this.lessons.materialslist) {
                list = _filter(this.lessons.materialslist, {lesson: this.lessonId});
                console.log("building materialSelect", list);
                for(let i in list){
                    list[i] = {
                        text: list[i].order + " " + list[i].section + " " + list[i].title,
                        value: list[i]._id
                    }
                }
            }            
            return list;
        },
        classSelect: function(){
            if(!this.courseId){
                return [{text: "Please Select a Course", value: false}]
            } else if(!this.classes || !this.classes.list) {
                return [];
            } else {
                let list = _filter(this.classes.list, {course: this.courseId});
                let out = [];
                let selfpaced = [];
                for(let i in list){
                    if(list[i].selfpaced){
                        selfpaced.push({
                            text: list[i].name,
                            value: list[i]._id                            
                        })
                    } else {
                        out.push({
                            text: list[i].name,
                            value: list[i]._id
                        });
                    }
                }
                _sortBy(out, 'text');
                list = selfpaced.concat(out);
                return list;
            }
        },
        recordList: function(){
            return this.parentId ? _filter(this.comments.list, {parent: this.parentId}) : []
            // return Object.values(this.comments.list);
        }
    },
    methods: {
        loadComments: function () {
            console.log("Admin.loadComments");
            this.$store.dispatch("comments/getList", {parent: this.parentId, limit: 1000})
                .then((result) => {
                    console.log("comments/getList returned", result);
                    this.isLoading = false;
                })
        },
        loadLessons: function () {
            this.$store.dispatch("lessons/getList", {shortfields: true})
                .then((result) => {
                    console.log("lessons/getList returned", result);
                });
        },
        loadMaterials: function () {
            this.$store.dispatch("lessons/listMaterials", {shortfields: true})
                .then((result) => {
                    console.log("lessons/listMaterials returned", result);
                });
        },
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },
        handleAction: function(action, params, opts) {
            console.log("admin comments handleAction", action, params, opts);
            switch(action){
                case 'close':
                    {
                        this.isRemoving = false;
                        this.isSaving = false;
                        this.isEditing = false;
                        this.editingBody = false;
                    }
                    break;
                case 'courseSelected':
                    {
                        this.courseId = params;
                        let newpath = '/admin/comments/'+this.courseId
                        if(newpath !== this.$route.path) this.$router.push(newpath);
                        this.init(newpath);
                    }
                    break;
                case 'lessonSelected':
                    {
                        if(params === this.lessonId) return;
                        this.lessonId = params;
                        this.parentId = false;
                        let newpath = '/admin/comments/'+this.courseId+"/"+ this.lessonId+"/"+this.parentId+"/"+this.classId;
                        if(newpath !== this.$route.path) this.$router.push(newpath);
                        this.init(newpath);
                    }
                    break;
                case 'parentSelected':
                    {
                        this.parentId = params;
                        let newpath = '/admin/comments/'+this.courseId+"/"+ this.lessonId+"/"+this.parentId+"/"+this.classId;
                        if(newpath !== this.$route.path) this.$router.push(newpath);
                        this.init(newpath);
                    }
                    break;                    
                case 'classSelected':
                    {
                        this.classId = params;
                        let newpath = '/admin/comments/'+this.courseId+"/"+ this.lessonId+"/"+this.parentId+"/"+this.classId;
                        if(newpath !== this.$route.path) this.$router.push(newpath);
                        this.init(newpath);
                    }
                    break;
                case 'remove':
                    {
                        this.isSaving = params
                        this.$store.dispatch("comments/remove", params)
                    }
            }

        },
        handleBodyChange: function( a, b, c, d){
            this.editingBody = a;
        },
        handleBodySave: function(item) {
            if(item){
                console.log("Saving", item);
                this.isSaving = true;
                this.$store.dispatch("comments/savePost", item)
                    .then((result) => {
                        console.log("response", result);
                        this.handleAction('close');
                    })
            } else {
                this.handleAction('close');
                // this.isEditing = false;
                // this.editingBody = false;
            }
        },
        toggleDrawer: function ( job ) {
            job = job || undefined;
            // this.$root.$set(this.expanded, (job && [job]) || []);
            if(job){
                this.expanded =  [job];
            } else {
                this.expanded.splice(0);
            }

        },
        filterDate: function(value){
            return filterDateTime(value);
        },
        init: function(path){
            path = path || this.$route.params.pathMatch;
            
            console.log("admin comments init", path);

            if(path && typeof path === "string"){
                let p = path.split('/');
                if(p[0] === '') p.shift()
                if(p[0] === 'admin') p.shift();
                if(p[0] === 'comments') p.shift();
                
                console.log('path', p);
                this.courseId = (p[0]) ? filterId(p[0]) : null;
                this.lessonId = (p[1]) ? filterId(p[1]) : null;
                this.parentId = (p[2]) ? filterId(p[2]) : null;                
                this.classId  = (p[3]) ? filterId(p[3]) : null;                
            }

            if(this.courseId && (!this.course || this.courseId !== this.course._id)){ 
                this.course = this.courses.list[this.courseId];
                console.log('cannot find course', this.courseId, this.courses.list[this.courseId], this.courses.list);
                if(!this.course){ 
                    if(courseretries < 3){
                        courseretries++;
                        console.log("Retrying course", this.courseId);
                        setTimeout(() => { this.init(path); }, 300);
                        return;
                    }
                    this.courseId = false;
                }
            }

            if(this.classId && (!this.classRecord || this.classId !== this.classRecord._id) ){
                this.classRecord = this.classes.list[this.classId];
                if(!this.classRecord){ 
                    if(classretries < 3){
                        classretries++;
                        console.log("Retrying class", this.classId);
                        setTimeout(() => { this.init(path); }, 300);
                        return;
                    }
                    this.classId = false; 
                }
            }

            console.log('comments.init', path, this.courseId, this.lessonId, this.parentId, this.classId)

            this.loadComments();
            this.loadLessons();
            if(this.lessonId) this.loadMaterials();
        }        
    },
    created() {
        this.init();
    },
    watch: {
        '$route.params.id': {
            handler: function(s){
                console.log("comments watcher triggered", s, this.$route);
                let context = s || (this.$route.params && this.$route.params.pathMatch);
                if(this.context !== context) this.init(context);
            },
            deep: true,
            immediate: false
        }
    }
    
}
</script>
