<template>
 <v-container>
        <AdminTitleBar
            :title="loc('Confirm Enrollment Removal')"
            :actions="titleBarActions"
            v-on:actionhandler="handleAction"
        />
        <v-card>
            <v-card-text class="removeconfirmation">
                <v-row>
                    <div class="label">{{ loc('ID') }}</div>
                    <div class="value">{{record._id || ""}}</div>
                </v-row>                
                <v-row >
                    <div class="label">{{ loc('User') }}</div>
                    <div class="value">{{userRecord.firstname}} {{userRecord.lastname}}</div>
                </v-row>
                <v-row >
                    <div class="label">{{ loc('Course') }}</div>
                    <div class="value">{{course.title}}</div>
                </v-row>                
                <v-row >
                    <div class="label">{{ loc('Class') }}</div>
                    <div class="value">{{classRecord.name}}</div>
                </v-row>                
                <v-row>
                    <div class="label">{{ loc('Registered') }}</div>
                    <div class="value">{{record.regdate || ""}}</div>
                </v-row>
                <v-row class='actionrow'>
                    <v-btn small color="error" dark @click="handleRemove()">{{ loc('Remove Record') }}</v-btn>
                    <v-btn small color="secondary" dark @click="handleAction('close')">{{ loc('Cancel') }}</v-btn>
                </v-row>                
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { mapState } from 'vuex';
import AdminTitleBar from "./Admin_TitleBar";

export default {
    name: "AdminEnrollmentsRemove",
    props: {
        record: { type: Object, default: () => ({}) },
        classRecord: { type: Object, default: () => ({}) },
        course: { type: Object, default: () => ({}) }
    },
    components: { AdminTitleBar },
    data() {
        return {
            titleBarActions: [
                {
                    text: "Close",
                    key: "close"
                }
            ]            
        }
    },
    computed: {
        ...mapState(['users', 'localize', 'courses']),
        userRecord: function(){
            if(!this.record || !this.users || !this.users.list ) return {};

            return this.users.list[this.record.user];
        }
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },    
        handleAction: function(action, params) {
            this.$emit("actionhandler", action, params);
        },
        handleRemove: function(){
            this.$emit("actionhandler", 'removeConfirmed', this.record);
        }              
    }
  
}
</script>