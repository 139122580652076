<template>
    <v-container>
        <div v-if="authError">
            {{ loc('Please Login') }}
        </div>
        <div v-else-if="!users.currentUser || !users.currentUser.isAdmin" >
            {{ loc("You do not have permission to do that.") }}
        </div>
        <div v-else>
       
            <div v-if="isLoading">
                {{ loc('Loading...') }}
            </div>
            <div v-else-if="!lessonId">
                <h1>{{loc('Manage Lesson Materials')}}</h1>
                <h2>{{loc('Please Select a Lesson')}}</h2>
                <ul class="CourseLinkList" id="MaterialsAdminLessonSelection">
                    <li v-for="(item, key) in lessonList" v-bind:key="key" :id="'Courselink-'+key">
                        <router-link :to="'/admin/lessons/'+item._id+'/materials'">{{item.course}} - {{item.title}}</router-link>
                    </li>
                </ul>
            </div>
            <div v-else-if="record && manageRecord === 'editRecord' && materialRecord">
                <AdminLessonMaterialsEdit :record="materialRecord" :lesson='record' :course="course" v-on:actionhandler="handleAction" />
            </div>
            <div v-else-if="manageRecord === 'removeRecord' && materialRecord">
                <AdminLessonMaterialsRemove :record="materialRecord" :lesson='record' :course="course" v-on:actionhandler="handleAction" />
            </div>    

            <div v-else-if="materialsList">
                <AdminTitleBar
                    :title="loc('Lesson Materials') + (course && course.title ? (' - ' + course.title) : '') + ' - ' + record.title "
                    :actions="titleBarActions"
                    v-on:actionhandler="handleAction"
                />   
                <v-row v-if="course">
                    <h3>{{course.title}} ({{course.reftitle}})</h3>
                </v-row>   
                <v-row v-if="tablePrefs">
                    <div v-for="(item, idx) in headers" v-bind:key="idx">
                        <v-checkbox style="min-width: 120px;"
                            :name="idx"
                            v-model="item.show"
                            :label="loc(item.text)"
                            @click.stop="saveheaders"
                        ></v-checkbox>
                    </div>
                </v-row>                                 
                <v-row class="searchrow">
                    <v-select :items="lessonSelect" v-bind:value="record && record._id" label="Lesson" @change="handleLessonChange" ></v-select>
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        append-icon="search"
                        :label="loc('Search')"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-row>
                <v-data-table
                    :items-per-page="itemsPerPage"
                    :headers="tableHeaders"
                    :footer-props="{ itemsPerPageOptions:[10,20,30,40,50,100,200] }"
                    :items="materialsList"
                    :search="search"
                    class="courseList elevation-1 my-8 pb2"
                >
                    <template v-slot:[`header.actions`]="{header}">
                        {{header.text}}
                        <span @click="tablePrefs=!tablePrefs" class="dtableactionicon" ><v-icon small >edit</v-icon> {{ loc("Columns") }}</span>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }" class="actioncolumn">
                        <span class="manageusericons">
                            <v-btn small color="primary" dark @click="handleAction('edit', item)" class="mr-1">{{ loc("Edit") }}</v-btn>
                            <v-btn small color="error" dark @click="handleAction('remove', item)">{{ loc("Remove") }}</v-btn>
                        </span>
                    </template>                
                </v-data-table>
                <div style="display:none;">
                    <v-row>{{courseId}} | {{lessonId}} | {{materialId}} | {{manageRecord}}</v-row>
                    <!-- <v-row>lessonList<pre>{{lessonList}}</pre></v-row> -->
                    <!-- <v-row>lessonRecord<pre>{{lessonRecord}}</pre></v-row> -->
                    <v-row>lesson<pre>{{record}}</pre></v-row>
                </div>
            </div>
        </div>        
    </v-container>
</template>

<script>
import { mapState } from "vuex";
import AdminTitleBar from "./Admin_TitleBar";
import AdminLessonMaterialsEdit from "./Admin_Lesson_Materials_Edit"
import AdminLessonMaterialsRemove from "./Admin_Lesson_Materials_Remove.vue";

import { filterId } from '../lib/filter';
import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';
import _size from 'lodash/size';

export default {
    name: "AdminMaterialsList",
    components: { AdminTitleBar, AdminLessonMaterialsEdit, AdminLessonMaterialsRemove },
    props: {
        // course: { type: Object },
        // lessonRecord: { type: Object },
        // lessonList: { type: Array }
    },
    data() {
        return {
            isLoading: true,
            courseId: false,
            course: false,
            lessonId: false,
            materialId: false,
            record: false,
            search: "",
            manageRecord: false,
            tablePrefs: false,
            itemsPerPage: 30,
            foo: false,
            titleBarActions: [
                    { text: "Refresh List", key: "refresh" },
                    { text: "Add Materials", key: "add" },
                    { text: "Go To Lesson", key: "gotolesson" },
                    { text: "Courses List", key: "listcourses"}
            ],
            headers: {
                _id: {
                    text: "ID",
                    value: "_id"
                },
                title: {
                    text: "Title",
                    value: "title",
                    sortable: true,
                    align: "start",
                    show: true
                },  
                section: {
                    text: "Type",
                    value: "section",
                    sortable: true,
                    align: "start",
                    show: true
                },
                lang: {
                    text: "Language",
                    value: "lang",
                    sortable: true,
                    align: "start",
                    show: true
                },
                order: {
                    text: "Order",
                    value: "order",
                    sortable: true,
                    align: "start",
                    show: true
                },
                required: {
                    text: "Required",
                    value: "required",
                    sortable: true,
                    align: "start",
                    show: false
                },
                modified: {
                    text: "Modified",
                    value: "modified",
                    sortable: true,
                    align: "start",
                    show: false
                }                                
            }
        }
    },
    computed: {
        ...mapState(["authError", "users", "courses", "lessons", "localize"]),
        tableHeaders: function() {
            var temp = Object.assign({}, this.headers);
            let columns = []
            for(let k in temp){
                if(temp[k].show){
                    temp[k].sortable = true;
                    temp[k].align = temp[k].align || "start";
                    columns.push(temp[k]);
                }
            }

            columns.push({ text: "Actions", sortable: false, value: "actions" });
            return columns;   
        },
        materialsList: function(){
            return (this.record && this.record.materials) ? Object.values(this.record.materials) : false;
            // return (this.lessons.materials && this.lessons.materials[this.lessonId]) || false;
        },
        lessonList: function() {
            if(!this.lessons || !this.courseId) return [];
            // console.log('lessonList', this.context, this.lessons.list, _filter(this.lessons.list, {course: this.context}));
            let list = (this.courseId === "All") ? this.lessons.list : _filter(this.lessons.list, {course: this.courseId});
            return _sortBy(list, ['sortorder', 'order']);
        },
        lessonSelect: function(){
            let list = [];
            for(let k in this.lessonList){
                console.log('lessonSelect adding', this.lessonList[k].sortorder, this.lessonList[k].order, this.lessonList[k].title )
                list.push({ value: this.lessonList[k]._id, text: this.lessonList[k].order + " - " + this.lessonList[k].title });
            }
            return list;
        }     
    },
    methods: {
        loadMaterials: function (opts){
            this.$store.dispatch("lessons/get", Object.assign({}, {_id: this.lessonId, admin: true, nocache: true}, opts || {}))
                .then((record) => {
                    this.isLoading = false;
                    this.record = record;
                    console.log('AdminMaterialsList.loadMaterials', record);
                    if(this.materialId === 'new'){
                        this.handleAction('add');
                    } else if(this.materialId && this.record && ((this.record.materials && this.record.materials[this.materialId])  || this.materialId === 'new')){
                        this.materialRecord = this.record.materials[this.materialId];
                        this.manageRecord = 'editRecord';
                    }
                    this.loadCourse(opts);
                })
        },
        loadCourse: function (opts){
            this.$store.dispatch("courses/get", {_id: this.courseId})
                .then((result) => {
                    if(result && result._id) this.course = result;
                });
        },
        handleAction: function(action, params){
            console.log("Admin MaterialsList handleAction", action, params)

            switch(action){
                case 'close':
                    this.manageRecord = false;
                    break;
                case 'edit':
                    if(this.record && this.record.materials && this.record.materials[params._id]){
                        this.materialRecord = this.record.materials[params._id];
                        this.manageRecord = 'editRecord';
                    }
                    break;
                case 'remove':
                    if(this.record && this.record.materials && this.record.materials[params._id]){
                        this.materialRecord = this.record.materials[params._id];
                        this.manageRecord = 'removeRecord';
                    }                    
                    break;
                case 'removeConfirmed':
                    {
                        this.$store.dispatch('lessons/removeMaterials', params)
                            .then((result) => {
                                
                                this.materialRecord = false;
                                this.manageRecord = false;
                                this.isLoading = true;
                                this.loadMaterials();
                                this.handleAction('close');
                            })
                    }
                    break;
                case 'add':
                    if(this.record){
                        if(!this.record.materials) this.record.materials = {};
                        let norder = 0;
                        for(let k in this.record.materials){ 
                            if(this.record.materials[k].norder > norder){
                                 norder = this.record.materials[k].norder + 1;
                            }
                        }
                        // this.record.materials.new = {
                        let adding = {
                            _id: 'new',
                            lesson: this.lessonId,
                            description: "",
                            norder: norder,
                            required: true,
                            order: norder.toString(10)
                        };
                        this.$set(this.record.materials, 'new', adding);
                        this.materialRecord = adding;
                        this.manageRecord = 'editRecord';
                    }
                    break;
                case 'save':
                    break;
                case 'refresh':
                    this.isLoading = true;
                    this.loadMaterials({nocache: true});
                    break;
                case 'listcourses':
                    this.$router.push('/admin/courses');
                    break;
                case 'gotolesson':
                    if(this.courseId && this.lessonId) this.$router.push('/lessons/'+this.courseId+'/'+this.lessonId+'/selfpaced');
                    break;
            }
        },
        handleEdit: function(item){},
        handleRemove: function(item){},
        handleLessonChange: function (action){
            console.log('handleLessonCHange', action);
            this.isLoading = true;
            this.$router.push('/admin/lessonmaterials/'+this.courseId+'/'+ filterId(action));
        },
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },  
        init: function(path){
            if(path && typeof path === 'string'){
                let p = path.split('/');
                this.courseId = filterId(p[0]);
                this.lessonId = (p[1]) ? filterId(p[1]) : null;
                this.materialId = (p[2]) ? filterId(p[2]) : null;
            }

            if(!this.lessons || !this.lessons.list || _size(this.lessons.list) < 2) this.$store.dispatch("lessons/getList");

            this.loadMaterials();
        },
        saveheaders: function(){
            localStorage.AdminMaterialsList_headers = JSON.stringify(this.headers);
        }
    },
    created () {
        this.init(this.$route.params.pathMatch);
    },
    mounted() {
        if(localStorage.AdminMaterialsList_headers){
            try {
                let h = JSON.parse(localStorage.AdminMaterialsList_headers);
                h && (this.headers = h);
            } catch(e){
                console.log("Error parsing from local storage",e);
            }
        }
    },
    watch: {
        '$route.params.id': {
            handler: function(s){
                console.log("lessons watcher triggered", s, this.$route);
                let context = s || (this.$route.params && this.$route.params.pathMatch);
                if(this.context !== context) this.init(context);
            },
            deep: true,
            immediate: false
        }
    }

    
}
</script>