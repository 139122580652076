<template>
 <v-container>
        <AdminTitleBar
            :title="loc('Confirm Lesson Removal')"
            :actions="titleBarActions"
            v-on:actionhandler="handleAction"
        />
        <v-card>
            <v-card-text class="removeconfirmation">
                <v-row>
                    <div class="label">{{ loc('ID') }}</div>
                    <div class="value">{{record._id || ""}}</div>
                </v-row>                
                <v-row >
                    <div class="label">{{ loc('Title') }}</div>
                    <div class="value">{{record.title || ""}}</div>
                </v-row>
                <v-row >
                    <div class="label">{{ loc('Course') }}</div>
                    <div class="value">{{course}}</div>
                </v-row>                
                <v-row>
                    <div class="label">{{ loc('Created') }}</div>
                    <div class="value">{{record.created || ""}}</div>
                </v-row>
                <v-row class='actionrow'>
                    <v-btn small color="error" dark @click="handleRemove()">{{ loc('Remove Record') }}</v-btn>
                    <v-btn small color="secondary" dark @click="handleAction('close')">{{ loc('Cancel') }}</v-btn>
                </v-row>                
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { mapState } from 'vuex';
import AdminTitleBar from "./Admin_TitleBar";

export default {
    name: "AdminLessonsRemove",
    props: {
        record: { type: Object }
    },
    components: { AdminTitleBar },
    data() {
        return {
            titleBarActions: [
                {
                    text: "Close",
                    key: "close"
                }
            ]            
        }
    },
    computed: {
        ...mapState(['users', 'localize', 'courses']),
        course: function(){
            return (this.record && this.record.course && ((this.courses && this.courses.list && this.courses.list[this.record.course] && this.courses.list[this.record.course].title) || this.record.course)) || "";
        }
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },    
        handleAction: function(action, params) {
            this.$emit("actionhandler", action, params);
        },
        handleRemove: function(){
            this.$emit("actionhandler", 'removeConfirmed', this.record);
        }              
    }
  
}
</script>