<template>
    <v-container>
        <div v-if="authError">
            {{ loc('Please Login') }}
        </div>
        <div v-else-if="!users.currentUser || !users.currentUser.isAdmin" >
            {{ loc("You do not have permission to do that.") }}
        </div>
        <div v-else>
            <div v-if="isLoading">
                {{ loc('Loading...') }}
            </div>
            <div v-else-if="!context">
                <h1>{{loc('Manage Classes')}}</h1>
                <h2>{{loc('Please Select a Course')}}</h2>
                <ul class="CourseLinkList" id="ClassAdminCourseSelection">
                    <li v-for="(item, key) in courselist" v-bind:key="key" :id="'Courselink-'+key">
                        <router-link :to="'/admin/classes/'+item._id">{{item.main}} - {{item.lang}} - {{item.title}}</router-link>
                    </li>
                </ul>
            </div>
            <div v-else-if="manageRecord === 'classRecord' && courses.list && courses.list[context]">
                <AdminClassesEdit :classRecord="classRecord" :course="courses.list[context]" v-on:actionhandler="handleAction" />
            </div>
            <div v-else-if="manageRecord === 'removeRecord' && courses.list && courses.list[context]">
                <AdminClassesRemove :record="classRecord" :course="courses.list[context]" v-on:actionhandler="handleAction" />
            </div>            
            <div v-else-if="courses.list && (courses.list[context] || context === 'all')">
                <AdminClassesList :recordList="classList" :course="courses.list[context]" v-on:actionhandler="handleAction" />
            </div>
        </div>
        <!-- <v-row class='mt-4'><pre>{{manageRecord}}</pre></v-row>
        <v-row class='mt-4'><pre>{{classRecord}}</pre></v-row> -->
    </v-container>
</template>

<script>
import { mapState } from "vuex";
import AdminClassesList from "./Admin_Classes_List";
import AdminClassesEdit from "./Admin_Classes_Edit";
import AdminClassesRemove from "./Admin_Classes_Remove";
import { filterId } from '../lib/filter';
import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';

export default {
    name: "AdminClasses",
    components: { AdminClassesList, AdminClassesEdit, AdminClassesRemove },
    data() {
        return {
            context: false,
            classId: false,
            isLoading: true,
            after: false, 
            classRecord: false,
            manageRecord: false
        }
    },
    computed: {
        ...mapState(["authError", "users", "courses", "classes", "localize", "adminstore"]),
        classList: function() {
            console.log("classlist", this.classes, this.context);
            if(!this.classes || !this.context) return [];
            // console.log('classList', this.context, this.classes.list, _filter(this.classes.list, {course: this.context}));
            if(this.context === 'all'){
                return _sortBy(this.classes.list, ['sortorder', 'order']);
            } else {
                return _sortBy(_filter(this.classes.list, {course: this.context}), ['sortorder', 'order']);
            }
        },
        courselist: function() {
            return _sortBy(this.courses.list, ['main','lang','title'])
        }
    },
    methods: {
        loadClasses: function ( params ) {
            console.log("Ready to load classes for", this.context);
            this.$store.dispatch("classes/getList", params)
                .then((result) => {
                    if(this.classId && result){
                        if(result[this.classId]){
                            console.log('init found class record', this.classId);
                            this.classRecord =  result[this.classId];
                            this.manageRecord = 'classRecord';
                        } else if(this.classId === 'new') {
                            this.classRecord = { _id: 'new', course: this.context };
                            this.manageRecord = 'classRecord';
                        } else {
                            console.log("init did not find a matching record", this.classId, result);
                        }
                    } 
                    this.isLoading = false;

                    console.log('result from classes.loadClasses', result);
                })
                .catch((e) => {
                    console.log('error in admin courses getList', e);
                })


        },
        loadInstructors: function () {
            if(!this.context) return;
            this.$store.dispatch("users/getInstructors", {course: this.context})
        },
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },  
        handleAction: function(action, params, opts) {
            console.log("AdminCourses handleAction", action, params);

            switch(action){
                case 'close': 
                    {
                        console.log("closing", this.$route.query)
                        this.classRecord = false;
                        this.manageRecord = false;
                        if(!opts && this.$route.query && this.$route.query.after){
                            switch(this.$route.query.after){
                                case 'course':
                                    opts = '/Course/' + filterId(this.context)
                            }
                        }
                        
                        let newpath = (opts && typeof opts === "string" && opts != "close") ? opts : "/admin/classes/" + this.context;
                        console.log("Let's go to ", newpath, this.$route)
                        if(this.$route.path !== newpath){
                            this.$router.push(newpath);
                        } 
                    }
                    break;
                case 'edit':
                    if(this.classes.list[params._id]){
                        this.classRecord = this.classes.list[params._id];
                        this.manageRecord = 'classRecord';
                        let newpath = "/admin/classes/" + this.context + "/" + params._id;
                        if(this.$route.path !== newpath){
                            this.$router.push(newpath);
                        }                    
                    }
                    break;
                case 'remove':
                    if(this.classes.list[params._id]){
                        this.classRecord = this.classes.list[params._id];
                        this.manageRecord = 'removeRecord';
                    } 
                    break;
                case 'removeConfirmed':
                    {
                        this.$store.dispatch('classes/remove', params)
                            .then((result) => {
                                if(result){
                                    this.handleAction('close');
                                    // this.$store.commit("content/getList", {reset: true});
                                    this.loadClasses({nocache:true})
                                }
                            })
                    }                    
                    break;
                case 'add':
                    {
                        console.log("Add a class");
                        this.classRecord = { _id: 'new', course: this.context };
                        this.manageRecord = 'classRecord';
                        let newpath = "/admin/classes/" + this.context + "/new";
                        if(this.$route.path !== newpath){
                            this.$router.push(newpath);
                        }
                    }  
                    break;
                case 'save':
                    console.log("Save class", params);
                    if(!params.course) params.course = this.context;
                    this.$store.dispatch("classes/save", params )
                        .then((result) => {
                        // console.log('classes.save result', result);
                            // this.$store.dispatch('setMessage', false, {root: true});
                            if(opts){ 
                                this.handleAction('close', params, opts);
                            } else {
                                let newpath = "/admin/classes/" + this.context + "/" + result._id;
                                if(this.$route.path !== newpath){
                                    this.$router.push(newpath);
                                }   
                            }
                            this.classRecord = result 
                        });
                case 'refresh':
                    console.log('refresh');
                    this.loadClasses({nocache: true});
                    break;                           
            }
        },
        init(path){
            if(path && typeof path === 'string'){   
                let p = path.split('/');
                let context = filterId(p[0]) || "hffmm8ww";
                let classid = (p[1]) ? filterId(p[1]) : null;
                console.log("Admin_Classes.init", path, context, classid)
                this.context = context
                this.classId = classid;
            }
            if(this.context) this.loadInstructors();
            this.loadClasses();
            if(!this.content || this.content.list) this.$store.dispatch("courses/getList");
            console.log("AdminClasses.init", this.context, this.classId)
        },

    },
    created () {
        console.log("Loading Classes Admin", this.$route);
        this.init(this.$route.params.pathMatch)

    },
    watch: {
        '$route.params.id': {
            handler: function(s){
                console.log("classes watcher triggered", s, this.$route);
                let context = s || (this.$route.params && this.$route.params.pathMatch);
                if(this.context !== context) this.init(context);
                // this.context = s || (this.$route.params && this.$route.params.pathMatch);
            },
            deep: true,
            immediate: false
        }
    }
}

</script>

