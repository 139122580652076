<template>
    <div>
        <AdminTitleBar
            :title="loc('Enrollment')"
            :actions="titleBarActions"
            v-on:actionhandler="handleAction"
        />  
        <v-row >
            <v-col>This table will show enrollment and progress for users who were active since 2020-01-01</v-col>
        </v-row>          
        <v-row v-if="tablePrefs">
            <div v-for="(item, idx) in headers" v-bind:key="idx">
                <v-checkbox style="min-width: 120px;"
                    :name="idx"
                    v-model="item.show"
                    :label="loc(item.text)"
                    @click.stop="saveheaders"
                ></v-checkbox>
            </div>
        </v-row>  
        <div v-if="isLoading">
                {{ loc('Loading...') }}
        </div>        
        <div v-else-if="recordList">
            <v-row class="searchrow">
                <v-select :items="courseSelect" v-model="courseId" label="Course" style="margin-right:12px;"></v-select>
                <v-select :items="classSelect" v-model="classId" label="Class" @change="handleClassChange" ></v-select>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="search"
                    :label="loc('Search')"
                    single-line
                    hide-details
                ></v-text-field>
            </v-row>
            <v-data-table
                :items-per-page="itemsPerPage"
                :headers="tableHeaders"
                :footer-props="{ itemsPerPageOptions:[10,20,30,40,50,100,200] }"
                :items="recordList"
                :search="search"
                class="courseList elevation-1 my-8 pb2"
            >
                <template v-slot:[`header.actions`]="{header}">
                    {{(header.showaction && header.text) || ''}}
                    <span @click="tablePrefs=!tablePrefs" class="dtableactionicon"><v-icon small >edit</v-icon> {{ loc("Columns") }}</span>
                </template>
            </v-data-table>
        </div>
        <div v-else>
            {{ loc('No data yet.') }}
        </div>                
    </div>
       
</template>

<script>
import { mapState } from 'vuex';
import AdminTitleBar from "./Admin_TitleBar";
import { filterId, filterDate } from '../lib/filter';
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';


export default {
    name: "AdminEnrollmentList",
    props: {

    },
    components: { AdminTitleBar },
    data () {
        return {
            itemsPerPage: 30,
            tablePrefs: false,
            // manageRecord: false,
            courseId: false,
            classId: false,
            isLoading: true,
            reportId: 'enrollment',
            search: "",
            recordList: false,
            headers: {
                _id: {
                    text: "ID",
                    value: "_id"
                },
                user: {
                    text: "User ID",
                    value: "user",
                    sortable: true,
                    align: "start",
                    show: false
                },
                users_firstname: {
                    text: "First Name",
                    value: "users_firstname",
                    sortable: true,
                    align: "start",
                    show: true
                },  
                users_lastname: {
                    text: "Last Name",
                    value: "users_lastname",
                    sortable: true,
                    align: "start",
                    show: true
                },                   
                users_email: {
                    text: "Email",
                    value: "users_email",
                    sortable: true,
                    align: "start",
                    show: false
                },   
                users_regpath: {
                    text: "Reg path",
                    value: "users_regpath",
                    sortable: true,
                    align: "start",
                    show: false
                },                     
                course: {
                    text: "Course ID",
                    value: "course",
                    sortable: true,
                    align: "start",
                    show: false
                },  
                courses_main: {
                    text: "Course Main",
                    value: "courses_main",
                    sortable: true,
                    align: "start",
                    show: false
                },  
                courses_title: {
                    text: "Course Title",
                    value: "courses_title",
                    sortable: true,
                    align: "start",
                    show: true
                },  
                classid: {
                    text: "Class ID",
                    value: "classid",
                    sortable: true,
                    align: "start",
                    show: false
                },
                classes_name: {
                    text: "Class Name",
                    value: "classes_name",
                    sortable: true,
                    align: "start",
                    show: true
                },
                classes_selfpaced: {
                    text: "Class is Selfpaced",
                    value: "classes_selfpaced",
                    sortable: true,
                    align: "start",
                    show: false
                },
                classes_startdate: {
                    text: "Class Start",
                    value: "classes_startdate",
                    sortable: true,
                    align: "start",
                    show: false
                },
                percentcomplete: {
                    text: "Percent Complete",
                    value: "percentcomplete",
                    sortable: true,
                    align: "start",
                    show: false
                },                 
                regcode: {
                    text: "Registration Code",
                    value: "regcode",
                    sortable: true,
                    align: "start",
                    show: false
                },                        
                regdate: {
                    text: "Registered",
                    value: "regdate",
                    sortable: true,
                    align: "start",
                    show: true
                },
                completed: {
                    text: "Completed",
                    value: "completed",
                    sortable: true,
                    align: "start",
                    show: false
                },       
                lastview: {
                    text: "Last View",
                    value: "lastview",
                    sortable: true,
                    align: "start",
                    show: false
                },                
                modified: {
                    text: "Modified",
                    value: "modified",
                    sortable: true,
                    align: "start",
                    show: false
                }
            },
            titleBarActions: [
                    { text: "Refresh List", key: "refresh" },
            ]
        }      
    },
    computed: {
        ...mapState(['users', 'localize', 'courses', 'classes', 'adminstore']),
        tableHeaders: function() {
            var temp = Object.assign({}, this.headers);
            let columns = []
            for(let k in temp){
                if(temp[k].show){
                    temp[k].sortable = true;
                    temp[k].align = temp[k].align || "start";
                    columns.push(temp[k]);
                }
            }

            columns.push({ text: "Actions", sortable: false, value: "actions" });
            return columns;   
        },
        courseSelect: function() {
            if(!this.courses) return [];
            let list = [];
            for(let k in this.courses.list){
                list.push({value: this.courses.list[k]._id, text: this.courses.list[k].title});
            }
            return list;
        },
        classSelect: function() {
            if(!this.classes){ console.log("no classes?", this.classes); return [];}
            let list = _filter(this.classes.list, {course: this.courseId});
            let out = [], selfpaced = [];
            for(let k in list){
                if(list[k].selfpaced){ 
                    console.log("Adding selfpaced to class list", list[k]);
                    selfpaced.push({value: list[k]._id, text: list[k].name})
                }
                out.push({value: list[k]._id, text: list[k].name})
            }
            out = _sortBy(out, ['text']);
            selfpaced = _sortBy(selfpaced, ['text']);
            selfpaced.push({divider: true});
            return [...selfpaced, ...out];
        }
    },
    methods: {
        getData: function () {
            console.log()
            let opts = {
                reportid: this.reportId,
                // since: '2020-01-01',
                useractive: '2020-01-01',
            }
            if(this.courseId) opts.courseid = this.courseId;
            if(this.classId) opts.classid = this.classId;
            this.$store.dispatch('reports/getReport', opts)
            .then((result) => {
                this.recordList = result;
                this.isLoading = false;
                console.log('enrollment report result',result);
            })
        },
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },
        handleEdit: function(evt) {
            this.$emit("actionhandler", 'edit', evt)
        },
        handleRemove: function(evt) {
            this.$emit("actionhandler", 'remove', evt)
        },
        handleAction: function(action, params){
            this.$emit("actionhandler", action, params);
        },     
        handleClassChange: function(newclassid, params){
            console.log(newclassid, params);
            newclassid =  filterId(newclassid);
            // if(newclassid && newclassid !== this.course._id){
            //     this.$router.push("/admin/enrollment/" + newclassid);
            // }
        },
        regdate: function(item){
            return (!item.regdate && item.ts) ? filterDate(item.ts, 'YYYY-MM-DD') : item.regdate;
        },
        saveheaders: function(){ console.log('save headers?') }
    },
    created () {
        console.log(this.$route)
        this.context = this.$route.params.id || 'home';
        this.getData();
    },
    watch: {
        'courseId' (){ this.getData(); },
        'classId' (){ this.getData(); },
        // '$route.params.id': {
        //     handler: function(s){
        //         console.log("watcher triggered", s);
        //         this.getData();
        //     },
        //     deep: true,
        //     immediate: false
        // },
        'lang' (newLang, oldLang){
            console.log('lang change', newLang, oldLang);
        }
    }
    
}
</script>