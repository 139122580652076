<template>
    <v-container>
        <AdminTitleBar
            :title="loc('Edit User')"
            :actions="titleBarActions"
            v-on:actionhandler="handleAction"
        />
        <div class="mb-12 mt-n10" style="max-width:360px">
            <v-row>
                <v-col class="label" style="max-width:100px;">Name:</v-col>
                <v-col>{{record.firstname}} {{record.lastname}}</v-col>
            </v-row>
            <v-row>
                <v-col class="label" style="max-width:100px;">Email:</v-col>
                <v-col>{{record.email}}</v-col>
            </v-row>
            <v-row>
                <v-col class="label" style="max-width:100px;">ID:</v-col>
                <v-col>{{record._id}}</v-col>
            </v-row>
        </div>
        <v-tabs>
            <v-tab>{{ loc("Edit User") }}</v-tab>
            <v-tab>{{ loc("Classes") }}</v-tab>
            <v-tab>{{ loc("Other Info") }}</v-tab>
            <v-tab>{{ loc("JSON") }}</v-tab>

            <v-tab-item>
                <v-card>
                    <v-card-text class='profileform'>
                        <v-row>{{record._id}}</v-row>
                        <v-row justify="space-between" v-if="showvcrm">
                            <v-checkbox
                                name="vcrmOmit"
                                v-model="record.vcrmOmit"
                                :label="loc('Omit From CRM Integration')"
                            ></v-checkbox>
                        </v-row>
                        <v-row justify="space-between">
                            <v-text-field
                                name="email"
                                v-model="record.email"
                                :label="loc('Email')"
                            ></v-text-field>
                        </v-row>
                        <v-row justify="space-between">
                            <v-text-field
                                name="firstname"
                                v-model="record.firstname"
                                :label="loc('First Name')"
                            ></v-text-field>
                        </v-row>
                        <v-row justify="space-between">
                            <v-text-field
                                name="lastname"
                                v-model="record.lastname"
                                :label="loc('last Name')"
                            ></v-text-field>
                        </v-row>
                        <v-row justify="space-between">
                            <v-text-field
                                name="address1"
                                v-model="record.address1"
                                :label="loc('Address 1')"
                            ></v-text-field>
                        </v-row>
                        <v-row justify="space-between">
                            <v-text-field
                                name="address2"
                                v-model="record.address2"
                                :label="loc('Address 2')"
                            ></v-text-field>
                        </v-row>
                        <v-row justify="space-between">
                            <v-text-field
                                name="city"
                                v-model="record.city"
                                :label="loc('City')"
                            ></v-text-field>
                        </v-row>
                        <v-row justify="space-between">
                            <v-text-field
                                name="state"
                                v-model="record.state"
                                :label="loc('State')"
                            ></v-text-field>
                        </v-row>
                        <v-row justify="space-between">
                            <v-text-field
                                name="postal"
                                v-model="record.postal"
                                :label="loc('Postal Code/ZIP')"
                            ></v-text-field>
                        </v-row>
                        <v-row justify="space-between">
                            <v-text-field
                                name="country"
                                v-model="record.country"
                                :label="loc('Country')"
                            ></v-text-field>
                        </v-row>
                        <v-row justify="space-between">
                            <v-select :items="[{value:'',text:'none selected'},'male','female']" v-model="record.gender" :label="loc('Gender')" name="gender" />
                            <!-- <v-text-field
                                name="gender"
                                v-model="record.gender"
                                :label="loc('Gender"
                            ></v-text-field> -->
                        </v-row>

                        <v-row justify="space-between">
                            <v-select :items="languages" v-model="record.lang" :label="loc('Language')" name="language" />
                            <!-- <v-text-field
                                name="gender"
                                v-model="record.gender"
                                :label="loc('Gender')"
                            ></v-text-field> -->
                        </v-row>

                        <v-row justify="space-between">
                            <v-text-field
                                name="organization"
                                v-model="record.organization"
                                :label="loc('Organization')"
                            ></v-text-field>
                        </v-row>
                        <v-row justify="space-between">
                            <v-text-field
                                name="heared"
                                v-model="record.heared"
                                :label="loc('How did you hear about us?')"
                            ></v-text-field>
                        </v-row>
                        <v-row justify="space-between">
                            <v-text-field
                                name="vocation"
                                v-model="record.vocation"
                                :label="loc('Vocation')"
                            ></v-text-field>
                        </v-row>
                        <v-row justify="space-between">
                            <v-text-field
                                name="birthDate"
                                v-model="record.birthDate"
                                :label="loc('Birthday')"
                            ></v-text-field>
                        </v-row>
                        <v-row justify="space-between">
                            <v-checkbox
                                name="optout"
                                v-model="record.optout"
                                :label="loc('Opted Out from Other Communication')"
                            ></v-checkbox>
                        </v-row>
                        <v-row justify="space-between">
                            <v-textarea name="bio" v-model="record.bio" :label="loc('Bio')" rows="1" auto-grow ></v-textarea>
                        </v-row>
                        <v-row justify="space-between" class='editroles mr-3'>
                            <label style="margin-right: 12px; padding-top: 1.2em; font-size: 1.3em;">Roles:</label> 
                            <v-checkbox
                                class="mr-3"
                                name="roles.admin"
                                :input-value="record.roles && !!record.roles.admin"
                                :label="loc('Admin')"
                                :false-value="false"
                                :true-value="true"
                                @click.stop="handleRoleClick('admin')"
                                id="roles_admin"
                            ></v-checkbox>
                            
                            <v-checkbox
                                name="roles.cms"
                                :input-value="record.roles && !!record.roles.cms"
                                :label="loc('Content Manager')"
                                :false-value="false"
                                :true-value="true"
                                @click.stop="handleRoleClick('cms')"
                                id="roles_cms"
                            ></v-checkbox>
                            <v-spacer></v-spacer>
                        </v-row>                        
                        <v-row justify="space-between">
                            <v-textarea name="note" v-model="record.note" :label="loc('Admin Note')" rows="1" auto-grow ></v-textarea>
                        </v-row>
                        <v-row class='actionrow mt-2'>
                            <v-btn small color="primary" dark @click="handleSave()" class="mr-1">{{ loc("Save Changes") }}</v-btn>
                            <v-btn small color="primary" dark @click="handleSave('close')" class="mr-1">{{ loc("Save Changes and Close") }}</v-btn>
                            <v-btn small color="secondary" dark @click="handleCancel('close')">{{ loc("Cancel") }}</v-btn>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-tab-item>

            <v-tab-item>
                <v-card>
                    <v-card-text>
                        <div class='certificationscontainer mb-4' >
                            <h2 class='mb-3'>{{ loc("Certifications") }}</h2>
                            <UserCertificationList :record="record" :userId="record._id" />
                            <!-- <v-row v-for="(item, key) in record.certifications" :key="key" class="certsrow mb-2">
                                <div class='label'>{{ (courseList[key] && courseList[key].title) || key }}</div>
                                <div class='value' style="margin-right:1em;"><v-text-field :value="filterDate(item)" @blur="handleCertDate($event, key)"></v-text-field></div>
                                <div><v-icon small @click="handleRemoveCert(key)">delete</v-icon></div>
                            </v-row>
                            <v-row class="mb-2 certsrow" v-if="availablecerts">
                                <div class="label addLabel" >{{ loc("Add Certification") }}:</div>
                                <div class='value'><v-select :label="loc('Select a Course')" :items="availablecerts" name="addclass" @change="handleAddCert($event)" /></div>
                            </v-row> -->
                        </div>
 
                        <div class='enrollmentcontainer mb-4'>
                            <h2 class='mb-3'>{{ loc("Class Enrollment") }}</h2>
                            <UserEnrollmentList :isEditor="true" :record="record" :userId="record._id" />
                            <!-- <v-row class='actionrow'>
                                <v-btn small color="primary" dark @click="handleSave()">{{ loc("Save Changes") }}</v-btn>
                                <v-btn small color="primary" dark @click="handleSave('close')">{{ loc("Save Changes and Close") }}</v-btn>
                                <v-btn small color="secondary" dark @click="handleCancel('close')">{{ loc("Cancel") }}</v-btn>
                            </v-row> -->

                        </div>
                        <template v-if="instructor && instructor.length" >
                            <UserInstructorList :classList="instructor" :userRecord="record" />
                        </template>
                        <!-- <v-row><code>{{classList}}</code></v-row>-->
                        <!-- <v-row><code>{{courseList}}</code></v-row> -->
                    </v-card-text>
                </v-card>
            </v-tab-item>

            <v-tab-item>
                <v-card>
                    <v-card-text class='infotab'>
                        <v-row>
                            <div class="label">{{ loc("Record Created") }}</div>
                            <div class="value">{{record.created || ""}}</div>
                        </v-row>
                        <v-row>
                            <div class="label">{{ loc("Last Time") }}</div>
                            <div class="value">{{record.lastseen || ""}}</div>
                        </v-row>
                        <v-row>
                            <div class="label">{{ loc("Last URI") }}</div>
                            <div class="value">{{record.lasturi || ""}}</div>
                        </v-row>
                        <v-row v-if="showvcrm">
                            <div class="label">{{ loc("VCRM Contact ID") }}</div>
                            <div class="value"><a :href="`https://app.virtuoussoftware.com/Generosity/Contact/View/${record.vcrmContactId}`" target="_blank">{{record.vcrmContactId || ""}}</a></div>
                        </v-row>
                        <v-row v-if="showvcrm">
                            <div class="label">{{ loc("VCRM Contact Individual ID") }}</div>
                            <div class="value">{{record.vcrmIndividualId || ""}}</div>
                        </v-row>

                    </v-card-text>
                </v-card>
            </v-tab-item>

            <v-tab-item>
                <v-card>
                    <v-card-text>
                        <v-row>
                            <div class='note' style="font-size: 1.1em;">{{ loc("This is the raw user record.") }}</div>
                        </v-row>
                        <v-row class="mt-2 mb-3">
                            <pre>{{ record }}</pre>
                        </v-row>
                        <v-row>
                            <h3 class='mb-2'>{{ loc("Changes") }}</h3>
                            <pre>{{diffrecord}}</pre>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs>
    </v-container>
</template>

<script>
import { mapState } from "vuex";
import config_system from '../config/system';
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';
import AdminTitleBar from "./Admin_TitleBar";
import UserEnrollmentList from "./User_Enrollment_List";
import UserInstructorList from "./User_Instructor_List.vue";
import UserCertificationList from "./User_Certification_List";

import {
        filterDate as _filterDate,
        filterDateTime as _filterDateTime,
        isValidDate as _isValidDate
    } from "../lib/filter";

export default {
    name: "AdminUserEdit",
    props: {
        id: { type: [Object, Number, String] },
        userRecord: { type: [Object, Boolean] },
        stage: { type: [String, Boolean] }
    },
    components: { AdminTitleBar, UserEnrollmentList, UserCertificationList, UserInstructorList },
    data() {
        return {
            // REMOVE THESE
            classList: [],
            courseList: [],
            //
            showvcrm : config_system.integrations.vcrm,
            record: {...this.userRecord}, // @TODO this needs to be expanded so we can convert the timestamps to dates.
            dateMenu: false,
            editClass: true,
            showInstructor: false,
            rolesSet: false,
            // saverecord: false,
            titleBarActions: [
                {
                    text: "Close",
                    key: "close"
                }
            ]
        };
    },
    computed: {
        ...mapState(['authError', 'users', 'localize', 'classes', 'courses', 'enrollment']), // , 'classList', 'courseList'
        languages: function() {
            // console.log(this.locStrings);
            let out = [
                {text: 'not set', value: ''}
            ];
            // for(let k in this.locStrings.languages) out.push({text: this.locStrings.languages[k], value: k});
            return out;
        },
        diffrecord: function() {
            let diff = {};
            let count = 0;
            for(let k in this.record){
                // console.log('comparing', k, this.userRecord[k], this.record[k]);
                if(this.record[k] !== this.userRecord[k]){
                    diff[k] = this.record[k];
                    count++;
                } 
            }
            if(this.rolesSet){ diff.roles = this.record.roles; count++; }
            // console.log("What's the diff?", count, diff);
            return count ? diff : false;
        },
        availablecerts: function() {
            // console.log(this.courses.list);
            let out = [ ];
            for(let k in this.courses.list){
                let title = (this.courses.list[k].title) ? (this.courses.list[k].reftitle +' - '+ this.courses.list[k].title + " (" + k + ")" )  : k;
                if(!this.record.certifications || !this.record.certifications[k]) out.push({value: k, text: title  });
            }

            return _sortBy(out, 'text');
        },
        instructor: function() {
            let classlist = false;
            if(this.classes.list){
                classlist = _sortBy(_filter(this.classes.list, {instructor: this.userRecord._id}), 'startdate')
            }
            return classlist || false;
        }
    },
    methods: {
        handleAction: function(action, params) {
            // @TODO close should raise modal if there are unsaved changes.
            // console.log('handleAction', action)
            if(action === 'close' && this.diffrecord ){
                this.$store.dispatch('setMessage', {message: "You have unsaved changes.  Please use the buttons at the bottom to either save or cancel.", style: "warning"}, {root: true});
            } else {
                // this.$store.dispatch('setMessage', false, {root:true});
                // console.log(action, this.diffrecord);
                this.$emit("actionhandler", action);
            }
        },
        handleSignupDate: function(value, idx, field){
            // console.log("handleSignupDate", idx, value, this.record.classes);
            value = value.target.value;
            if(_isValidDate(value)){
                let classes = Object.assign({}, this.record.classes);
                classes[idx] = classes[idx] || {};
                if(field === "completed"){
                    classes[idx][field] = _filterDate(value, 'YYYY-MM-DD');
                } else if(field === "ts") {
                    classes[idx][field] = _filterDate(value, 'x');
                } else {
                    classes[idx][field] = value; // not used at the moment.
                }
                this.record.classes = classes;
                // console.log(this.record.classes[idx])
            }
        },
        handleCertDate: function(value, idx){
            value = value.target.value;
            if(_isValidDate(value)){
                let certs = Object.assign({}, this.record.certifications);
                certs[idx] = _filterDate(value, 'YYYY-MM-DD');
                this.record.certifications = certs;
            }
        },
        handleCancel: function(next) {
            if(this.diffrecord){
                this.record = this.userRecord;
            }
            // this.$store.dispatch('setMessage', false, {root: true});
            if(next === "close"){
                this.$emit("actionhandler", 'close');
            }
        },
        handleSave: function(next) {
            if(!this.diffrecord){
                console.log("No Diff, returning", this.diffrecord);
                if(next === 'close') this.$emit("actionhandler", 'close');
            } else {
                let diff = {...this.diffrecord};
                diff._id = this.userRecord._id;
                console.log("users.edit calling save", diff);
                this.$store.dispatch("users/save", diff )
                    .then((result) => {
                      // console.log('user.save result', result);
                        // this.$store.dispatch('setMessage', false, {root: true});
                        this.record = result // this.userRecord;
                        if(next === 'close') this.$emit("actionhandler", 'close');
                    })
            }
        },
        handleAddCert: function(key){
            let certs = Object.assign({}, this.record.certifications);
            if(certs[key]) return;
            certs[key] = _filterDate(Date.now());
            // this.record.certifications = certs;
            this.$set(this.record, 'certifications', certs);
          // console.log("handleAddCert", key);
        },
        handleAddClass: function(key){
            console.log('handleAddClass', key);
            let classes = Object.assign({}, this.record.classes);
            if(classes[key] || !this.classes.list[key]) return;
            classes[key] = { course: this.classes.list[key].course, ts: Date.now() }
            // this.record.classes = classes;
            this.$set(this.record, 'classes', classes);
            console.log("handleAddClass", key, this.record);
        },
        handleRemoveClass: function(key){
            if(!this.record.classes || !this.record.classes[key]) return;
            let classes = Object.assign({}, this.record.classes);
            delete classes[key];
            // this.record.classes = classes;
            this.$set(this.record, 'classes', classes);
        },
        handleRemoveCert: function(key){
            if(!this.record.certifications || !this.record.certifications[key]) return;
            let certs = Object.assign({}, this.record.certifications);
            delete certs[key];
            // this.record.certifications = certs;
            this.$set(this.record, 'certifications', certs);
        },
        handleRoleClick: function(key, i){
            console.log("handleRoleClick", key, i);
            if(!this.record) return;
            this.record.roles = this.record.roles || {};
            this.record.roles[key] = !this.record.roles[key];
            this.rolesSet = true;
        },
        // handleClassSave: function(key){
        //     console.log('handleClassSave', key);
        //     console.log('userRecord', this.userRecord);
        // },
        courseRecord: function(item, field){
            console.log("courseRecord", item, field)
            if(this.courses && this.courses.list && this.courses.list[item.course]){
                return (field) ? this.courses.list[item.course][field] : this.courses.list[item.course]
            } else {
                return field ? false : {}; 
            }
        },        
        filterDate: function(val, format){
            return _filterDate(val, format);
        },
        filterDateTime: function(val, format){
            return _filterDateTime(val, format);
        },
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },          
    }
};
</script>


