<template>
    <v-container>
        <div id="uploadwrapper">
            <v-dialog v-model="uploaddialog" persistent max-width="600px">
                <v-card>
                    <v-card-title>
                        <span class="text-h5">{{ loc('Upload File') }}</span>
                    </v-card-title>
                    <div v-if="uploading">
                        <v-card-text>
                            <h3>{{ loc('Uploading...') }}</h3>
                        </v-card-text>
                    </div>
                    <div v-else>
                        <v-card-text>
                            <v-file-input :label=" loc('File') " v-model="uploadfiles" dense show-size prepend-icon="file_upload" ref="file"></v-file-input>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn @click="handleAction('uploadfiles')" color="primary">{{ loc('Upload') }}</v-btn>
                            <v-btn @click="handleAction('toggleupload')">{{ loc('Cancel') }}</v-btn>
                        </v-card-actions>                    
                    </div>
                </v-card>
            </v-dialog>
        </div>
        <div v-if="authError">
            {{ loc('Please Login') }}
        </div>
        <div v-else-if="isLoading">
            {{ loc("Loading...") }}
        </div>
        <div v-else-if="uploading">
            {{ loc("Working...") }}
        </div>        
        <div v-else-if="!users.currentUser || !users.currentUser.isAdmin" >
            {{ loc("You do not have permission to do that.") }}
        </div>
        <div v-else id="fileadminwrapper">
            <input ref="uploadfield" class="d-none" type="file" @change="handleFileChange" >
            <div v-if="manageRecord === 'editRecord'">
                <AdminFilesEdit :record="record" v-on:actionhandler="handleAction" />
            </div>
            <div v-else-if="manageRecord === 'removeRecord'">
                <AdminFilesRemove :record="record" v-on:actionhandler="handleAction" />
            </div>            
            <div v-else>
                <AdminFilesList :fileList="files.list" v-on:actionhandler="handleAction" :context="context" />
            </div>
            <div>Context: <pre>{{context}}</pre></div>
            <div>manageRecord: <pre>{{manageRecord}}</pre></div>
        </div>
        <!-- <v-row class='mt-4'><pre>{{manageRecord}}</pre></v-row>
        <v-row class='mt-4'><pre>{{record}}</pre></v-row> -->
    </v-container>
</template>

<script>
import { mapState } from "vuex";
// import config_system from '../config/system';

import AdminFilesEdit from "./Admin_Files_Edit";
import AdminFilesList from "./Admin_Files_List";
import AdminFilesRemove from "./Admin_Files_Remove";
import { filterId } from "../lib/filter";
import _find from "lodash/find";

export default {
    name: "AdminFiles",
    components: { AdminFilesList, AdminFilesEdit, AdminFilesRemove },
    data() {
        return {
            itemsPerPage: 15,
            context: false,
            filename: false,
            record: false,
            list: false,
            isLoading: true,
            isSelecting: true,
            manageRecord: false,
            uploaddialog: false,
            uploadfiles: [],
            uploading: false,
        }
    },
    computed: {
        ...mapState(["authError", "users", "localize", "files"]),
    },
    methods: {
        getList: function(context) {
            context = context || this.context;
            console.log("files.getList", context);
            this.$store.dispatch("files/getList", {id: context})
                .then((result) => {
                    this.list = result;
                    this.isLoading = false;
                    this.$store.dispatch('setMessage', {message: "Got listing "+ new Date().toISOString(), style: "success"}, {root: true})
                    if(result.length && this.filename){
                        // let record = (r) => {for(let i=0;i<r.length;i++){ if(r[i].filename === this.filename) return r[i] } }
                        this.handleAction('edit', _find(result, {filename: this.filename}) );
                    }
                })
                .catch((e) => {
                    console.log('error in getList?', e);
                    this.$store.dispatch('setMessage', {message: "Directory or File Not Found", style: "warning"}, {root: true});
                })
        },
        handleAction: function(action, params, opts) {
            console.log('AdminFiles handleAction', action, params, opts)
            switch(action){
                case 'close':
                    {  // block needed for lexical statement therein
                        this.record = false;
                        this.manageRecord = false;
                        let newpath = "/admin/files/" + this.context;
                        if(this.$route.path !== newpath) this.$router.push(newpath);
                    }
                    break;
                case 'edit':
                    this.handleEdit(params,opts);
                    break;
                case 'remove':
                    this.record = params;
                    this.manageRecord = 'removeRecord';
                    break;  
                case 'removeConfirmed':
                    console.log("Removing", params);
                    this.handleRemove(params, opts);
                    break;
                case 'refresh':
                    console.log('refresh',this.context);
                    this.getList(this.context);
                    break;
                case 'save':
                    this.handleSave(params, opts);
                    break;
                case 'toggleupload':
                    this.handleFileDialog();
                    // this.uploaddialog = !this.uploaddialog;
                    // this.$refs.file.$click()
                    break;
                case 'uploadfiles':
                    this.handleUpload(params);
                    break;
            }
        },
        handleFileDialog: function (){
            this.isSelecting = true;
            window.addEventListener('focus', () => { this.isSelecting = false; }, { once: true })
            this.$refs.uploadfield.click();
        },
        handleFileChange: function (e){
            this.uploadfiles = e.target.files[0];
            this.handleUpload();
        },
        handleEdit: function (params, opts) {
            console.log('files.handleEdit', params, opts);
            params.context = this.context;
            this.$store.dispatch("files/getFile", params)
                .then((result) => {
                    this.record = result;
                });
            this.manageRecord = "editRecord";
            let newpath = "/admin/files/" + this.context + "/" + params.filename;
            if(this.$route.path !== newpath){
                this.$router.push(newpath);
            }   
            return;            

        },
        handleRemove: function (params, opts) {
            console.log("removal is confirmed, removing", params, opts);
            params.context = this.context;
            this.$store.dispatch("files/removeFile", params)
                .then((result) => {
                    this.handleAction('close');
                })
        },
        handleSave: function (params, opts) {
            console.log("Saving file", this.context, this.record, params, opts);
            this.record.context = this.context;
            this.$store.dispatch("files/save", this.record);
        },
        handleUpload: function (params) {
            console.log("handleUpload", this.uploadfiles);
            if(!this.uploadfiles || this.uploadfiles.length === 0) return;
            this.uploading = true;

            let data = {
                context: this.context,
                thefile: this.uploadfiles
            }
            console.log("handleUpload dispatching",data);

            this.$store.dispatch("files/upload", data)
                .then((result) => {
                    this.getList(this.context);
                    this.uploadfiles = [];
                    this.uploaddialog = false;
                    this.uploading = false;
                })
        },
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        }, 
        init: function(path){
            path = path || (this.$route.params && this.$route.params.pathMatch) || ''
            console.log('files.init', path);
            let context = '';
            let filename = null;
            if(path && typeof path === 'string' && path.length){   
                let p = path.split('/');
                context = filterId(p[0]) || "";
                filename = (p[1]) ? filterId(p[1]) : null;
                console.log("Admin_Lessons.init", path, context, filename)
            }
            this.context = context
            this.filename = filename;

            this.getList();            
        }
    },
    created() {
        this.init(this.$route.params.pathMatch)
    },
    watch: {
        '$route.params': {
            handler: function(s){
                this.init();
            },
            deep: true,
            immediate: false
        }
    }
}

</script>