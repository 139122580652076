<template>
    <v-container>
        <AdminTitleBar
            :title=" loc('Comfirm User Removal')"
            :actions="titleBarActions"
            v-on:actionhandler="handleAction"
        />
                <v-card>
                    <v-card-text class='infotab'>
                        <v-row>
                            <strong>{{ loc('This does NOT remove the contact from other places, such as a CRM.  It just removes the user from the LMS.') }}}</strong>
                        </v-row>
                        <v-row>
                            <div class="label">{{ loc('Email') }}</div>
                            <div class="value">{{userRecord.email || ""}}</div>
                        </v-row>
                        <v-row>
                            <div class="label">{{ loc('Name') }}</div>
                            <div class="value">{{userRecord.firstname + " " + userRecord.lastname}}</div>
                        </v-row>
                        <v-row>
                            <div class="label">{{ loc('Last Seen') }}</div>
                            <div class="value">{{userRecord.lastseen || ""}}</div>
                        </v-row>
                        <v-row class='actionrow'>
                            <v-btn small color="error" dark @click="handleRemove()">{{ loc('Remove User') }}</v-btn>
                            <v-btn small color="secondary" dark @click="handleAction('close')">{{ loc('Cancel') }}</v-btn>
                        </v-row>
                    </v-card-text>
                </v-card>
    </v-container>
</template>

<script>
import { mapState } from 'vuex';
import AdminTitleBar from "./Admin_TitleBar";

export default {
    name: "AdminUserRemove",
    props: {
        id: { type: [Object, Number, String] },
        userRecord: { type: [Object, Boolean] },
        stage: { type: [String, Boolean] }
    },
    components: { AdminTitleBar },
    data() {
        return {
            titleBarActions: []
        }
    },
    computed: {
        ...mapState(['users', 'localize']),
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },          
        handleAction: function(action, params) {
            this.$emit("actionhandler", action, params);
        },
        handleRemove: function(){
            this.$emit("actionhandler", 'removeUserConfirm', this.userRecord._id);
        }
    }
}
</script>

