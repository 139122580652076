<template>
    <v-container>
        <div v-if="authError">
            {{ loc('Please Login') }}
        </div>
        <div v-else-if="!users.currentUser || !users.currentUser.isAdmin" >
            {{ loc("You do not have permission to do that.") }}
        </div>
        <div v-else>
            <div v-if="isLoading">
                {{ loc('Loading...') }}
            </div>
            <div v-else-if="manageRecord === 'courseRecord'">
                <AdminCoursesEdit :courseRecord="courseRecord" v-on:actionhandler="handleAction" />
            </div>
            <div v-else-if="manageRecord === 'removeRecord'">
                <AdminCoursesRemove :record="courseRecord" v-on:actionhandler="handleAction" />
            </div>            
            <div v-else>
                <AdminCoursesList :courseList="courseList" v-on:actionhandler="handleAction" :viewmain="viewmain" :viewlanguage="viewlanguage" />
            </div>
        </div>
        <!-- <v-row class='mt-4'><pre>{{manageRecord}}</pre></v-row>
        <v-row class='mt-4'><pre>{{courseRecord}}</pre></v-row> -->
    </v-container>
</template>

<script>
import { mapState } from "vuex";
// import config_system from '../config/system';
// import AdminTitleBar from "./Admin_TitleBar";
import AdminCoursesList from "./Admin_Courses_List";
import AdminCoursesEdit from "./Admin_Courses_Edit";
import AdminCoursesRemove from "./Admin_Courses_Remove";
import _filter from 'lodash/filter';
import { filterId } from '../lib/filter';

export default {
    name: "AdminCourses",
    components: {  AdminCoursesList, AdminCoursesEdit, AdminCoursesRemove },
    data() {
        return {
            isLoading: false,
            itemsPerPage: 15,
            viewmain: false,
            viewlanguage: false,
            after: false,
            courseRecord: false,
            manageRecord: false
        }
    },
    computed: {
        ...mapState(["authError", "users", "courses", "localize"]),
        courseList: function() {
            let list = this.courses.list;
            if(this.viewmain) list = _filter(list, {main: this.viewmain})
            if(this.viewlanguage) list = _filter(list, {lang: this.viewlanguage})
            console.log("List of courses", list)
            return Object.values(list);
        }
    },
    methods: {
        getList: function() {
            this.$store.dispatch("courses/getList")
                .then((result) => {
                    
                    if(this.$route.params.pathMatch){
                        // this is for handling page refreshes when the URL includes a record id.
                        console.log("Is this an id?", this.$route.params.pathMatch);
                        if(result[this.$route.params.pathMatch]){
                            this.handleAction('edit', result[this.$route.params.pathMatch]);
                        }
                    }    
                    this.isLoading = false;                
                })
                .catch((e) => {
                    console.log('error in admin courses getList', e);
                })
        },
        handleAction: function(action, params, opts) {
            console.log("AdminCourses handleAction", action, params);

            switch(action){
                case 'gotocourse':
                    {
                        let newpath = '/Course/' + params._id;
                        this.courseRecord=false
                        this.manageRecord = false;
                        this.$router.push(newpath);
                    }                    
                    break;
                case 'close': 
                    {
                        console.log("closing", this.$route.query)
                        this.courseRecord = false;
                        this.manageRecord = false;
                        if(!opts && this.$route.query && this.$route.query.after){
                            switch(this.$route.query.after){
                                case 'course':
                                    opts = '/Course/' + filterId(this.$route.params.pathMatch)
                            }
                        }
                        
                        let newpath = (opts && typeof opts === "string" && opts != "close") ? opts : "/admin/courses";
                        console.log("Let's go to ", newpath, this.$route)
                        if(this.$route.path !== newpath){
                            this.$router.push(newpath);
                        } 
                    }
                    break;
                case 'edit':
                    if(this.courses.list[params._id]){
                        this.courseRecord = this.courses.list[params._id];
                        this.manageRecord = 'courseRecord';
                        let newpath = "/admin/courses/"+params._id;
                        if(this.$route.path !== newpath){
                            this.$router.push(newpath);
                        }                    
                    }
                    break;
                case 'remove':
                    if(this.courses.list[params._id]){
                        this.courseRecord = this.courses.list[params._id];
                        this.manageRecord = 'removeRecord';
                    } 
                    break;
                case 'removeConfirmed':
                    {
                        this.$store.dispatch('courses/remove', params)
                            .then((result) => {
                                if(result){
                                    this.handleAction('close');
                                    this.$store.commit("content/getList", {reset: true});
                                }
                            })
                    }                    
                    break;
                case 'add':
                    {
                        console.log("Add a course");
                        this.courseRecord = { _id: 'new' };
                        this.manageRecord = 'courseRecord';
                        let newpath = "/admin/courses/new";
                        if(this.$route.path !== newpath){
                            this.$router.push(newpath);
                        }
                    }  
                    break;
                case 'save':
                    console.log("Save course", params);
                    this.$store.dispatch("courses/save", params )
                        .then((result) => {
                        // console.log('courses.save result', result);
                            // this.$store.dispatch('setMessage', false, {root: true});
                            if(opts){ 
                                this.handleAction('close', params, opts);
                            } else {
                                let newpath = "/admin/courses/"+result._id;
                                if(this.$route.path !== newpath){
                                    this.$router.push(newpath);
                                }   
                            }
                            this.courseRecord = result 
                        });
                    break;
                    case 'setviewmain':
                        this.viewmain = params;
                        break;
                    case 'setviewlanguage':
                        this.viewlanguage = params;
            }

        },
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },  
    },
    created() {
        console.log("Creating Admin.Courses", this.$route)
        this.isLoading = true;
        this.getList();
    }
}
</script>


