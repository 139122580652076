<template>
    <div>
        <AdminTitleBar
            :title="loc('Manage Lessons') + ' - ' + course.title "
            :actions="titleBarActions"
            v-on:actionhandler="handleAction"
        />  
        <v-row v-if="tablePrefs">
            <div v-for="(item, idx) in headers" v-bind:key="idx">
                <v-checkbox style="min-width: 120px;"
                    :name="idx"
                    v-model="item.show"
                    :label="loc(item.text)"
                ></v-checkbox>
            </div>
        </v-row>  
        <div v-if="recordList">
            <v-row class="searchrow">
                <v-select :items="courseSelect" v-bind:value="course && course._id" label="Course" @change="handleCourseChange" ></v-select>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="search"
                    :label="loc('Search')"
                    single-line
                    hide-details
                ></v-text-field>
            </v-row>
            <v-data-table
                :items-per-page="itemsPerPage"
                :headers="tableHeaders"
                :footer-props="{ itemsPerPageOptions:[10,20,30,40,50,100,200] }"
                :items="lessonList"
                :search="search"
                class="courseList elevation-1 my-8 pb2"
            >
                <template v-slot:[`item.title`]="{ item }" class="linkcolumn titlecolumn">
                    <router-link :to="'/lessons/' + item.course + '/' + item._id + '/selfpaced'">{{ item.title }}</router-link>
                </template>            
                <template v-slot:[`header.actions`]="{header}">
                    {{header.text}}
                    <span @click="tablePrefs=!tablePrefs" class="dtableactionicon" ><v-icon small >edit</v-icon> {{ loc("Columns") }}</span>
                </template>
                <template v-slot:[`item.course`]="{ item }">
                    {{ course && (course._id === item.course) && course.title }}
                </template>
                <template v-slot:[`item.actions`]="{ item }" class="actioncolumn">
                    <span class="manageusericons">
                        <v-btn small color="secondary" dark :to="'/admin/lessonmaterials/' + item.course + '/' + item._id" class="mr-1">{{ loc("Materials") }}</v-btn>
                        <v-btn small color="primary" dark @click="handleEdit(item)" class="mr-1">{{ loc("Edit") }}</v-btn>
                        <v-btn small color="error" dark @click="handleRemove(item)">{{ loc("Remove") }}</v-btn>
                    </span>
                </template>
            </v-data-table>
            <!-- <pre>{{lessonList}}</pre> -->
        </div>
        <div v-else>
            {{ loc('No data yet.') }}
        </div>                
    </div>
       
</template>

<script>
import { mapState } from 'vuex';
import AdminTitleBar from "./Admin_TitleBar";
import { filterId } from '../lib/filter';

export default {
    name: "AdminLessonsList",
    props: {
        course: { type: Object, default: () => ({}) },
        recordList: { type: Array }
    },
    components: { AdminTitleBar },
    data () {
        return {
            itemsPerPage: 30,
            tablePrefs: false,
            manageRecord: false,
            search: "",
            headers: {
                _id: {
                    text: "ID",
                    value: "_id"
                },
                title: {
                    text: "Title",
                    value: "title",
                    sortable: true,
                    align: "start",
                    show: true
                },   
                course: {
                    text: "Course",
                    value: "course",
                    sortable: true,
                    align: "start",
                    show: false
                },     
                type: {
                    text: "Type",
                    value: "type",
                    sortable: true,
                    align: "start",
                    show: true
                },                   
                lang: {
                    text: "Language",
                    value: "lang",
                    sortable: true,
                    align: "start",
                    show: true
                },
                order: {
                    text: "Order",
                    value: "order",
                    sortable: true,
                    align: "start",
                    show: true
                },
                modified: {
                    text: "Modified",
                    value: "modified",
                    sortable: true,
                    align: "start",
                    show: false
                }
            },
            titleBarActions: [
                    { text: "Refresh List", key: "refresh" },
                    { text: "Add Lesson", key: "add" },
                    { text: "Courses List", key: "listcourses"}
            ]
        }      
    },
    computed: {
        ...mapState(['users', 'localize', 'courses', 'adminstore']),
        tableHeaders: function() {
            var temp = Object.assign({}, this.headers);
            let columns = []
            for(let k in temp){
                if(temp[k].show){
                    temp[k].sortable = true;
                    temp[k].align = temp[k].align || "start";
                    columns.push(temp[k]);
                }
            }

            columns.push({ text: "Actions", sortable: false, value: "actions" });
            return columns;   
        },
        lessonList: function() { return this.recordList },
        courseSelect: function() {
            let list = [];
            for(let k in this.courses.list){
                list.push({value: this.courses.list[k]._id, text: this.courses.list[k].title});
            }
            list.push({value:'All', text:'All Courses'});
            return list;
        }
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },
        handleEdit: function(evt) {
            this.$emit("actionhandler", 'edit', evt)
        },
        handleRemove: function(evt) {
            this.$emit("actionhandler", 'remove', evt)
        },
        handleAction: function(action, params){
            this.$emit("actionhandler", action, params);
        },     
        handleCourseChange: function(newcourseid, params){
            console.log(newcourseid, params);
            newcourseid =  filterId(newcourseid);
            if(newcourseid && newcourseid !== this.course._id){
                this.$router.push("/admin/lessons/" + newcourseid);
            }
        }   
    }
    
}
</script>