<template>
    <div>
        <AdminTitleBar
            :title="loc('Manage Course')"
            :actions="titleBarActions"
            v-on:actionhandler="handleAction"
        />        
        <v-card id="editcourseproperties" class="pa-2">
            <v-card-text class='courseform'>
                <v-row justify="space-between" v-if="isnew">
                    <v-text-field
                        name="ID"
                        v-model="record._id"
                        :label="loc('ID')"
                        :hint="loc('If you leave the ID blank we will generate one for you.')"
                        persistent-hint                        

                    ></v-text-field>
                </v-row>                
                <v-row justify="space-between">
                    <v-text-field
                        name="title"
                        v-model="record.title"
                        :label="loc('Title')"
                    ></v-text-field>
                </v-row>     
                <v-row justify="space-between">
                    <v-select
                        name="main"
                        v-model="record.main"
                        :label="loc('Main Course')"
                        :items="maincourses"
                    ></v-select>
                </v-row>     
                <v-row justify="space-between">
                    <v-text-field
                        name="reftitle"
                        v-model="record.reftitle"
                        :label="loc('Reference Title')"
                        :hint="loc('Primarily used in integrations')"
                        persistent-hint   
                    ></v-text-field>
                </v-row>                   

                <v-row justify="space-between">
                    <v-select
                        name="lang"
                        v-model="record.lang"
                        :label="loc('Language')"
                        :items="languages"
                    ></v-select>
                </v-row>   
                
                <v-row justify="space-between">
                    <v-text-field
                        name="order"
                        v-model="record.order"
                        :label="loc('Display Order')"
                    ></v-text-field>
                </v-row>

                <v-row justify="space-between">
                    <v-text-field
                        name="video"
                        v-model="record.video"
                        :label="loc('Intro Video URL')"
                    ></v-text-field>
                </v-row>                  
                <v-row justify="space-between">
                    <v-text-field
                        name="smallbanner"
                        v-model="record.smallbanner"
                        :label="loc('Small Banner Image')"
                        :hint="loc('Used for course listings')"
                        persistent-hint                           
                    ></v-text-field>
                </v-row>  
                <v-row justify="space-between">
                    <v-select
                        name="status"
                        v-model="record.status"
                        :label="loc('Status')"
                        :items="[{text:'Active',value:'Active'},{text:'Inactive',value:'Inactive'}]"
                    ></v-select>
                </v-row>   
                <v-row justify="space-between">
                    <v-text-field
                        name="timerequired"
                        v-model="record.timerequired"
                        :label="loc('Time Required')"
                    ></v-text-field>
                </v-row>
                <v-row justify="space-between">
                    <v-select
                        name="certificate"
                        v-model="record.certificate"
                        :label="loc('Certificate Template')"
                        :items="certificateTemplates"
                        :hint="loc('The template to use for the certificate at the end of the course.  If empty, no certificate will be given.')"
                        persistent-hint  
                    ></v-select>
                </v-row> 
                <v-row justify="space-between">
                    <v-text-field
                        name="sections"
                        v-model="record.sections"
                        :label="loc('Lesson Sections')"
                        :hint="loc('Optional.  This should be a comma separated list in the order the sections should appear on the course page.')"
                        persistent-hint  
                    ></v-text-field>
                </v-row> 
                <v-row justify="space-between" class="pt-4 pb-4">
                    <div style="width: 100%; padding-bottom: 12px;">
                        <label for="course-description" class="v-label theme--light" style="left: 0px; right: auto; position: relative;">{{ loc("Course Description") }}</label>
                    </div>
                    <SharedEditor
                        name="description"
                        :record="record.description"
                        :handleChange="handleDescriptionChange"
                        id="course-description"
                    ></SharedEditor>
                </v-row>  
                <v-row justify="space-between" class="pt-4 pb-4">
                    <div style="width: 100%; padding-bottom: 12px;">
                        <label for="course-description" class="v-label theme--light" style="left: 0px; right: auto; position: relative;">{{ loc("Additional Description") }}</label>
                    </div>
                    <SharedEditor
                        name="description2"
                        :record="record.description2"
                        :handleChange="handleDescription2Change"
                        id="course-description2"
                    ></SharedEditor>
                </v-row>                  
                <v-row justify="space-between" class="pb-3">
                    <div style="width: 100%; padding-bottom: 12px;">
                        <label for="course-style" class="v-label theme--light" style="left: 0px; right: auto; position: relative;">{{ loc("CSS Styles for Course Home Page") }}</label>
                    </div>                    
                    <CodeEditor :data="record.style" :handleChange="handleStyle" mode="text/css" />
                </v-row>
                <v-divider class="mx-4"></v-divider>                         
                <v-row class='actionrow mt-2'>
                    <v-btn small color="primary" dark @click="handleSave()" class="mr-1">{{ loc("Save Changes") }}</v-btn>
                    <v-btn small color="primary" dark @click="handleSave('close')" class="mr-1">{{ loc("Save and Go to List") }}</v-btn>
                    <v-btn small color="primary" dark @click="handleSave('course')" class="mr-1">{{ loc("Save and Go to Course") }}</v-btn>
                    <v-btn small color="secondary" dark @click="handleCancel('close')" class="mr-1">{{ loc("Cancel") }}</v-btn>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import SharedEditor from './Shared_Editor';
import CodeEditor from './Shared_Editor_Code';
import AdminTitleBar from "./Admin_TitleBar";
import _filter from 'lodash/filter';
// import _sortBy from 'lodash/sortBy';


export default {
    name: "AdminCoursesEdit",
    props: {
        // list: { type: Array },
        editId: { type: String },
        courseRecord: { type: Object }
    },
    components: { SharedEditor, CodeEditor, AdminTitleBar },
    data() {
        return {
            record: this.courseRecord,
            isnew: this.courseRecord && this.courseRecord._id === 'new',
            titleBarActions: [
                {
                    text: "Close",
                    key: "close"
                },
                {
                    text: "Go To Course",
                    key: 'gotocourse'
                }
            ]              
        }
    },
    computed: {
        ...mapState(['users', 'localize', 'adminstore', 'content']),
        languages: function(){
            let list = (this.adminstore && this.adminstore.siteconfig && this.adminstore.siteconfig.languages) || {en:"English"};
            let out = [];
            for(let k in list){
                out.push({ value: k, text: list[k] });
            }
            return out;
        },
        maincourses: function(){
            console.log("maincourses", this.adminstore.siteconfig.maincourses);
            // for(let k in this.adminstore.siteconfig.maincourses)

            if(!this.adminstore || !this.adminstore.siteconfig || !this.adminstore.siteconfig.maincourses) return [];

            return Object.keys(this.adminstore.siteconfig.maincourses).sort();
        },
        certificateTemplates: function(){
            let out = [{value:false, text:"None"}];

            let list = _filter(this.content.list, {type: 'certificate'});
            for(let i in list){
                out.push({
                    text: list[i].title,
                    value: list[i]._id
                })
            }
            console.log('certificateTemplates', this.content.list);
            return out;
        }
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },   
        handleDescriptionChange: function(val){
            console.log(val);
            this.record.description = val;
        },
        handleDescription2Change: function(val){
            console.log(val);
            this.record.description2 = val;
        },        
        handleDescriptionClose: function(val){

        },
        handleSave: function(val){
            if(val === "course") val = "/Course/"+this.record._id;
            this.$emit("actionhandler", "save", this.record, val);
        },
        handleCancel: function(val){
            this.$emit("actionhandler", 'close', val)
        },
        handleAction: function(action, params){
            this.$emit("actionhandler", action, params || this.record);
        },
        handleStyle: function(val, a, b){
            // console.log('style field updated', a, b, val);
            this.record.style = val;
        },
    }
  
}
</script>