<template>
    <div>
        <AdminTitleBar
            :title="loc('Manage Uploaded Files')"
            :actions="titleBarActions"
            v-on:actionhandler="handleAction"
        />        
        <v-row v-if="tablePrefs">
            <div v-for="(item, idx) in headers" v-bind:key="idx">
                <v-checkbox style="min-width: 120px;"
                    :name="idx"
                    v-model="item.show"
                    :label="loc(item.text)"
                ></v-checkbox>
            </div>
        </v-row>   
        <div v-if="fileList">
            <v-row class="searchrow">
                <div style="font-size: 2rem; font-weight: 400;">/{{context}}</div><v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="search"
                    :label="loc('Search')"
                    single-line
                    hide-details
                ></v-text-field>
            </v-row>
            <v-data-table
                :items-per-page="itemsPerPage"
                :headers="tableHeaders"
                :footer-props="{ itemsPerPageOptions:[10,20,30,40,50,100,200] }"
                :items="list"
                :search="search"
                class="filelist admintable elevation-1 my-8 pb2"
            >
                <template v-slot:[`header.actions`]="{header}">
                    {{header.text}}
                    <span @click="tablePrefs=!tablePrefs" class="dtableactionicon"><v-icon small >edit</v-icon> {{ loc("Columns") }}</span>
                </template>
                <template v-slot:[`item.title`]="{ item }" class="titlecolumn linkcolumn">
                     <router-link :to=" '/' + context + ((item.filename && item.filename.length) ? ('/' + item.filename) : '') " v-if="item.type==='file'" target="_blank">{{item.filename}}</router-link>
                    <span v-else><v-icon small class="mr-6">{{item.icon || 'folder'}}</v-icon><router-link :to="'/admin/files/' + (item.filename && item.filename.length ? item.filename : '')" >{{item.title}}</router-link></span>
                </template>
                <template v-slot:[`item.actions`]="{ item }" class="actioncolumn">
                    <span class="manageusericons" v-if="item.type === 'file'">
                        <v-btn v-if="showEditBtn(item)" small color="primary" dark @click="handleEdit(item)" class="mr-1">{{ loc("Edit") }}</v-btn>
                        <v-btn small color="error" dark @click="handleRemove(item)">{{ loc("Remove") }}</v-btn>
                    </span>
                </template>
            </v-data-table>
            <v-row>
                <p class="note">{{loc('To modify or add directories please contact support.')}}</p>
            </v-row>
        </div>
        <div v-else>
            {{ loc('Loading.') }}
        </div>
        
    </div>
</template>

<script>
import { mapState } from 'vuex';
import AdminTitleBar from "./Admin_TitleBar";
import _find from "lodash/find";

export default {
    name: "AdminCoursesList",
    props: {
        fileList: { type: [Array, Boolean] },
        context: { type: String, default: "" }
    },
    components: { AdminTitleBar },
    data() {
        return {
            itemsPerPage: 15,
            tablePrefs: false,
            manageRecord: false,
            search: "",
            // titleBarActions: [
            //     { text: "Refresh List", key: "refresh" },
            //     { text: "Upload File", key: "toggleupload" },
            //     { text: "Add File", key: "add" }
            // ],            
            headers: {
                title: {
                    text: "Title",
                    value: "title",
                    align: "start",
                    show: true
                },
                filename: {
                    text: "File Name",
                    value: "filename"
                },
                mime: {
                    text: "Mime Type",
                    value: "mime",
                    show: true
                },
                created: {
                    text: "Created",
                    value: "created",
                    show: true
                },
                modified: {
                    text: "Modified",
                    value: "modified"
                },
                type: {
                    text: "Type",
                    value: "type"
                },
                size: {
                    text: "Size",
                    value: "size",
                    show: true
                }
            }            
        }
    },
    computed: {
        ...mapState(['users', 'localize', 'adminstore', 'files']),
        tableHeaders: function() {
            var temp = Object.assign({}, this.headers);
            let columns = []
            for(let k in temp){
                if(temp[k].show){
                    temp[k].sortable = true;
                    temp[k].align = temp[k].align || "start";
                    // if(k !== "title") temp[k].width="1%";
                    columns.push(temp[k]);
                }
            }

            columns.push({ text: "Actions", sortable: false, value: "actions", width: "210" });
            return columns;            
        },
        list: function() {
            if(this.context && this.context.length){
                let l = this.fileList.slice(0);
                l.unshift({title: "Up", filename: false, icon: "north"})
                return l;
            } else {
                return this.fileList;
            }            
        },
        titleBarActions: function(){ 
            if(this.context && this.context.length) {
                return [
                    { text: "Refresh List", key: "refresh" },
                    { text: "Upload File", key: "toggleupload" },
                    // { text: "Add File", key: "add" }
                ]
            } else {
                return [
                    { text: "Refresh List", key: "refresh" }
                ]
            }
        }
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },  
        handleEdit: function(evt) {
            this.$emit("actionhandler", 'edit', evt)
        },
        handleRemove: function(evt) {
            this.$emit("actionhandler", 'remove', evt)
        },
        handleAction: function(action, params) {
            this.$emit("actionhandler", action, params)
        },
        itemtarget: function(item) {
            if(item.type === 'dir'){
                return '/admin/files/' + item.filename;
            } else if(item.type === 'file') {
                return '/' + this.context + '/' + item.filename;
            } else {
                return '/';
            }
        },
        showEditBtn: function(item) {
            return item && item.mime && item.mime.match(/text/);
        }
    }
  
}
</script>

