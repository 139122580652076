<template>
    <div>
        <AdminTitleBar
            :title="loc('Manage Enrollment') + ' - ' + course.title "
            :actions="titleBarActions"
            v-on:actionhandler="handleAction"
        />  
        <h4 v-text="'Editing ' + record._id" ></h4>
        <v-card id="editenrollmentproperties" class="pa-2">
            <v-card-text enrollment='adminenrollmentform'>
                <v-row v-if="userRecord" >
                    <div class="label">{{ loc('User') }}</div>
                    <div class="value">{{userRecord.firstname}} {{userRecord.lastname}}</div>
                </v-row>
                <v-row >
                    <div class="label">{{ loc('Course') }}</div>
                    <div class="value">{{course.title}}</div>
                </v-row>                
                <v-row >
                    <div class="label">{{ loc('Class') }}</div>
                    <div class="value">{{classRecord.name}}</div>
                </v-row>  
                <v-divider class="ma-4"></v-divider>
                <v-row justify="space-between" class="mt-4" v-if="!userRecord">
                    <v-autocomplete
                        name="user"
                        v-model="record.user"
                        :label="loc('User')"
                        :items="userList"
                        dense
                    ></v-autocomplete>
                </v-row>   
                <v-row justify="space-between" class="mt-4">
                    <v-text-field
                        name="regcode"
                        v-model="record.regcode"
                        :label="loc('Reg Code')"
                    ></v-text-field>
                </v-row>                   
                <v-row justify="space-between">
                    <v-text-field
                        name="regdate"
                        v-model="record.regdate"
                        :label="loc('Registration Date')"
                    ></v-text-field>
                </v-row>                   
                <v-row justify="space-between">
                    <v-text-field
                        name="completed"
                        v-model="record.completed"
                        :label="loc('Date Completed')"
                    ></v-text-field>
                </v-row>                   

                <v-divider class="ma-4"></v-divider>                         
                <v-row class='actionrow mt-2'>
                    <v-btn small color="primary" dark @click="handleSave()" class="mr-1">{{ loc("Save Changes") }}</v-btn>
                    <v-btn small color="primary" dark @click="handleSave('close')" class="mr-1">{{ loc("Save and Go to List") }}</v-btn>
                    <v-btn small color="primary" dark @click="handleSave('class')" class="mr-1">{{ loc("Save and Go to Class") }}</v-btn>
                    <v-btn small color="secondary" dark @click="handleCancel('close')" class="mr-1">{{ loc("Cancel") }}</v-btn>
                </v-row>
            </v-card-text>
        </v-card>        
    </div>
       
</template>

<script>

/*
    trackquizzes: { f: filter.noop },
    trackexam: { f: filter.noop },
    instructortrack: { f: filter.noop }
*/
import { mapState } from 'vuex';
// import SharedEditor from './Shared_Editor';
// import CodeEditor from './Shared_Editor_Code';
import AdminTitleBar from "./Admin_TitleBar";
import _sortBy from 'lodash/sortBy';

export default {
    name: "AdminEnrollmentEdit",
    props: {
        editId: { type: String },
        enrollmentRecord: { type: Object, default: () => ({}) },
        classRecord: { type: Object, default: () => ({}) },
        // userRecord: { type: Object, default: () => ({}) },
        course: { type: Object, default: () => ({}) }
    },
    components: { AdminTitleBar },
    data() {
        return {
            record: this.enrollmentRecord,
            isnew: this.contentRecord && this.contentRecord._id === 'new',
            titleBarActions: [
                {
                    text: "Close",
                    key: "close"
                }
            ]              
        }
    },
    computed: {
        ...mapState(['users', 'localize', 'adminstore', 'courses']),
        languages: function(){
            let list = (this.adminstore && this.adminstore.siteconfig && this.adminstore.siteconfig.languages) || {en:"English"};
            let out = [];
            for(let k in list){
                out.push({ value: k, text: list[k] });
            }
            return out;
        },
        // instructors: function() {
        //     let course = this.record && this.record.course;
        //     console.log('preparing instructor list', course, this.users.instructors, this.users.instructors[course])

        //     if(course && this.users.instructors && this.users.instructors[course]) {
        //         let out = [];
        //         for(let k in this.users.instructors[course]){
        //             // out.push({value: k, text: this.users.instructors[course][k].firstname + " " + this.users.instructors[course][k].lastname });
        //             out.push({value: k, text: this.users.instructors[course][k].fullname  })
        //         }
        //         out = _sortBy(out, ['text'])
        //         return out;
        //     } else {
        //         return [];
        //     }
        // },
        userRecord: function(){
            if(!this.record || !this.users || !this.users.list ) return {};

            return this.users.list[this.record.user];
        },
        userList: function(){
            if(!this.users.list) return [];

            let list = [];
            for(let k in this.users.list){
                list.push({
                    value: this.users.list[k]._id, 
                    text: this.users.list[k].firstname + " " + this.users.list[k].lastname + " (" + this.users.list[k].email + ")"})
            }
            return list;
        }
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },  
        handleDescriptionChange: function(val){
            console.log(val);
            this.record.description = val;
        },        
        handleSave: function(val){
            if(val === "course") val = "/Course/"+this.record.course;
            if(!this.record.classid) this.record.classid = this.classid;
            this.$emit("actionhandler", "save", this.record, val);
        },
        handleCancel: function(val){
            this.$emit("actionhandler", 'close', val)
        },
        handleAction: function(action, params){
            this.$emit("actionhandler", action, params);
        },    }
    
}
</script>
