<template>
    <div class="certificationList my-3 pa-3" >
        <div v-for="(certdate, courseid) in certificationList" :key="courseid">
            <div v-if="editId === courseid" class='certificationrow isEditing elevation-2 my-4 px-4' >
                <v-row>
                    <v-col cols="12" md="4" sm="6" class="certcoursename">
                        <h4>{{courseTitle(courseid)}}</h4>
                    </v-col>
                    <v-col cols="12" md="2" sm="6">
                        <v-text-field v-model="certificationList[courseid]" name="regdate" :label="loc('Enrolled Date')"></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col v-if="isEditor"  class='editCertificationButton' sm="12" md="3">
                        <v-btn small color="primary" dark @click="handleSave(courseid)">{{ loc('Save') }}</v-btn>
                        <v-btn small color="secondary" dark @click="handleEditCancel">{{ loc("Cancel") }}</v-btn>

                    </v-col>  
                </v-row>
            </div>
            <div v-else-if="removeId === courseid" class='enrollmentrow isEditing elevation-2 my-4 pa-4'>
                <v-row class="my-3">
                    Please confirm removal of this certification record.
                </v-row>
                <v-row class='editCertificationButton' >
                    <v-btn small color="error" dark @click="handleRemove(courseid)" >{{ loc("Remove") }}</v-btn> &nbsp;
                    <v-btn small color="secondary" dark @click="handleEditCancel">{{ loc("Cancel") }}</v-btn>
                </v-row>
            </div>
            <div v-else class='certificationrow' >
                <v-row>
                    <v-col cols="12" md="4" sm="6" class="certcoursename">
                        <h4>{{courseTitle(courseid)}}</h4>
                    </v-col>
                    <v-col cols="12" md="2" sm="6">
                        {{ filterDate(certdate)}}
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col v-if="isEditor"  class='editCertificationButton' sm="12" md="3">
                        <v-btn small color="primary" dark @click="handleEdit(courseid)">{{ loc('Edit') }}</v-btn>
                        <v-btn small color="error" dark @click="handleConfirmRemove(courseid)" >{{ loc("Remove") }}</v-btn>

                    </v-col>  
                </v-row>
            </div>
        </div>
        <div v-if="isEditor && !addRecord">
            <v-row class="mb-2 classrow" v-if="availablecourses">
                <div class="label addLabel" style='margin-left:0;'>{{ loc("Add Course") }}:</div>
                <div class='value'><v-select :label="loc('Select a Course')" :items="availablecourses" name="addcert" @change="handleAdd($event)" /></div>
            </v-row>
        </div>
       
    </div>
</template>

<script>
import { mapState } from "vuex";
import _sortBy from 'lodash/sortBy';

import {
        filterDate as _filterDate,
        filterDateTime as _filterDateTime,
        isValidDate as _isValidDate
    } from "../lib/filter";

export default {
    name: "UserCertificationList",
    props: {
        userId: { type: String },
        record: { type: [Object, Boolean], default: false },
        noEdit: { type: Boolean, default: false }
    },
    data() {
        return {
            certificationList: this.record.certifications,
            editId: false,
            removeId: false,
            addRecord: false,
        }
    },
    computed: {
        ...mapState(["authError", "users", "courses", "localize"]),
        isEditor: function() {
            return !this.noEdit && this.users.currentUser.isEditor;
        },
        availablecourses: function() {
            let out = [ ];
            for(let k in this.courses.list){
                let title = (this.courses.list[k].title) ? (this.courses.list[k].reftitle +' - '+ this.courses.list[k].title + " (" + k + ")" )  : k;
                if(!this.certificationList || !this.certificationList[k]) out.push({value: k, text: title  });
            }

            return _sortBy(out, 'text');        }
    },
    methods: {
        handleEdit: function(courseid){
            console.log('handleEdit', courseid);
            this.reset();
            this.editId = courseid;
        },
        handleSave: function(courseid){
            console.log('handleSave', courseid);
            this.$store.dispatch("users/save", {_id: this.record._id, certifications: this.certificationList})
                .then((result) => {
                    console.log("Returned to certification list from users.save", result);
                    this.addRecord = false;
                    this.reset();
                })
        },
        handleEditCancel: function(courseid){
            console.log('handleEditCancel', courseid);
            this.reset(courseid);
        },
        handleConfirmRemove: function(courseid){
            console.log('handleConfirmRemove', courseid);
            this.reset();
            this.removeId = courseid;
        },
        handleRemove: function(courseid){
            console.log("handleRemove", courseid);
            if(this.certificationList && this.certificationList[courseid]) delete this.certificationList[courseid];
            this.handleSave(courseid);
        },        
        courseTitle: function(id){
            return this.courses.list[id].title + " - " + this.courses.list[id].reftitle;
        },
        handleAdd: function(courseid){
            console.log('handleAdd', courseid);
            this.reset();
            this.certificationList = this.certificationList || {};
            this.certificationList[courseid] = this.filterDate(Date.now());
            this.addRecord = courseid;
            this.editId = courseid;
        },
        reset: function(courseid){
            if(this.addRecord && this.certificationList && this.certificationList[this.addRecord]){
                delete this.certificationList[this.addRecord];
            }
            this.removeId = false;
            this.editId = false;
            this.addRecord = false;
        },
        filterDate: function(val, format){
            format = format || "YYYY-MM-DD";
            return _filterDate(val, format);
        },        
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        }      

    }
}
</script>