import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

const axiosconfig = { headers: {} };

export default {
    namespaced: true,
    state: {
        siteconfig: false
    },
    mutations: {
        /** 
         * Sets the whole object as a block.  Only useful for initial set because it will
         * effectively be non-reactive on updates because the changes are deep.
         * */
        setSiteConfig (state, val) {
            Vue.set(state, 'siteconfig', val);
        },

        setSiteConfigValues (state, val) {
            for(let k in val){
                Vue.set(state.siteconfig, k, val[k]);
            }
        }
    },
    actions: {
        getSiteConfig ({commit, state}, data) {
            return axios.get("/app_admin/getSiteConfig", axiosconfig)
            .then((response) => {
                let result = response && response.data;
                if(result) commit('setSiteConfig', result);
                return result;
            })
        },
        setSiteConfig ({commit, state}, data) {
            return axios.post('/app_admin/setSiteConfig', data, axiosconfig)
                .then((response) => {
                    let result = response && response.data;
                    if(result) commit('setSiteConfigValues', result);
                    // if(result) commit('setConfigItem');
                    return result;
                })

        }
    }
}