<template>
    <v-container class="LMSAdmin" fluid>
        <v-row v-if="users.loggedout || !users.currentUser" class="pt-16">
             <router-link to="/login">{{ loc('Please Login') }}</router-link>
        </v-row>
        <v-row v-else-if="!users.currentUser.roles" class="pt-16">
            <h3>{{ loc("You do not have permission to do that.") }}</h3>
        </v-row>

        <div v-else-if="adminpanel==='users'">
            <AdminUsers />
        </div>
        <div v-else-if="adminpanel==='config'">
            <AdminConfig />
        </div>               
        <div v-else-if="adminpanel==='content'">
            <AdminContent />
        </div>
        <div v-else-if="adminpanel==='classes'">
            <AdminClasses />
        </div>        
        <div v-else-if="adminpanel==='comments'">
            <AdminComments />
        </div>          
        <div v-else-if="adminpanel==='courses'">
            <AdminCourses />
        </div>
        <div v-else-if="adminpanel==='enrollment'">
            <AdminEnrollment />
        </div>         
        <div v-else-if="adminpanel==='files'">
            <AdminFiles />
        </div>     
        <div v-else-if="adminpanel==='lessons'">
            <AdminLessons />
        </div>   
        <div v-else-if="adminpanel==='lessonmaterials'">
            <AdminLessonMaterials />
        </div>              
        <div v-else-if="adminpanel==='localization'">
            <AdminLocalization />
        </div>  
        <div v-else-if="adminpanel==='reports'">
            <AdminReports />
        </div>          
        <div v-else-if="adminpanel==='surveys'">
            <AdminSurveys />
        </div>         
        <div v-else>
            <v-row>
                 <h2>{{ loc("Site Administration") }}</h2>
            </v-row>
            <v-row id="admin-index-intro" style="min-height:130px;" class='feature' >
                    <ContentBlockSimple :record="{type:'snippet', _id:'adminindexintro'}" :options="{load: true}" />
            </v-row>
            <v-row>
               
                <v-list >
                    <v-list-item v-for="item in menulist" :key="item.title" link :to="item.url" >
                        <v-list-item-icon v-if="item.icon">
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title v-text="loc(item.title)"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-row>
        </div>
    </v-container>
</template>

<script>
import { mapState } from 'vuex';
import config_system from '../config/system';
// import siteconfig from '../config/site.js';
import ContentBlockSimple from './Content_Block_Simple';
import AdminClasses from './Admin_Classes';
import AdminComments from "./Admin_Comments_List";
import AdminConfig from './Admin_Config';
import AdminContent from "./Admin_Content";
import AdminCourses from './Admin_Courses';
import AdminEnrollment from './Admin_Enrollment';
import AdminFiles from './Admin_Files';
import AdminLessonMaterials from './Admin_Lesson_Materials_List';
import AdminLessons from './Admin_Lessons';
import AdminLocalization from './Admin_Localization';
import AdminReports from './Admin_Reports.vue';
import AdminSurveys from './Admin_Surveys';
import AdminUsers from './Admin_Users';
import store_admin from '../lib/store_admin';

const navitems = [ 
    { title: "Admin Index", url: "/admin/index", group: 'admin' },
    { title: "Users", url: "/admin/users" },
    { title: "Comments", url: "/admin/comments" },
    { title: "Content", url: "/admin/content" },
    { title: "Courses", url: "/admin/courses" },
    { title: "Classes", url: "/admin/classes" },
    { title: "Surveys", url: "/admin/surveys" },
    { title: "Exports", url: "/admin/reports" },
    { title: "Files", url: "/admin/files" },
    { title: "Site Config", url: "/admin/config"},
    { title: "Translations", url: "/admin/localization"}
]

export default {
    name: "Admin",
    metaInfo () {
        let meta = {
            title: "Admin "+this.adminpanel
        };
        if(this.siteconfig && this.siteconfig.sitetitle) meta.titleTemplate = this.siteconfig.sitetitle;
        return meta;
    },    
    data () { 
        return {
            showvcrm : config_system.integrations.vcrm,
            menulist: navitems
        }
    },
    components: { 
        ContentBlockSimple, 
        AdminClasses, 
        AdminComments, 
        AdminConfig, 
        AdminContent, 
        AdminCourses, 
        AdminEnrollment, 
        AdminFiles, 
        AdminLessonMaterials, 
        AdminLessons, 
        AdminLocalization,
        AdminReports,
        AdminSurveys,
        AdminUsers, 
    },
    computed: {
        ...mapState(['siteconfig', 'users', 'content', 'courses', 'localize']),
        // ...mapActions(['setNav']),
        adminpanel() {
            console.log('admin', this.$route)
            return this.$route.params.panel
        }
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },          
    },
    created () {
        // this.setNav({key: "admin", 'items': [ { title: "Users", url: "/admin/users" }]});
        this.$store.dispatch('setNav',{key: "admin", 'items': navitems}, { root: true });
    },
    mounted () {
        this.$store.registerModule('adminstore', store_admin);
        this.$store.dispatch('adminstore/getSiteConfig');
        // if(!this.content || !this.content.list) this.$store.dispatch('content/getList');
        this.$store.dispatch('content/getList');
    },
    unmounted () {
        this.$store.dispatch('setNav',{key: "admin", 'items': false}, { root: true });
    },
    beforeDestroy () {
        this.$store.unregisterModule('adminstore')
    }
};

</script>
