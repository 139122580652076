<template>
    <div>
        <AdminTitleBar
            :title="loc('Manage Content')"
            :actions="titleBarActions"
            v-on:actionhandler="handleAction"
        />          
        <v-row v-if="tablePrefs">
            <div v-for="(item, idx) in headers" v-bind:key="idx">
                <v-checkbox style="min-width: 120px;"
                    :name="idx"
                    v-model="item.show"
                    :label="loc(item.text)"
                    @click.stop="saveheaders"
                ></v-checkbox>
            </div>
        </v-row>  
        <v-row v-else>
            <div class='tip pb-4'>{{ typetip }}</div>
        </v-row> 
        <div v-if="recordList">
            <v-row class="searchrow">
                <v-select :items="typeselect" v-bind:value="viewtype" :label="loc('Content Type')" @change="handleViewType" ></v-select>
                <v-select v-if="showContextSelect" :items="availableContexts" v-bind:value="viewcontext" :label="loc('Context')" @change="handleViewContext" class="ml-3" ></v-select>
                <v-select :items="languages" v-bind:value="viewlanguage" :label="loc('Language')" @change="handleViewLanguage" class="ml-3" ></v-select>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="search"
                    :label="loc('Search')"
                    single-line
                    hide-details
                ></v-text-field>
            </v-row>
            <v-data-table
                :items-per-page="itemsPerPage"
                :headers="tableHeaders"
                :footer-props="{ itemsPerPageOptions:[10,20,30,40,50,100,200] }"
                :items="rows"
                :search="search"
                class="courseList elevation-1 my-8 pb2"
            >
                <template v-slot:[`header.actions`]="{header}">
                    {{header.text}}
                    <span @click="tablePrefs=!tablePrefs" class="dtableactionicon"><v-icon small >edit</v-icon> {{ loc("Columns") }}</span>
                </template>
                <template v-slot:[`item.context`]="{ item }" class="linkcolumn contextcolumn">
                    <router-link :to="'/' + item.context">{{ item.context }}</router-link>
                </template>
                <template v-slot:[`item.actions`]="{ item }" class="actioncolumn">
                    <span class="manageusericons">
                        <v-btn small color="primary" dark @click="handleEdit(item)" class="mr-1">{{ loc("Edit") }}</v-btn>
                        <v-btn small color="error" dark @click="handleRemove(item)">{{ loc("Remove") }}</v-btn>
                    </span>
                </template>
            </v-data-table>
        </div>
        <div v-else>
            {{ loc('No data yet.') }}
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';
import AdminTitleBar from "./Admin_TitleBar";
import { filterId, filterWordsStrict } from "../lib/filter.js";

const pageblocktypes = ['block', 'courselisting'];

export default {
    name: "AdminContentList",
    props: {
        recordList: { type: Array },
        viewtype: { type: String, default: 'page' },
        viewcontext: {type: [String, Boolean] },
        viewlanguage: {type: [String, Boolean] },
        viewsearch: {type: [String, Boolean]}
    },
    components: { AdminTitleBar },
    data() {
        return {
            itemsPerPage: 50,
            tablePrefs: false,
            manageRecord: false,
            record: false,
            // viewtype: 'page',
            search: this.viewsearch || "",
            headers: {
                _id: {
                    text: "ID",
                    value: "_id",
                    show: true
                },
                title: {
                    text: "Title",
                    value: "title",
                    sortable: true,
                    align: "start",
                    show: true
                },
                shorttitle: {
                    text: "Short Title",
                    value: "shorttitle",
                    sortable: true,
                    align: "start",
                    show: false
                },
                context: {
                    text: "Context",
                    value: "context",
                    sortable: true,
                    align: "start",
                    show: true
                },
                type: {
                    text: "Type",
                    value: "type",
                    sortable: true,
                    align: "start",
                    show: false
                },
                subtype: {
                    text: "Subtype",
                    value: "subtype",
                    sortable: true,
                    align: "start",
                    show: false
                },                
                order: {
                    text: "Order",
                    value: "order",
                    sortable: true,
                    align: "start",
                    show: false
                },
                lang: {
                    text: "Language",
                    value: "lang",
                    sortable: true,
                    align: "start",
                    show: false
                },
                status: {
                    text: "Status",
                    value: "status",
                    sortable: true,
                    align: "start",
                    show: false
                },
                timestamp: {
                    text: "Created",
                    value: "created",
                    sortable: true,
                    align: "start",
                    show: false
                },
                modified: {
                    text: "Modified",
                    value: "modified",
                    sortable: true,
                    align: "start",
                    show: true
                }
            },
            typeselect: [ // 'page','snippet','message','block'
                { text: "Pages", value: "page", tip: "Pages are stand alone 'static' site pages, like 'About' that have their own URL.  They typically contain one or more Page Blocks." },
                { text: "Page Blocks", value: "block", tip: "Page Blocks are parts of a Page.  It is usually easier to manage and edit these from the page itself rather than here." },
                { text: "Stand Along Blocks", value: "snippet", tip: "Stand alone blocks are blocks of content that are typically part of the functionality of the site, such as the text on the password reset page." },
                { text: "Email Messages", value: "message", tip: "These are the templates for email messages the site sends to users based on event triggers." },
                { text: "Certificates", value: "certificate", tip: "Certificates are the templates for the PDF certificates participants can receive when the pass the exam for a course." }
            ],
            titleBarActions: [
                { text: "Refresh List", key: "refresh" },
                { text: "Add Page", key: "addPage" },
                { text: "Add Block", key: "addContent" }
            ],            
        }
    },
    computed: {
        ...mapState(['users', 'localize', 'adminstore', 'content']),
        tableHeaders: function() {
            // text: "Short Title",
            // value: "shorttitle",
            // sortable: true,
            // align: "start",
            // show: false

            let columns = [];
            for(let k in this.headers){
                if(this.headers[k].show){
                    columns.push({
                        text: filterWordsStrict(this.headers[k].text),
                        value: filterId(this.headers[k].value),
                        sortable: (this.headers[k].sortable !== false),
                        align: ((this.headers[k].align == 'end') ? 'end' : (this.headers[k].align == 'center') ? 'center' : "start"),
                        show: true
                    });
                }
            }

            columns.push({ text: "Actions", sortable: false, value: "actions" });
            return columns;            
        },
        availableContexts: function(){
            let list = {}; 
            for(let k in this.content.list){
                if(this.content.list[k].context && typeof this.content.list[k].context === "string") list[this.content.list[k].context] = {text: this.content.list[k].context, value: this.content.list[k].context};
            }
            list = _sortBy(Object.values(list), [(doc) => doc.text.toLowerCase()] );
            list.unshift({text: 'Show All', value: 'all'})
            return list;
        },
        rows: function(){
            let viewtype = this.viewtype || "page";
            // viewtype = (viewtype === 'block') ? 
            //     (o) => { return pageblocktypes.indexOf(o.type) > -1; } :
            //     { type: viewtype }
            let list =  _filter(this.recordList, { type: viewtype });
            if(viewtype !== 'page' && this.viewcontext && this.viewcontext !== 'all') list = _filter(list, {context: this.viewcontext})
            if(this.viewlanguage && this.viewlanguage !== "all"){ console.log("filtering language", this.viewlanguage); list = _filter(list, {lang: this.viewlanguage}); }

            return list;
        },
        showContextSelect: function(){
            return this.viewtype !== 'page' && this.viewtype !== 'message';
        },
        languages: function(){
            let list = (this.adminstore && this.adminstore.siteconfig && this.adminstore.siteconfig.languages) || {en:"English"};
            let out = [];
            for(let k in list){
                out.push({ value: k, text: list[k] });
            }
            out.unshift({text: 'Show All', value: 'all'})
            return out;
        }, 
        typetip: function(){
            let t = _filter(this.typeselect, {value: this.viewtype});
            return t && t.length === 1 && t[0].tip;
        }      
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },   
        handleAction: function(action, params){
            // mostly used as a passthrough for the admintitlebar
            if(action === "addContent"){
                params = params || {};
                params.type = (this.viewtype && this.viewtype !== 'page') ? this.viewtype : 'block';
            }
            this.$emit("actionhandler", action, params);
        },
        handleEdit: function(evt) {
            console.log("Content list", evt)
            this.$emit("actionhandler", 'edit', evt)
        },
        handleRemove: function(evt) {
            this.$emit("actionhandler", 'remove', evt)
        },
        handleViewType: function(evt) {
            this.$emit("actionhandler", 'setviewtype', evt);
        },
        handleViewContext: function(evt) {
            this.$emit("actionhandler", 'setviewcontext', evt);
        },
        handleViewLanguage: function(evt) {
            this.$emit("actionhandler", 'setviewlanguage', evt);
        },
        saveheaders: function(){
            localStorage.AdminContentList_headers = JSON.stringify(this.headers);
        }
    },
    mounted() {
        if(localStorage.AdminContentList_headers){
            try {
                let h = JSON.parse(localStorage.AdminContentList_headers);
                h && (this.headers = h);
            } catch(e){
                console.log("Error parsing from local storage",e);
            }
        }
    }
  
}
</script>