<template>
    <div>
        <AdminTitleBar
            :title="loc('Manage Survey')"
            :actions="titleBarActions"
            v-on:actionhandler="handleAction"
        />
        <v-card id="editcourseproperties" class="pa-2">
            <v-card-text class='courseform'>
                <v-row justify="space-between" v-if="isnew">
                    <v-text-field
                        name="ID"
                        v-model="editRecord._id"
                        :label="loc('ID')"
                        :hint="loc('If you leave the ID blank we will generate one for you.')"
                        persistent-hint                        

                    ></v-text-field>
                </v-row>           
                <v-row justify="space-between">
                    <v-text-field
                        name="title"
                        v-model="editRecord.title"
                        :label="loc('Title')"
                    ></v-text-field>
                </v-row>  
                <v-row justify="space-between">
                    <v-select
                        name="lang"
                        v-model="editRecord.lang"
                        :label="loc('Language')"
                        :items="languages"
                    ></v-select>
                </v-row>      
                <v-row justify="space-between">
                    <v-select
                        name="status"
                        v-model="editRecord.status"
                        :label="loc('Status')"
                        :items="[{text:'Active',value:'Active'},{text:'Inactive',value:'Inactive'}]"
                    ></v-select>
                </v-row>   
                <v-row justify="space-between" class="pt-4 pb-4">
                    <v-checkbox 
                        v-model="editRecord.allowAnon" 
                        :label="loc('Allow Anonymous Entry')" 
                        class='px-0'
                    ></v-checkbox>
                </v-row>                 
                <v-row justify="space-between" class="pt-4 pb-4">
                    <div style="width: 100%; padding-bottom: 12px;">
                        <label for="course-description" class="v-label theme--light" style="left: 0px; right: auto; position: relative;">{{ loc("Survey Description") }}</label>
                    </div>
                    <SharedEditor
                        name="description"
                        :record="editRecord.description"
                        :handleChange="handleDescriptionChange"
                        id="course-description"
                    ></SharedEditor>
                </v-row>  
                <v-row justify="space-between" class="pt-4 pb-4">
                    <div style="width: 100%; padding-bottom: 12px;">
                        <label for="course-description" class="v-label theme--light" style="left: 0px; right: auto; position: relative;">{{ loc("Post-Survey Message") }}</label>
                    </div>
                    <SharedEditor
                        name="description2"
                        :record="editRecord.description2"
                        :handleChange="handleDescription2Change"
                        id="course-description2"
                    ></SharedEditor>
                </v-row>  
                <v-row justify="space-between" class="pt-4 pb-4">
                    <v-col cols="6"><h2>{{ loc('Questions') }}</h2></v-col>
                    <v-col cols="6"><v-btn @click="addquestion">{{ loc('Add Question') }}</v-btn></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">{{loc("Enter one question in each text block.")}}</v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-card v-for="(question, qkey, idx) in editRecord.questions" v-bind:key="qkey" class="mt-4 mb-4">
                            <v-card-text>
                                <v-row>
                                    <v-col cols="6" md="3" class="text-h5">{{loc('Survey Question')}} {{ idx + 1 }}</v-col>
                                    <v-spacer></v-spacer> 
                                    <v-col class="text-right"><v-btn color="error" size="small" @click="removequestion(qkey)">Remove</v-btn></v-col>
                                </v-row>
                                <v-row>
                                    <v-col><v-select :label="loc('Type')" v-model="editRecord.questions[qkey].type" :items="questionTypes"></v-select></v-col>
                                </v-row>
                                <v-row v-if="editRecord.questions[qkey].type === 'choice' || editRecord.questions[qkey].type === 'select' "><v-col>{{ loc('For multiple choice or drop down questions, put the question and each answer on its own line, separated by a line feed.') }}</v-col></v-row>
                                <v-row>
                                    <v-col><v-textarea v-model="editRecord.questions[qkey].question"></v-textarea></v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>   
                <v-divider class="mx-4"></v-divider>                         
                <v-row class='actionrow mt-2'>
                    <v-btn small color="primary" dark @click="handleSave()" class="mr-1">{{ loc("Save Changes") }}</v-btn>
                    <v-btn small color="primary" dark @click="handleSave('close')" class="mr-1">{{ loc("Save and Go to List") }}</v-btn>
                    <v-btn small color="secondary" dark @click="handleCancel('close')" class="mr-1">{{ loc("Cancel") }}</v-btn>
                </v-row>
            </v-card-text>
        </v-card>                                                                               
        <div style="display:none;"><pre>{{ editRecord }}</pre></div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import SharedEditor from './Shared_Editor';
// import CodeEditor from './Shared_Editor_Code';
import AdminTitleBar from "./Admin_TitleBar";
import _filter from 'lodash/filter';
import {makeDateId} from '../lib/filter.js';

export default {
    name: "AdminSurveyEdit",
    props: {
        // list: { type: Array },
        editId: { type: String },
        record: { type: Object }
    },
    components: { SharedEditor, AdminTitleBar },
    data() {
        return {
            editRecord: this.record || {},
            isnew: this.record && this.record._id === 'new',
            titleBarActions: [
                {
                    text: "Close",
                    key: "close"
                }
            ]              
        }
    },
    computed: {
        ...mapState(['users', 'localize', 'adminstore', 'surveys']),
        languages: function(){
            let list = (this.adminstore && this.adminstore.siteconfig && this.adminstore.siteconfig.languages) || {en:"English"};
            let out = [];
            for(let k in list){
                out.push({ value: k, text: list[k] });
            }
            return out;
        },  
        questionTypes: function(){
            return [
                {text: this.loc('Text Field (single line)'), value: 'textfield' },
                {text: this.loc('Text Answer (paragraph)'), value: 'text' },
                {text: this.loc('Multiple Choice'), value: 'choice' },
                {text: this.loc('Drop Down'), value: 'select' },
                {text: this.loc('Numeric Answer'), value: 'numeric' }
            ];
        }      
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },   
        handleDescriptionChange: function(val){
            console.log(val);
            this.editRecord.description = val;
        },
        handleDescription2Change: function(val){
            console.log(val);
            this.editRecord.description2 = val;
        },        
        handleDescriptionClose: function(val){

        },
        handleSave: function(val){
            if(val === "survey") val = "/surveys/"+this.editRecord._id;
            this.$emit("actionhandler", "save", this.editRecord, val);
        },
        handleCancel: function(val){
            this.$emit("actionhandler", 'close', val)
        },
        handleAction: function(action, params){
            this.$emit("actionhandler", action, params || (action !== 'close' && this.editRecord));
        },
        handleStyle: function(val, a, b){
            // console.log('style field updated', a, b, val);
            this.editRecord.style = val;
        },
        addquestion: function(){
            let k = makeDateId()
            if(!this.editRecord.questions) this.$set(this.editRecord, 'questions', {});
            this.$set(this.editRecord.questions, k,  {type:'text', question: ''});
            console.log('Adding', k, this.editRecord.questions)
        },
        removequestion: function(key){
            if(this.editRecord && this.editRecord.questions && this.editRecord.questions[key]){
                this.$delete(this.editRecord.questions, key);
            }
        }
    }
  
}

</script>