<template>
    <div>
        <AdminTitleBar
            :title=" loc('Edit File')"
            :actions="titleBarActions"
            v-on:actionhandler="handleAction"
        />
        <v-row v-if="record" class="mt-8 mb-8">
            <CodeEditor :data="record.body" :handleChange="handleChange" :mode="record.mime" />
        </v-row>
        <v-row v-else>
            Loading...
        </v-row>
        <v-row class='actionrow mb-8'>
            <v-btn small color="primary" dark @click="handleSave()">{{ loc('Save File') }}</v-btn>
            <v-btn small color="primary" dark @click="handleSave('close')">{{ loc('Save File and Close') }}</v-btn>
            <v-btn small color="secondary" dark @click="handleClose()">{{ loc('Close') }}</v-btn>
        </v-row>
        <v-row class="mt-12">
            <pre>{{record}}</pre>
        </v-row>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import AdminTitleBar from "./Admin_TitleBar";
import CodeEditor from "./Shared_Editor_Code";


export default {
    name: "AdminFilesEdit",
    props: {
        record: { type: [Object, Boolean] }
    },
    components: { AdminTitleBar, CodeEditor },
    data() {
        return {
            titleBarActions: [
                { text: "Close", key: "close" }
            ],
            content: this.record
        }
    },
    computed: {
        ...mapState(['users', 'localize', 'files'])
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },  
        handleClose: function () {
            this.$emit('actionhandler', 'close')
        },
        handleChange: function (change, arg1, arg2){
            this.content = this.content || this.record;
            this.content.body = change;
        },
        handleSave: function (opt) {
            if(!this.content && this.record) this.content = this.record;
            this.$emit('actionhandler', 'save', this.content)
            if(opt === "close") this.$emit('actionhandler', 'close');
        },
        handleAction: function(action, params) {
            this.$emit("actionhandler", action, params)
        },   
    }
  
}
</script>