<template>
    <v-container>
        <div v-if="authError">
            {{ loc('Please Login') }}
        </div>
        <div v-else-if="!users.currentUser || !users.currentUser.isAdmin" >
            {{ loc("You do not have permission to do that.") }}
        </div>    
        <div v-else-if="isLoading">
            {{ loc('Loading...') }}
        </div>  
        <div v-else>
            <AdminTitleBar
                v-if="titleBarActions"
                :title="loc('Language Configuration')"
                :actions="titleBarActions"
                v-on:actionhandler="handleAction"
            />
            <div v-if="panel === 'adminLocalizationDescription'">
                <ContentBlockSimple :record="'adminLocalizationDescription'"  />
            </div>
            <div v-else-if="panel === 'json'">
                <pre>{{localize.languages}}</pre>
                 <!-- <pre>{{recordList}}</pre> -->
            </div>
            <div v-else-if="recordList">
                <ContentBlockSimple :record="'adminLocalizationIntro'"  class="mb-10" />
                <v-row class="searchrow">
                    <v-select :items="languageSelect" v-bind:value="lang1" @change="handleLang1Change" ></v-select>
                    <v-select :items="languageSelect" v-bind:value="lang2" @change="handleLang2Change" class='ml-4'></v-select>
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        append-icon="search"
                        :label="loc('Search')"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-row>
                <v-data-table 
                    :items-per-page="itemsPerPage"
                    :headers="tableHeaders"
                    :footer-props="{ itemsPerPageOptions:[10,20,30,40,50,100,200] }"
                    :items="recordList"
                    :search="search"
                    class="courseList elevation-1 my-8 pb2"
                >
                    <template v-slot:[`item.lang2`]="{ item }" >
                        <v-text-field v-if="item.idx === editingIdx" name="lang2" v-model="editingValue" ></v-text-field>
                        <div v-else>{{item.lang2}} </div>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }" class="actioncolumn">
                        <span class="manageusericons" v-if="item.idx === editingIdx">
                            <v-btn  small color="primary" dark @click="handleSave(item)" >{{ loc("Save") }}</v-btn> 
                            <v-btn v-if="item.idx === editingIdx" small color="error" dark @click="handleCancel(item)" class="ml-4">{{ loc("Cancel") }}</v-btn>
                        </span>
                        <span class="manageusericons" v-else>
                            <v-btn small dark @click="handleEdit(item)" >{{ loc("Edit") }}</v-btn>
                        </span>
                    </template>                    
                </v-data-table>
            </div>
        </div>
    </v-container>
</template>

<script>
import { mapState } from "vuex";
import AdminTitleBar from "./Admin_TitleBar";
import { filterId } from '../lib/filter';
import ContentBlockSimple from './Content_Block_Simple';
import _union from 'lodash/union';
import _size from 'lodash/size';
import _sortBy from 'lodash/sortBy';


export default {
    name: "AdminLocalization",
    components: {AdminTitleBar, ContentBlockSimple},
    data() {
        return {
            isLoading: false,
            panel: false,
            itemsPerPage: 30,
            search: "",
            lang1: "_sys",
            lang2: "en",
            editingValue: false,
            editingIdx: false,
            editingSys: false,
            headers: {
                lang1: {
                    text: "Language 1",
                    value: "lang1",
                    show: true
                },
                lang2: {
                    text: "Language 2",
                    value: "lang2",
                    show: true
                },
                actions: {
                    text: "Action",
                    value: "actions",
                    show: true
                }

            },
            titleBarActions:[
                { text: "Refresh", key: "refresh" },
                { text: this.panel === 'json' ? "Close" : "JSON", key: "toggleJSON"}
            ]
        }
    },
    computed: {
        ...mapState(["authError", "users", "localize", "siteconfig"]),
        tableHeaders: function() {
            var temp = Object.assign({}, this.headers);
            let columns = [];
            if(this.siteconfig && this.siteconfig.languages && this.siteconfig.languages[this.lang1]) temp.lang1.text = this.siteconfig.languages[this.lang1];
            if(this.siteconfig && this.siteconfig.languages && this.siteconfig.languages[this.lang2]) temp.lang2.text = this.siteconfig.languages[this.lang2];
            if(this.lang1 === "_sys") temp.lang1.text = "Defaults";
            if(this.lang2 === "_sys") temp.lang2.text = "Defaults";
            for(let k in temp){
                if(temp[k].show){
                    temp[k].sortable = (temp[k].sortable || temp[k].sortable === undefined) ? true : false;
                    temp[k].align = temp[k].align || "start";
                    columns.push(temp[k]);
                }
            }

            // columns.push({ text: "Actions", sortable: false, value: "actions" });
            return columns;   
        },
        recordList: function() {
            let list = [];
            if(!this.localize || !this.localize.languages) return list;
            let lang1 = this.lang1 || 'en';
            let lang2 = this.lang2 || '_sys';
            let fields;
            if(this.localize.languages[lang1] && this.localize.languages[lang2] && _size(this.localize.languages[lang1]) && _size(this.localize.languages[lang2])){
                // we have a list of strings for both languages
                console.log("Loc recordList both ", lang1, this.localize.languages[lang1], lang2, this.localize.languages[lang2])
                fields = _union(Object.keys(this.localize.languages[lang1]), Object.keys(this.localize.languages[lang2]));
            } else if(this.localize.languages[lang1] && _size(this.localize.languages[lang1])){
                // we only have a list for language 1
                console.log("Loc recordList 1", lang1, this.localize.languages[lang1], lang2, this.localize.languages[lang2])
                fields = Object.keys(this.localize.languages[lang1]);
            } else if(this.localize.languages[lang2] && _size(this.localize.languages[lang2])){
                // we only have a list for language 2 (this is strange)
                console.log("Loc recordList 2", lang1, this.localize.languages[lang1], lang2, this.localize.languages[lang2])
                fields = Object.keys(this.localize.languages[lang2]);
            } else {
                // falling back, empty list for both
                console.log("Loc recordList Falling back", lang1, this.localize.languages[lang1], lang2, this.localize.languages[lang2])
                return list;
            }
            console.log("recordList fields:", fields)
            for(let i in fields ){
                let k = fields[i];
                if(k === "timestamp") continue;
                let row = {
                    idx: i,
                    _sys: k,
                    lang1: lang1 === "_sys" ? k : ((this.localize.languages[lang1] && this.localize.languages[lang1][k]) || "" ),
                    lang2: lang2 === "_sys" ? k : ((lang2 && this.localize.languages[lang2] && this.localize.languages[lang2][k]) || "" ),
                    actions: true
                }
                list.push(row);
            }
            list = _sortBy(list, ['lang1', 'lang2'])
            return list;
        },
        languageSelect: function() {
            let list = (this.siteconfig && this.siteconfig.languages); // || {en:"English"};
            let out = [];
            for(let k in list){
                out.push({ value: k, text: list[k] });
            }
            // out.unshift({text: 'Show All', value: 'all'})
            out.unshift({text: 'Defaults', value: '_sys'})
            return out;        
        }
    },
    methods: {
        getLanguage: function (lang) {
            this.isLoading = true;
            let data = {lang: lang}
            this.$store.dispatch('localize/getLanguage', data)
                .then(() => {
                    this.isLoading = false;
                })
                .catch((e) =>{
                    console.log("ERROR in admin localization getLanguage",e && e.message);
                })
        },
        handleLang1Change: function (lang) {
            console.log('handleLang1Change', lang)
            this.lang1 = lang;
            this.$router.push('/admin/localization/' + this.lang1 + '/' + this.lang2);
        },
        handleLang2Change: function (lang) {
            console.log('handleLang2Change', lang)
            this.lang2 = lang;
            this.$router.push('/admin/localization/' + this.lang1 + '/' + this.lang2);
        },    
        handleEdit: function (item){
            console.log("Localization.handleEdit", item);
            this.editingValue = item.lang2;
            this.editingIdx = item.idx;
            this.editingSys = item._sys;
        },    
        handleSave: function (item){
            console.log("Localization.handleSave", item, this.editingSys, this.lang2, this.editingValue);
            // editingValue: false,
            // editingIdx: false,
            // editingSys: false,
            let record = {
                lang: this.lang2,
                _sys: this.editingSys,
                value: this.editingValue,
            }
            console.log("Localization.handleSave record", record);
            // this.isLoading = true;
            this.$store.dispatch('localize/save', record)
                .then((result) => {
                    this.editingValue = false;
                    this.editingIdx = false;
                    this.editingSys = false;
                    this.isLoading = false;
                })

        },    
        handleCancel: function (){
            this.editingValue = false;
            this.editingIdx = false;
            this.editingSys = false;
        },
        handleAction: function(action, params, opts) {
            switch(action){
                case 'toggleJSON':
                    this.panel = (this.panel === 'json') ? 'list' : 'json';
                    break;      
                case 'refresh':
                    this.isLoading = true;
                    this.getLanguage(this.lang1);          
                    this.getLanguage(this.lang2);
                    break;          
                default:
                    console.log("admin localization handleAction", action, params, opts);
            }
        },
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },  
        init: function (path) {
            console.log("entering Admin_Localization.init");
            let list = (this.adminstore && this.adminstore.siteconfig && this.adminstore.siteconfig.languages) || {en:"English"};
            let lang1, lang2;
            if(path && typeof path === 'string'){
                let p = path.split('/');
                lang1 = (p[0] && filterId(p[0])) || '_sys';
                lang2 = (p[1] && filterId(p[1])) || (this.siteconfig && this.siteconfig.defaults && this.siteconfig.defaults.lang) || 'en';
                // (p[1]) ? filterId(p[1]) : "en";
                console.log('Admin_Localization.init', path, lang1, lang2, p[2]);
            }
            this.isLoading = true;
            this.getLanguage('_sys');
            if(lang1){ 
                this.lang1 = lang1;
            }
            if(lang2){ 
                this.lang2 = lang2;
                // if(list[lang2]){
                //     this.$set(this.headers.lang2, 'text', list[lang2]);
                //     // this.headers.lang2.text = list[lang2];
                // } else {
                //     this.$set(this.headers.lang2, 'text', lang2 === "_sys" ? "Defaults" : "Language 2");
                // }                
            }
            if(lang1 !== '_sys') this.getLanguage(lang1);
            if(lang2 && lang2 !== '_sys') this.getLanguage(lang2);
        }
    },
    created (){
        this.init(this.$route.params.pathMatch);
    },
    watch: {
        '$route.params.id': {
            handler: function(s){
                console.log("lessons watcher triggered", s, this.$route);
                let context = s || (this.$route.params && this.$route.params.pathMatch);
                if(this.context !== context) this.init(context);
                // this.context = s || (this.$route.params && this.$route.params.pathMatch);
            },
            deep: true,
            immediate: false
        }
    }
}
</script>
