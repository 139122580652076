<template>
    <div>
        <AdminTitleBar
            :title="loc('Manage Classes') + ' - ' + course.title + ' - ' + classRecord.name"
            :actions="titleBarActions"
            v-on:actionhandler="handleAction"
        />  
        <h4 v-text="record._id" ></h4>
        <v-card id="editclassproperties" class="pa-2">
            <v-card-text class='adminclassform'>
                <v-row justify="space-between">
                    <v-text-field
                        name="name"
                        v-model="record.name"
                        :label="loc('Class Name')"
                    ></v-text-field>
                </v-row>  
                <!-- <v-row justify="space-between">
                    <v-text-field
                        name="course"
                        v-model="record.course"
                        :label="loc('Course')"
                    ></v-text-field>
                </v-row>                        -->
                <v-row justify="space-between">
                    <v-text-field
                        name="regcode"
                        v-model="record.regcode"
                        :label="loc('Reg Code')"
                    ></v-text-field>
                </v-row>                   
                <v-row justify="space-between">
                    <v-select
                        name="lang"
                        v-model="record.lang"
                        :label="loc('Language')"
                        :items="languages"
                    ></v-select>
                </v-row>   
                <v-row justify="space-between">
                    <v-select
                        name="instructor"
                        v-model="record.instructor"
                        :label="loc('Instructor')"
                        :items="instructors"
                    ></v-select>
                </v-row>                  
                <!-- <v-row justify="space-between">
                    <v-text-field
                        name="logo"
                        v-model="record.logo"
                        :label="loc('Logo')"
                    ></v-text-field>
                </v-row>   -->
                <v-row justify="space-between">
                    <v-select
                        name="status"
                        v-model="record.status"
                        :label="loc('Status')"
                        :items="statusselect"
                    ></v-select>
                </v-row>    
                <v-row justify="space-between">
                    <v-select
                        name="message1"
                        v-model="record.message1"
                        :label="loc('Welcome Message Template')"
                        :items="messagetemplateselect"
                    ></v-select>
                </v-row>                                
                <v-row justify="space-between">
                    <v-checkbox 
                        name="selfpaced"
                        :label="loc('Self Paced')"
                        v-model="record.selfpaced"
                    ></v-checkbox>

                </v-row>
                <v-row justify="space-between" class="pt-4 pb-4">
                    <div style="width: 100%; padding-bottom: 12px;">
                        <label for="class-description" class="v-label theme--light" style="left: 0px; right: auto; position: relative;">{{ loc("Class Description") }}</label>
                    </div>
                    <SharedEditor
                        name="description"
                        :record="record.description"
                        :handleChange="handleDescriptionChange"
                        id="class-description"
                    ></SharedEditor>
                </v-row>  
                <v-row justify="space-between" class="pt-4 pb-4">
                    <div style="width: 100%; padding-bottom: 12px;">
                        <label for="class-description2" class="v-label theme--light" style="left: 0px; right: auto; position: relative;">{{ loc("Class Sidebar") }}</label>
                    </div>
                    <SharedEditor
                        name="description2"
                        :record="record.description2"
                        :handleChange="handleDescriptionChange"
                        id="class-description2"
                        field="description2"
                    ></SharedEditor>
                </v-row>                 
                <v-divider class="mx-4"></v-divider>                         
                <v-row class='actionrow mt-2'>
                    <v-btn small color="primary" dark @click="handleSave()" class="mr-1">{{ loc("Save Changes") }}</v-btn>
                    <v-btn small color="primary" dark @click="handleSave('close')" class="mr-1">{{ loc("Save and Go to List") }}</v-btn>
                    <v-btn small color="primary" dark @click="handleSave('class')" class="mr-1">{{ loc("Save and Go to Class") }}</v-btn>
                    <v-btn small color="primary" dark @click="handleSave('course')" class="mr-1">{{ loc("Save and Go to Course") }}</v-btn>
                    <v-btn small color="secondary" dark @click="handleCancel('close')" class="mr-1">{{ loc("Cancel") }}</v-btn>
                </v-row>
            </v-card-text>
        </v-card>   
        <v-row style="display:none"><pre>{{record}}</pre></v-row>     
    </div>
       
</template>

<script>

/*
    trackquizzes: { f: filter.noop },
    trackexam: { f: filter.noop },
    instructortrack: { f: filter.noop }
*/
import { mapState } from 'vuex';
import SharedEditor from './Shared_Editor';
// import CodeEditor from './Shared_Editor_Code';
import AdminTitleBar from "./Admin_TitleBar";
import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';

export default {
    name: "AdminClassesEdit",
    props: {
        editId: { type: String },
        classRecord: { type: Object },
        course: { type: Object }
    },
    components: { SharedEditor, AdminTitleBar },
    data() {
        return {
            record: this.classRecord,
            isnew: this.contentRecord && this.contentRecord._id === 'new',
            titleBarActions: [
                {
                    text: "Close",
                    key: "close"
                }
            ],
            statusselect: ['Active', 'Archive']
        }
    },
    computed: {
        ...mapState(['users', 'localize', 'adminstore', 'courses', 'content']),
        languages: function(){
            let list = (this.adminstore && this.adminstore.siteconfig && this.adminstore.siteconfig.languages) || {en:"English"};
            let out = [];
            for(let k in list){
                out.push({ value: k, text: list[k] });
            }
            return out;
        },
        instructors: function() {
            let course = this.record && this.record.course;
            console.log('preparing instructor list', course, this.users.instructors, this.users.instructors[course])

            if(course && this.users.instructors && this.users.instructors[course]) {
                let out = [];
                for(let k in this.users.instructors[course]){
                    out.push({value: k, text: this.users.instructors[course][k].firstname + " " + this.users.instructors[course][k].lastname });
                }
                out = _sortBy(out, ['text'])
                return out;
            } else {
                return [];
            }
        },
        messagetemplateselect: function() {
            let contents = (this.content && this.content.list) || [];
            console.log('messagememplate',contents);
            contents = _filter(contents, (o) => { return o.type === 'message' && o._id.slice(0,13) === 'message-class'})
            console.log('messagememplate filtered',contents);
            let list = [];
            for(let i in contents){ list.push(contents[i]._id)}
            console.log('messagememplate out', list);
            return list;
        } 
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },  
        handleDescriptionChange: function(val, field){
            // console.log(val);
            field = field || 'description';
            this.record[field] = val;
        },        
        handleSave: function(val){
            if(val === "course") val = "/Course/"+this.record.course;
            this.$emit("actionhandler", "save", this.record, val);
        },
        handleCancel: function(val){
            this.$emit("actionhandler", 'close', val)
        },
        handleAction: function(action, params){
            this.$emit("actionhandler", action, params);
        },    
    }
    
}
</script>
