<template>
    <div>
        <AdminTitleBar
            :title="loc('Manage Classes') + ' - ' + (course && course.title || 'all') "
            :actions="titleBarActions"
            v-on:actionhandler="handleAction"
        />  
        <v-row v-if="tablePrefs">
            <div v-for="(item, idx) in headers" v-bind:key="idx">
                <v-checkbox style="min-width: 120px;"
                    :name="idx"
                    v-model="item.show"
                    :label="loc(item.text)"
                    @click.stop="saveheaders"
                ></v-checkbox>
            </div>
        </v-row>  
        <div v-if="recordList">
            <v-row class="searchrow">
                <v-select :items="courseSelect" v-bind:value="course && course._id || 'all'" label="Course" @change="handleCourseChange" ></v-select>
                <v-select :items="[ 'Active', 'Archived', 'All' ]" v-model="statusfilter" label="Status" class="ml-3" ></v-select>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="search"
                    :label="loc('Search')"
                    single-line
                    hide-details
                ></v-text-field>
            </v-row>
            <v-data-table
                :items-per-page="itemsPerPage"
                :headers="tableHeaders"
                :footer-props="{ itemsPerPageOptions:[10,20,30,40,50,100,200] }"
                :items="classList"
                :search="search"
                class="courseList elevation-1 my-8 pb2"
            >
                <template v-slot:[`header.actions`]="{header}">
                    {{header.text}}
                    <span @click="tablePrefs=!tablePrefs" class="dtableactionicon" ><v-icon small >edit</v-icon> {{ loc("Columns") }}</span>
                </template>
                <template v-slot:[`item.course`]="{ item }">
                    {{ course && (course._id === item.course) && course.title }}
                </template>
                <template v-slot:[`item.enrollment`]="{ item }" class="linkcolumn enrollmentcolumn">
                    <router-link :to="'/admin/enrollment/' + item._id">Enrollment</router-link>
                </template>                 
                <template v-slot:[`item.actions`]="{ item }" class="actioncolumn">
                    <span class="manageusericons">
                        <v-btn small color="primary" dark @click="handleEdit(item)" class="mr-1">{{ loc("Edit") }}</v-btn>
                        <v-btn small color="error" dark @click="handleRemove(item)">{{ loc("Remove") }}</v-btn>
                    </span>
                </template>
            </v-data-table>
        </div>
        <div v-else>
            {{ loc('No data yet.') }}
        </div>                
    </div>
       
</template>

<script>
import { mapState } from 'vuex';
import AdminTitleBar from "./Admin_TitleBar";
import { filterId } from '../lib/filter';
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';


export default {
    name: "AdminClassesList",
    props: {
        course: { type: Object, default: () => ({}) },
        recordList: { type: Array }
    },
    components: { AdminTitleBar },
    data () {
        return {
            itemsPerPage: 30,
            tablePrefs: false,
            manageRecord: false,
            search: "",
            statusfilter: 'Active',
            headers: {
                _id: {
                    text: "ID",
                    value: "_id"
                },
                name: {
                    text: "Name",
                    value: "name",
                    sortable: true,
                    align: "start",
                    show: true
                },   
                course: {
                    text: "Course",
                    value: "course",
                    sortable: true,
                    align: "start",
                    show: false
                },     
                instructorname: {
                    text: "Instructor",
                    value: "instructorname",
                    sortable: true,
                    align: "start",
                    show: true
                },                   
                lang: {
                    text: "Language",
                    value: "lang",
                    sortable: true,
                    align: "start",
                    show: true
                },
                regcode: {
                    text: "RegCode",
                    value: "regcode", 
                    sortable: true,
                    align: "start",
                    show: false
                },
                startdate: {
                    text: "Start Date",
                    value: "startdate",
                    sortable: true,
                    align: "start",
                    show: true
                },
                selfpaced: {
                    text: "Selfpaced",
                    value: "selfpaced",
                    sortable: true,
                    align: "start",
                    show: true
                },
                status: {
                    text: "Status",
                    value: "status",
                    sortable: true,
                    align: "start",
                    show: false
                },
                modified: {
                    text: "Modified",
                    value: "modified",
                    sortable: true,
                    align: "start",
                    show: false
                },
                enrollment: {
                    text: 'Enrollment',
                    value: "enrollment",
                    sortable: false,
                    align: "start",
                    show: true
                }
            },
            titleBarActions: [
                { text: "Export Classes", key: "exportclasses", href: "/app_reports/classes/classes.csv"},
                { text: "Refresh List", key: "refresh" },
                { text: "Add Class", key: "add" }
            ],

        }      
    },
    computed: {
        ...mapState(['users', 'localize', 'courses', 'adminstore']),
        tableHeaders: function() {
            var temp = Object.assign({}, this.headers);
            let columns = []
            for(let k in temp){
                if(temp[k].show){
                    temp[k].sortable = true;
                    temp[k].align = temp[k].align || "start";
                    columns.push(temp[k]);
                }
            }

            columns.push({ text: "Actions", sortable: false, value: "actions" });
            return columns;   
        },
        classList: function() { 
            let list; //  = Object.values(this.recordList) ;
            if(this.statusfilter && this.statusfilter !== 'All') {
                if(this.statusfilter === "Active"){
                    list = _filter(this.recordList, (o) => { return !o.status || o.status === "Active"; } );
                } else {
                    list = _filter(this.recordList, {status: this.statusfilter});
                }
            } else {
                list = this.recordList;
            }
            // for(let i in list){
            //     list[i].instructorname = (this.users.list && this.users.list[list[i].instructor] && this.users.list[list[i].instructor].firstname + " " + this.users.list[list[i].instructor].lastname) ||  list[i].instructor;
            // }
            return list;
        },
        courseSelect: function() {
            let list = [];
            for(let k in this.courses.list){
                list.push({value: this.courses.list[k]._id, text: this.courses.list[k].reftitle + " - " + this.courses.list[k].title});
            }
            list.unshift({value:'all', text:'All Courses'})
            return list;
        }
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },
        handleEdit: function(evt) {
            this.$emit("actionhandler", 'edit', evt)
        },
        handleRemove: function(evt) {
            this.$emit("actionhandler", 'remove', evt)
        },
        handleAction: function(action, params){
            this.$emit("actionhandler", action, params);
        },     
        handleCourseChange: function(newcourseid, params){
            console.log(newcourseid, params);
            newcourseid =  filterId(newcourseid);
            if(newcourseid && newcourseid !== this.course._id){
                this.$router.push("/admin/classes/" + newcourseid);
            }
        },
        saveheaders: function(){
            localStorage.AdminClassList_headers = JSON.stringify(this.headers);
        }
    },
    mounted() {
        if(localStorage.AdminClassList_headers){
            try {
                let h = JSON.parse(localStorage.AdminClassList_headers);
                h && (this.headers = h);
            } catch(e){
                console.log("Error parsing from local storage",e);
            }
        }
    }
    
}
</script>