<template>
    <div>
        <AdminTitleBar
            :title="loc('Manage Surveys')"
            :actions="titleBarActions"
            v-on:actionhandler="handleAction"
        />        
        <v-row v-if="tablePrefs">
            <div v-for="(item, idx) in headers" v-bind:key="idx">
                <v-checkbox style="min-width: 120px;"
                    :name="idx"
                    v-model="item.show"
                    :label="loc(item.text)"
                    @click.stop="saveheaders"
                ></v-checkbox>
            </div>
        </v-row>   
        <div v-if="recordList">
            <v-row class="searchrow">
                <v-select :items="languages" v-bind:value="viewlanguage" :label=" loc('Language') " @change="handleAction('setviewlanguage', $event)" class="ml-3" ></v-select>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="search"
                    :label="loc('Search')"
                    single-line
                    hide-details
                ></v-text-field>
            </v-row>

            <v-data-table
                :items-per-page="itemsPerPage"
                :headers="tableHeaders"
                :footer-props="{ itemsPerPageOptions:[10,20,30,40,50,100,200] }"
                :items="recordList"
                :search="search"
                class="courseList elevation-1  my-8 pb2"
            >
                <template v-slot:[`header.actions`]="{header}">
                    {{header.text}}
                    <span @click="tablePrefs=!tablePrefs" class="dtableactionicon"><v-icon small >edit</v-icon> {{ loc("Columns") }}</span>
                </template>
                <template v-slot:[`item.title`]="{ item }" class="linkcolumn titlecolumn">
                    {{ item.title }}
                </template>      
                <template v-slot:[`item.actions`]="{ item }" class="actioncolumn">
                    <span class="manageusericons">
                        <v-btn small color="secondary" :to="'/admin/surveys/responses/' + item._id" dark class="mr-1">{{ loc("Responses") }}</v-btn>
                        <v-btn small color="primary" dark @click="handleEdit(item)" class="mr-1">{{ loc("Edit") }}</v-btn>
                        <v-btn small color="error" dark @click="handleRemove(item)">{{ loc("Remove") }}</v-btn>
                    </span>
                </template>
            </v-data-table>            
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import AdminTitleBar from "./Admin_TitleBar";
import _filter from 'lodash/filter';

export default {
    name: "AdminSurveysList",
    props: {
        viewmain: {type: [String, Boolean]}, 
        viewlanguage: { type: [String, Boolean]}
    },
    components: { AdminTitleBar },
    data() {
        return {
            itemsPerPage: 50,
            tablePrefs: false,
            manageRecord: false,
            search: "",
            headers: {
                _id: {
                    text: "ID",
                    value: "_id",
                },
                title: {
                    text: "Title",
                    value: "title",
                    sortable: true,
                    align: "start",
                    show: true                    
                },
                user: {
                    text: "User",
                    value: "user",

                },
                created: {
                    text: "Created",
                    value: "created",
                    sortable: true,
                    align: "start",
                    show: true                    
                },
                modified: {
                    text: "Modified",
                    value: "modified"
                },
                allowAnon: {
                    text: "Allow Anonymous",
                    value: "allowAnon"
                },
                lang: {
                    text: "Language",
                    value: "lang",
                    sortable: true,
                    align: "start",
                    show: true                    
                },
                status: {
                    text: "Status",
                    value: "status"
                }
            },
            titleBarActions: [
                { text: "Export Surveys", key: "exportSurveys", href: "/app_reports/surveys/surveys.csv"},
                { text: "Export Responses", key: "exportResponses", href: "/app_reports/surveyanswers/surveyresponses.csv"},
                // { text: "Refresh List", key: "refresh" },
                { text: "Add Survey", key: "add" }                
            ],

        }
    },
    computed: {
        ...mapState(['users', 'localize', 'adminstore', 'surveys','authError']),
        tableHeaders: function() {
            var temp = Object.assign({}, this.headers);
            let columns = []
            for(let k in temp){
                if(temp[k].show){
                    temp[k].sortable = true;
                    temp[k].align = temp[k].align || "start";
                    columns.push(temp[k]);
                }
            }

            columns.push({ text: "Actions", sortable: false, value: "actions" });
            return columns;            
        },
        recordList: function() {
            if(!this.surveys.list) return [];
            let list = {...this.surveys.list};
            // if(this.viewmain) list = _filter(list, {main: this.viewmain})
            // if(this.viewlanguage) list = _filter(list, {lang: this.viewlanguage})
            console.log("List of surveys", list)
            return Object.values(list);            
        },
        courseSelect: function(){ return [] },
        languages: function(){
            let list = (this.adminstore && this.adminstore.siteconfig && this.adminstore.siteconfig.languages) || {en:"English"};
            let out = [];
            for(let k in list){
                out.push({ value: k, text: list[k] });
            }
            out.unshift({text: 'Show All', value: false})
            return out;
        },  
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },     
        handleEdit: function(evt) {
            this.$emit("actionhandler", 'edit', evt)
        },
        handleRemove: function(evt) {
            this.$emit("actionhandler", 'remove', evt)
        },

        handleAction: function(action, params){
            this.$emit("actionhandler", action, params);
        },
        saveheaders: function(){
            localStorage.AdminSurveysList_headers = JSON.stringify(this.headers);
        }  

    }
}
</script>
