<template>
    <v-container>
        <div v-if="authError">
            {{ loc('Please Login') }}
        </div>
        <div v-else-if="!users.currentUser || !users.currentUser.isAdmin" >
            {{ loc("You do not have permission to do that.") }}
        </div>
        <div v-else>
            <div v-if="isLoading">
                {{ loc('Loading...') }}
            </div>
            <div v-else-if="!context">
                <h1>{{loc('Manage Lessons')}}</h1>
                <h2>{{loc('Please Select a Course')}}</h2>
                <ul class="CourseLinkList" id="LessonAdminCourseSelection">
                    <li v-for="(item, key) in courses.list" v-bind:key="key" :id="'Courselink-'+key">
                        <router-link :to="'/admin/lessons/'+item._id">{{item.reftitle}} - {{item.title}}</router-link>
                    </li>
                </ul>
            </div>
            <div v-else-if="manageRecord === 'lessonRecord' && courses.list && courses.list[context]">
                <AdminLessonsEdit :lessonRecord="lessonRecord" :course="courses.list[context]" v-on:actionhandler="handleAction" />
            </div>
            <div v-else-if="manageRecord === 'removeRecord' && courses.list && courses.list[context]">
                <AdminLessonsRemove :record="lessonRecord" :course="courses.list[context]" v-on:actionhandler="handleAction" />
            </div>    
            <div v-else-if="manageRecord === 'materials'">
                <AdminLessonMaterialsList :course="courses.list[context]" :lessonList="lessonList" :lessonRecord="lessonRecord" v-on:actionhandler="handleAction" />
            </div>
            <div v-else-if="courses.list && context === 'All'">
                <AdminLessonsList :recordList="lessonList" :course="{_id:'All'}" v-on:actionhandler="handleAction" />
            </div>
            <div v-else-if="courses.list && courses.list[context]">
                <AdminLessonsList :recordList="lessonList" :course="courses.list[context]" v-on:actionhandler="handleAction" />
            </div>
        </div>
        <!-- <v-row class='mt-4'><pre>{{manageRecord}}</pre></v-row>
        <v-row class='mt-4'><pre>{{lessonRecord}}</pre></v-row> -->
    </v-container>
</template>

<script>
import { mapState } from "vuex";
import AdminLessonsList from "./Admin_Lessons_List";
import AdminLessonsEdit from "./Admin_Lessons_Edit";
import AdminLessonsRemove from "./Admin_Lessons_Remove";
import AdminLessonMaterialsList from "./Admin_Lesson_Materials_List";
import { filterId } from '../lib/filter';
import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';

export default {
    name: "AdminLessons",
    components: { AdminLessonsList, AdminLessonsEdit, AdminLessonsRemove, AdminLessonMaterialsList},
    data() {
        return {
            context: false,
            lessonId: false,
            isLoading: true,
            after: false, 
            lessonRecord: false,
            manageRecord: false
        }
    },
    computed: {
        ...mapState(["authError", "users", "courses", "lessons", "localize", "adminstore"]),
        lessonList: function() {
            if(!this.lessons || !this.context) return [];
            // console.log('lessonList', this.context, this.lessons.list, _filter(this.lessons.list, {course: this.context}));
            let list = (this.context === "All") ? this.lessons.list : _filter(this.lessons.list, {course: this.context});
            return _sortBy(list, ['sortorder', 'order']);
        }   
    },
    methods: {
        loadLessons(){
            console.log("Ready to load lessons for", this.context);
            this.$store.dispatch("lessons/getList")
                .then((result) => {
                    if(this.lessonId && result){
                        if(result[this.lessonId]){
                            console.log('init found lesson record', this.lessonId);
                            this.lessonRecord =  result[this.lessonId];
                            this.manageRecord = 'lessonRecord';
                        } else if(this.lessonId === 'new') {
                            this.lessonRecord = { _id: 'new', course: this.context };
                            this.manageRecord = 'lessonRecord';
                        } else {
                            console.log("init did not find a matching record", this.lessonId, result);
                        }
                    } 
                    this.isLoading = false;

                    console.log('result from lessons.loadLessons', result);
                })
                .catch((e) => {
                    console.log('error in admin courses getList', e);
                })


        },
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },  
        handleAction: function(action, params, opts) {
            console.log("AdminCourses handleAction", action, params);

            switch(action){
                case 'close': 
                    {
                        console.log("closing", this.$route.query)
                        this.lessonRecord = false;
                        this.manageRecord = false;
                        if(!opts && this.$route.query && this.$route.query.after){
                            switch(this.$route.query.after){
                                case 'course':
                                    opts = '/Course/' + filterId(this.context)
                            }
                        }
                        
                        let newpath = (opts && typeof opts === "string" && opts != "close") ? opts : "/admin/lessons/" + this.context;
                        console.log("Let's go to ", newpath, this.$route)
                        if(this.$route.path !== newpath){
                            this.$router.push(newpath);
                        } 
                    }
                    break;
                case 'edit':
                    if(this.lessons.list[params._id]){
                        this.lessonRecord = this.lessons.list[params._id];
                        this.manageRecord = 'lessonRecord';
                        let newpath = "/admin/lessons/" + this.context + "/" + params._id;
                        if(this.$route.path !== newpath){
                            this.$router.push(newpath);
                        }                    
                    }
                    break;
                case 'remove':
                    if(this.lessons.list[params._id]){
                        this.lessonRecord = this.lessons.list[params._id];
                        this.manageRecord = 'removeRecord';
                    } 
                    break;
                case 'removeConfirmed':
                    {
                        this.$store.dispatch('lessons/remove', params)
                            .then((result) => {
                                if(result){
                                    this.handleAction('close');
                                    this.$store.commit("content/getList", {reset: true});
                                }
                            })
                    }                    
                    break;
                case 'add':
                    {
                        console.log("Add a lesson");
                        this.lessonRecord = { _id: 'new', course: this.context };
                        this.manageRecord = 'lessonRecord';
                        let newpath = "/admin/lessons/" + this.context + "/new";
                        if(this.$route.path !== newpath){
                            this.$router.push(newpath);
                        }
                    }  
                    break;
                case 'save':
                    console.log("Save lesson", params);
                    if(!params.course) params.course = this.context;
                    this.$store.dispatch("lessons/save", params )
                        .then((result) => {
                        // console.log('lessons.save result', result);
                            // this.$store.dispatch('setMessage', false, {root: true});
                            if(opts){ 
                                this.handleAction('close', params, opts);
                            } else {
                                // let newpath = "/admin/lessons/" + this.context + "/" + params._id;
                                // if(this.$route.path !== newpath){
                                //     this.$router.push(newpath);
                                // }   
                            }
                            this.lessonRecord = result 
                        });
                    break;
                case 'listcourses':
                    this.$router.push('/admin/courses');
                    break;                    
                // case 'materials':
                //     if(this.lessons.list[params._id]){
                //         this.lessonRecord = this.lessons.list[params._id];
                //         this.manageRecord = 'materials';
                //     } 
                //     break;   
            }
        },
        init(path){
            console.log("entering Admin_Lessons.init")
            if(path && typeof path === 'string'){   
                let p = path.split('/');
                let context = filterId(p[0]);
                let lessonid = (p[1]) ? filterId(p[1]) : null;
                console.log("Admin_Lessons.init", path, context, lessonid, p[2])
                this.context = context
                this.lessonId = lessonid;
            } 
            this.isLoading = true;
            this.loadLessons();
        },

    },
    created () {
        console.log("Loading Lessons Admin", this.$route);
        this.init(this.$route.params.pathMatch)
    },
    watch: {
        '$route.params.id': {
            handler: function(s){
                console.log("lessons watcher triggered", s, this.$route);
                let context = s || (this.$route.params && this.$route.params.pathMatch);
                if(this.context !== context) this.init(context);
                // this.context = s || (this.$route.params && this.$route.params.pathMatch);
            },
            deep: true,
            immediate: false
        }
    }
}

</script>

