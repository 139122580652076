<template>
    <div>
        <AdminTitleBar
            :title="isnew ? loc('Add Content') : loc('Edit Content Properties')"
            :actions="titleBarActions"
            v-on:actionhandler="handleAction"
        />         
        <v-card id="editcontentproperties" class="pb-2">
            <v-card-text class='contentform'>
                <v-row justify="space-between">
                    <v-text-field
                        name="title"
                        v-model="record.title"
                        :label="loc('Title')"
                        :hint="loc('For some content block types the title will not be displayed.')"
                        persistent-hint                        
                    ></v-text-field>
                </v-row>    
                <v-row v-if="isnew" justify="space-between" class="pb-2">
                    <v-select
                        name="type"
                        v-model="record.type"
                        :label="loc('Block Type')"
                        :items="['block','snippet','message', 'certificate']"
                    ></v-select>
                </v-row>     
                <v-row v-if="record.type === 'block'" justify="space-between" class="pb-2">
                    <v-select
                        name="subtype"
                        v-model="record.subtype"
                        :label="loc('Sub Type')"
                        :items="subtypeSelect"
                    ></v-select>
                </v-row>                  
                <v-row v-if="record.type !== 'message' && record.type !== 'certificate'" justify="space-between" class="pb-2">
                    <v-select
                        name="context"
                        v-model="record.context"
                        :label="loc('Context')"
                        :items="existingContexts"
                        :error-messages="context_error"
                    ></v-select>
                </v-row>                               
                <v-row justify="space-between" class="pb-2">
                    <v-select
                        name="lang"
                        v-model="record.lang"
                        :label="loc('Language')"
                        :items="languages"
                    ></v-select>
                </v-row>   
                <v-row justify="space-between" v-if="record.type !== 'certificate'">
                    <v-text-field
                        name="order"
                        v-model="record.order"
                        :label="loc('Order')"
                    ></v-text-field>
                </v-row>    
                <v-row justify="space-between" v-if="(!record.subtype || record.subtype === 'content') && record.type !== 'certificate' ">
                    <v-text-field
                        name="video"
                        v-model="record.video"
                        :label="loc('Video or Image URL (if relevant)')"
                    ></v-text-field>
                    <v-select name="videoplacement" v-model="record.videoplacement" class='ml-3'
                        :label="loc('Media Placement') "
                        :items="[{value:'first',text:'Media on the Left'},{value: 'solo', text: 'Media in the Center'},{value:'last',text:'Media on the Right'}]"
                    ></v-select>
                </v-row>                   
                <v-row class="pt-3 pb-3" v-if="!record.subtype || record.subtype === 'content'">
                    <h2 >Content</h2>
                </v-row>  
                <v-row justify="space-between" class="pb-3"  style="width:100%" id="sharededitor" v-if="!record.subtype || record.subtype === 'content'">
                    <SharedEditor :record="record" :handleChange="handleBodyChange" />
                </v-row>
                <v-divider class="mx-4 mt-3"></v-divider>
                <v-row class='actionrow mt-3'>
                    <v-btn small color="primary" dark @click="handleSave()" class="mr-1">{{ loc("Save Changes") }}</v-btn>
                    <v-btn small color="primary" dark @click="handleSave('close')" class="mr-1">{{ loc("Save and Go to List") }}</v-btn>
                    <v-btn small color="secondary" dark @click="handleCancel('close')" class="mr-1">{{ loc("Cancel") }}</v-btn>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card v-if="englishversion" class='englishversion elevation-2 pa-4 mt-4 ql-container ql-snow'>
            <div ><h2>{{ loc('Current English Version') }}</h2></div>
            <v-divider></v-divider>
            <div class='ql-editor' v-html="englishversion" ></div>
        </v-card>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import AdminTitleBar from "./Admin_TitleBar";
import SharedEditor from './Shared_Editor';
import { filterId } from '../lib/filter';
import _sortBy from 'lodash/sortBy';

export default {
    name: "AdminContentsEdit",
    props: {
        // list: { type: Array },
        // editId: { type: String },
        contentRecord: { type: Object }
    },
    components: { AdminTitleBar, SharedEditor },
    data() {
        return {
            record: this.contentRecord,
            isnew: this.contentRecord._id === 'new',
            context_error: '',
            subtypeSelect:  [
                {text: "Content (default)", value: "content"},
                {text: "Content Cards", value: "cards"},
                {text: "Content Accordian", value: "accordian"},
                {text: "Course List", value: "courselisting"},
                {text: "Registration", value: "register"},
                {text: "Survey", value: "survey"},
                {text: "Language List", value: "languagelist"},
            ]            
        }
    },
    computed: {
        ...mapState(['users', 'localize', 'adminstore', 'content']),
        languages: function(){
            let list = (this.adminstore && this.adminstore.siteconfig && this.adminstore.siteconfig.languages) || {en:"English"};
            let out = [];
            for(let k in list){
                out.push({ value: k, text: list[k] });
            }
            return out;
        },
        existingContexts: function(){
            let list = {}; 
            for(let k in this.content.list){
                if(this.content.list[k].context && typeof this.content.list[k].context === "string") list[this.content.list[k].context] = {text: this.content.list[k].context, value: this.content.list[k].context};
            }
            list = _sortBy(Object.values(list), 'text');
            list.unshift({text:'Select a Context', value: ''});
            console.log(list);
            return list;
        },
        titleBarActions: function(){
            if(this.record.type === 'message'){ 
                return [
                    { text: "Close", key: "close" }
                ]
            } else {
                return [
                    { text: "Close", key: "close" },
                    { text: "Go to Page", key: "gotopage" }
                ]
            }
        },
        englishversion: function(){
            let id = this.contentRecord._id;
            if(!id) return;

            let parts = id.split('-');
            let lang = parts.pop();
            let id2 = parts.join('-');

            if(this.content && this.content.list){
                if(this.content.list[id2]){
                    return this.content.list[id2].body
                } else if(this.content.list[id2 + '-en']){
                    return this.content.list[id2 + '-en'].body
                } else if(this.contentRecord.lang && this.contentRecord.lang !== 'en' && this.content.list[id] && this.content.list[id] !== this.contentRecord.lang){
                    return ''; // this.content.list[id].body;
                } 
            }
            return '';
        }
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },   
        handleAction: function(action, params){
            this.$emit("actionhandler", action, params);
        },
        handleDescriptionChange: function(val){
            console.log(val);
            this.record.description = val; 
        },
        handleDescriptionClose: function(val){

        },
        handleBodyChange: function(val, opts) {
            console.log('handleBodyChange', val, opts)
            // this.$emit("actionhandler", "change", this.record, val, opts);
            this.record.body = val;
        },
        handleSave: function(val){
            if(val === 'page') val = "/" + this.context;
            this.context_error = '';
            if(this.isnew){
                // This logic could probably be simplified.
                // The main goal is to make sure we have an _id that isn't 'new'
                // and that all 'block' type records have a context.
                if(this.record.type === 'message') this.record.context = null;
                if(this.record.type === "block"){
                    if(!this.record.context) return this.validationError('context_error', this.loc("Context is required for 'block' records."));
                    this.record._id = this.record.context + Date.now().toString(36);
                    let count = 1;
                    for(let k in this.content){
                        if(this.content[k].context === this.record.context) count++;
                    }
                    this.record.order = count;
                } else if(!this.record._id || this.record._id === 'new') {
                    if(this.record.title){
                        this.record._id = filterId(this.record.title);
                    } else if(this.record.context){
                        this.record._id = this.record.context + Date.now().toString(36);
                    } else {
                        this.record._id = Date.now().toString(36);
                    }
                }
            }
            this.$emit("actionhandler", "save", this.record, val);
        },
        handleCancel: function(val){
            this.$emit("actionhandler", 'close', val)
        },
        handleStyle: function(val, a, b){
            // console.log('style field updated', a, b, val);
            this.record.style = val;
        },
        validationError(field, message){
            this[field] = message;
        }

    }
  
}
</script>