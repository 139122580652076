<template>
    <v-container>
        <div v-if="authError">
            {{ loc('Please Login') }}
        </div>
        <div v-else-if="!users.currentUser || !users.currentUser.isAdmin" >
            {{ loc("You do not have permission to do that.") }}
        </div>
        <div v-else-if="record">
  
            <div v-if="manageRecord === 'editpage'" style="width:100%" >
                <AdminContentEditPage :contentRecord="record" v-on:actionhandler="handleAction" />
            </div>
            <div v-else-if="manageRecord === 'remove'">
                <AdminContentRemove :record="record" v-on:actionhandler="handleAction" />
            </div>
            <div v-else-if="manageRecord" style="width:100%">
                <AdminContentEdit :contentRecord="record" v-on:actionhandler="handleAction" />
            </div>             
        </div>
        <div v-else-if="content.list">
            <div >
                <AdminContentList :recordList="contentList" v-on:actionhandler="handleAction" :viewtype="viewtype" :viewcontext="viewcontext" :viewlanguage="viewlanguage" />
            </div>
        </div>
        <div v-else>
            Loading...
        </div>
    </v-container>
</template>

<script>
import { mapState } from "vuex";
// import AdminTitleBar from "./Admin_TitleBar";

import AdminContentList from './Admin_Content_List';
import AdminContentEditPage from './Admin_Content_Edit_Page'; // edit pages top level properties
import AdminContentEdit from './Admin_Content_Edit';          // edit content.
import AdminContentRemove from './Admin_Content_Remove'; 

export default {
    name: "AdminContent",
    components: { AdminContentList, AdminContentEditPage, AdminContentEdit, AdminContentRemove},
    data() {
        return {
            context: false,
            record: false,
            viewtype: 'page',
            viewcontext: false,
            viewlanguage: false,
            // list: false,
            manageRecord: false,
            headers: {
                _id: {
                    text: "ID",
                    value: "_id",
                    show: true
                },
                title: {
                    text: "Title",
                    value: "title",
                    sortable: true,
                    align: "start",
                    show: true
                },
                shorttitle: {
                    text: "Short Title",
                    value: "shorttitle",
                    sortable: true,
                    align: "start",
                    show: false
                },
                context: {
                    text: "Context",
                    value: "context",
                    sortable: true,
                    align: "start",
                    show: true
                },
                type: {
                    text: "Type",
                    value: "type",
                    sortable: true,
                    align: "start",
                    show: false
                },
                subtype: {
                    text: "Subtype",
                    value: "subtype",
                    sortable: true,
                    align: "start",
                    show: false
                },                
                order: {
                    text: "Order",
                    value: "order",
                    sortable: true,
                    align: "start",
                    show: false
                },
                lang: {
                    text: "Language",
                    value: "lang",
                    sortable: true,
                    align: "start",
                    show: false
                },
                status: {
                    text: "Status",
                    value: "status",
                    sortable: true,
                    align: "start",
                    show: false
                },
                timestamp: {
                    text: "Created",
                    value: "timestamp",
                    sortable: true,
                    align: "start",
                    show: false
                },
                modified: {
                    text: "Modified",
                    value: "modified",
                    sortable: true,
                    align: "start",
                    show: true
                }
            },
        }
    },
    computed: {
        ...mapState(["authError", "users", "localize", "content"]),
        contentList: function() {
            return Object.values(this.content.list);
        }
    },
    methods: {
        getList: function (context) {
            console.log("AdminContent.getList");
            this.$store.dispatch("content/getList")
                .then((result) => {
                    // console.log("Is this a list?", result);
                    // console.log("Is this an id?", this.$route.params.pathMatch);
                    if(result[this.$route.params.pathMatch]){
                        this.handleAction('edit', result[this.$route.params.pathMatch]);
                    }
                })
        },
        handleAction: function(action, params, opts){
            console.log('handleaction', action, params, opts);
            switch(action){
                case 'addPage':
                    this.addContent('page');
                    break;   
                case 'addContent':
                    this.addContent(params);
                    break;                  
                case 'close':
                    {
                        this.manageRecord = false;
                        this.record = false;
                        let newpath = (opts && typeof opts === "string" && opts != "close") ? opts : "/admin/content";
                        console.log("Let's go to ", newpath)
                        if(this.$route.path !== newpath){
                            this.$router.push(newpath);
                        } 

                    }
                    break;
                case 'edit':
                    {
                        // console.log("content list at edit click", this.content.list);
                        this.record = this.content.list[params._id];
                        this.manageRecord = this.record.type === "page" ? 'editpage' : 'editblock';
                        console.log("AdminContent.handleAction.edit", params, this.record);
                        let newpath = "/admin/content/"+params._id;
                        if(this.$route.path !== newpath){
                            this.$router.push(newpath);
                        }                      
                    }
                    break;
                case 'gotopage':
                    {
                        let newpath = '/' + (this.record.context || this.record._id);
                        this.record=false
                        this.managerecord = false;
                        this.$router.push(newpath);
                    }
                case 'refresh':
                    console.log('refresh',this.context);
                    this.getList(this.context);
                    break;                           
                case 'remove':
                    this.record = this.content.list[params._id];
                    this.manageRecord = 'remove';
                    break;  
                case 'removeConfirmed':
                    {
                        this.$store.dispatch('content/remove', params)
                            .then((result) => {
                                if(result){
                                    this.handleAction('close');
                                    this.$store.commit("content/getList", {reset: true});
                                }
                            })
                    }
                    break;
                case 'save':
                    console.log("Save content", params);
                    this.$store.dispatch("content/save", params )
                        .then((result) => {
                        // console.log('courses.save result', result);
                            this.$store.dispatch('setMessage', false, {root:true});
                            if(opts){ 
                                this.handleAction('close', params, opts);
                            } else {
                                let newpath = "/admin/content/"+result._id;
                                if(this.$route.path !== newpath){
                                    this.$router.push(newpath);
                                }   
                            }
                            this.courseRecord = result 
                        });                    
                    break;     
                case 'setviewtype':
                    this.viewtype = params;
                    break;
                case 'setviewcontext':
                    this.viewcontext = params;
                    break;
                case 'setviewlanguage':
                    this.viewlanguage = params;
                    break;
            }
        },
        addContent: function (type) {
            if(typeof type === 'object') type = type.type;
            this.$store.dispatch('content/addNew', type || 'block')
                .then((result) => {
                    this.handleAction('edit', result);
                })
        },
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        }
    },
    created() {
        this.context = this.$route.params.pathMatch;
        this.viewcontext = this.context;
        this.getList()
        console.log("AdminContent created", this.context);
    }
}

</script>