<template>
    <v-container fluid>
        <div v-if="authError">
            {{ loc('Please Login') }}
        </div>
        <div v-else-if="!users.currentUser || !users.currentUser.isAdmin" >
            {{ loc("You do not have permission to do that.") }}
        </div>
        <div v-else>

            <div >
                <AdminEnrollmentList  v-on:actionhandler="handleAction" />
            </div>
        </div>
        <!-- <v-row class='mt-4'><pre>{{enrollment.list}}</pre></v-row> -->
        <!-- <v-row class='mt-4'><pre>{{enrollmentRecord}}</pre></v-row> -->
    </v-container>
</template>

<script>
import { mapState } from "vuex";
import AdminEnrollmentList from "./Admin_Enrollment_List";
import AdminEnrollmentEdit from "./Admin_Enrollment_Edit";
import AdminEnrollmentRemove from "./Admin_Enrollment_Remove";
import { filterId } from '../lib/filter';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';

export default {
    name: "AdminEnrollment",
    // components: { AdminEnrollmentList, AdminEnrollmentEdit, AdminEnrollmentRemove },
    components: { AdminEnrollmentList },
    data() {
        return {
            classid: false,
            enrollmentId: false,
            isLoading: true,
            after: false, 
            enrollmentRecord: false,
            manageRecord: false
        }
    },
    computed: {
        ...mapState(["authError", "users", "courses", "classes", "enrollment", "localize", "adminstore"]),
        // enrollmentList: function() {
        //     if(!this.enrollment || !this.classid || !this.enrollment.list[this.classid] ) return [];
        //     return Object.values(this.enrollment.list[this.classid]);
        //     // console.log('enrollmentList', this.classid, this.enrollment.list, _filter(this.enrollment.list, {course: this.classid}));
        //     // return _sortBy(_filter(this.enrollment.list, {class: this.classid}), ['sortorder', 'order']);
        // },
        // classRecord: function(){
        //     console.log("generating classRecord for Enrollment", this.classid, this.classes.list && this.classes.list[this.classid]);
        //     if(!this.classid) return {};
        //     return this.classes.list[this.classid];
        // },
        // course: function(){
        //     if(!this.classid) return {};
        //     if(this.classRecord) return this.courses.list[this.classRecord.course] || {};
        //     return {};
        // }
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },  
        handleAction: function(action, params, opts) {
            console.log("AdminEnrollment handleAction", action, params);
            return;

            // switch(action){

            // }
        },
        init(path){
            console.log("entering Admin_Enrollment.init")
        }

    },
    created () {
        console.log("Loading Enrollment Admin", this.$route);
        this.init(this.$route.params.pathMatch)
    },
    watch: {
        // '$route.params.id': {
        //     handler: function(s){
        //         console.log("enrollment watcher triggered", s, this.$route);
        //         let classid = s || (this.$route.params && this.$route.params.pathMatch);
        //         if(this.classid !== classid) this.init(classid);
        //     },
        //     deep: true,
        //     immediate: false
        // }
    }
}

</script>

