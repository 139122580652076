<template>
    <div>
        <AdminTitleBar
            :title="loc('Manage Courses')"
            :actions="titleBarActions"
            v-on:actionhandler="handleAction"
        />        
        <v-row v-if="tablePrefs">
            <div v-for="(item, idx) in headers" v-bind:key="idx">
                <v-checkbox style="min-width: 120px;"
                    :name="idx"
                    v-model="item.show"
                    :label="loc(item.text)"
                    @click.stop="saveheaders"
                ></v-checkbox>
            </div>
        </v-row>   
        <div v-if="courseList">
            
            <v-row class="searchrow">
                <v-select :items="courseSelect" v-bind:value="viewmain" :label=" loc('Main Course') " @change="handleAction('setviewmain', $event)" ></v-select>
                <v-select :items="languages" v-bind:value="viewlanguage" :label=" loc('Language') " @change="handleAction('setviewlanguage', $event)" class="ml-3" ></v-select>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="search"
                    :label="loc('Search')"
                    single-line
                    hide-details
                ></v-text-field>
            </v-row>
            <v-data-table
                :items-per-page="itemsPerPage"
                :headers="tableHeaders"
                :footer-props="{ itemsPerPageOptions:[10,20,30,40,50,100,200] }"
                :items="courseList"
                :search="search"
                class="courseList elevation-1  my-8 pb2"
            >
                <template v-slot:[`header.actions`]="{header}">
                    {{header.text}}
                    <span @click="tablePrefs=!tablePrefs" class="dtableactionicon"><v-icon small >edit</v-icon> {{ loc("Columns") }}</span>
                </template>
                <template v-slot:[`item.title`]="{ item }" class="linkcolumn titlecolumn">
                    <router-link :to="'/Course/' + item._id">{{ item.title }}</router-link>
                </template>      
                <template v-slot:[`item.actions`]="{ item }" class="actioncolumn">
                    <span class="manageusericons">
                        <v-btn small color="secondary" :to="'/admin/lessons/' + item._id" dark class="mr-1">{{ loc("Lessons") }}</v-btn>
                        <v-btn small color="primary" dark @click="handleEdit(item)" class="mr-1">{{ loc("Edit") }}</v-btn>
                        <v-btn small color="error" dark @click="handleRemove(item)">{{ loc("Remove") }}</v-btn>
                    </span>
                </template>
            </v-data-table>
        </div>
        <div v-else>
            {{ loc('No data yet.') }}
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import AdminTitleBar from "./Admin_TitleBar";
// import _filter from 'lodash/filter';


export default {
    name: "AdminCoursesList",
    props: {
        courseList: { type: Array },
        viewmain: { type: [String, Boolean] },
        viewlanguage: { type: [String, Boolean] }
    },
    components: { AdminTitleBar },
    data() {
        return {
            itemsPerPage: 50,
            tablePrefs: false,
            manageRecord: false,
            search: "",
            headers: {
                _id: {
                    text: "ID",
                    value: "_id"
                },
                title: {
                    text: "Title",
                    value: "title",
                    sortable: true,
                    align: "start",
                    show: true
                },
                reftitle: {
                    text: "Reference Title",
                    value: "reftitle",
                    sortable: true,
                    align: "start",
                    show: false
                },
                main: {
                    text: "Main",
                    value: "main",
                    sortable: true,
                    align: "start",
                    show: true
                },
                category: {
                    text: "Category",
                    value: "category",
                    sortable: true,
                    align: "start",
                    show: false
                },
                lang: {
                    text: "Language",
                    value: "lang",
                    sortable: true,
                    align: "start",
                    show: true
                },
                offline: {
                    text: "Offline",
                    value: "offline",
                    sortable: true,
                    align: "start",
                    show: false
                },
                order: {
                    text: "Display Order",
                    value: "order",
                    sortable: true,
                    align: "end",
                    show: false
                },
                sections:{
                    text: "Sections",
                    value: "sections",
                    sortable: false,
                    align: "start", 
                    show: false
                },
                status: {
                    text: "Status",
                    value: "status",
                    sortable: true,
                    align: "start",
                    show: false
                },
                timestamp: {
                    text: "Created",
                    value: "created",
                    sortable: true,
                    align: "start",
                    show: true
                },
                modified: {
                    text: "Modified",
                    value: "modified",
                    sortable: true,
                    align: "start",
                    show: false
                }
                // ,
                // lessons: {
                //     text: 'Lessons',
                //     value: "lessons",
                //     sortable: false,
                //     align: "start",
                //     show: true
                // }
            },
            titleBarActions: [
                { text: "Export Courses", key: "exportcourses", href: "/app_reports/courses/courses.csv"},
                { text: "Refresh List", key: "refresh" },
                { text: "Add Course", key: "add" }
            ],            
        }
    },
    computed: {
        ...mapState(['users', 'localize', 'adminstore', 'courses']),
        tableHeaders: function() {
            var temp = Object.assign({}, this.headers);
            let columns = []
            for(let k in temp){
                if(temp[k].show){
                    temp[k].sortable = true;
                    temp[k].align = temp[k].align || "start";
                    columns.push(temp[k]);
                }
            }

            columns.push({ text: "Actions", sortable: false, value: "actions" });
            return columns;            
        },
        courseSelect: function() {
            let list = {
                'All': { text: "Show All", value: false}
            };
            for(let k in this.courses.list){
                if(this.courses.list[k].main) list[this.courses.list[k].main] = { text: this.courses.list[k].main, value: this.courses.list[k].main }
            }
            return Object.values(list);
        },
        languages: function(){
            let list = (this.adminstore && this.adminstore.siteconfig && this.adminstore.siteconfig.languages) || {en:"English"};
            let out = [];
            for(let k in list){
                out.push({ value: k, text: list[k] });
            }
            out.unshift({text: 'Show All', value: false})
            return out;
        },         
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },  
        handleEdit: function(evt) {
            this.$emit("actionhandler", 'edit', evt)
        },
        handleRemove: function(evt) {
            this.$emit("actionhandler", 'remove', evt)
        },
        handleAction: function(action, params){
            this.$emit("actionhandler", action, params);
        },
        saveheaders: function(){
            localStorage.AdminCoursesList_headers = JSON.stringify(this.headers);
        }        
    },
    mounted() {
        if(localStorage.AdminCoursesList_headers){
            try {
                let h = JSON.parse(localStorage.AdminCoursesList_headers);
                h && (this.headers = h);
            } catch(e){
                console.log("Error parsing from local storage",e);
            }
        }
    }
  
}
</script>