<template>
    <div>
        <AdminTitleBar
            :title="loc('Lesson Materials') + (course && course.title ? (' - ' + course.title) : '') + ' - ' + lesson.title "
            :actions="titleBarActions"
            v-on:actionhandler="handleAction"
        />      
        <h4 v-text="'Editing ' + (record.title || 'New Record') + '  (' + record._id + ')'" ></h4>
        <v-card id="editlessonproperties" class="pa-2">
            <v-card-text class='lessonform'>
                <v-row justify="space-between">
                    <v-text-field
                        name="title"
                        v-model="editRecord.title"
                        :label="loc('Title')"
                    ></v-text-field>
                </v-row>  
                <v-row justify="space-between">
                    <v-select
                        name="lang"
                        v-model="editRecord.lang"
                        :label="loc('Language')"
                        :items="languages"
                    ></v-select>
                </v-row>
                <v-row justify="space-between">
                    <v-text-field
                        name="order"
                        v-model="editRecord.order"
                        :label="loc('Order')"
                    ></v-text-field>
                </v-row>
                <v-row justify="space-between">
                    <v-select
                        name="section"
                        v-model="editRecord.section"
                        :label="loc('Type')"
                        :items="materialTypes"
                    ></v-select>
                </v-row>
                <v-row justify="space-between">
                    <v-checkbox 
                        name="required" 
                        v-model="editRecord.required"
                        :label="loc('Required')"
                    ></v-checkbox>  
                </v-row>

                <div v-if="editRecord.section === 'Survey'" class="pt-4 pb-3 mt-4">
                    <ContentBlockSurvey :record="editRecord" context="Lesson" :contextid="editRecord._id" :sid="editRecord.subid" :options="{embed: true}" v-on:actionhandler="handleSurveyActions" />
                </div>
      

                <div v-else-if="editRecord.section === 'Quiz' || editRecord.section === 'Exam'" class='pt-4 pb-3 mt-4'>
                    <v-row justify="space-between">
                        <div style="width: 100%; padding-bottom: 12px;">
                            <label :for="'description-'+editRecord._id" class="v-label theme--light" style="left: 0px; right: auto; position: relative;">{{ loc("Description") }}</label>
                        </div>                    
                        <SharedEditor
                            name="description"
                            :record="editRecord.description"
                            :handleChange="handleDescriptionChange"
                            :id="'description-'+editRecord._id"
                        ></SharedEditor>         
                    </v-row>    
                    <v-row justify="space-between">
                        <div style="width: 100%; padding-bottom: 12px;">
                            <label :for="'description1-'+editRecord._id" class="v-label theme--light" style="left: 0px; right: auto; position: relative;">{{ loc("Success Message") }}</label>
                        </div>                    
                        <SharedEditor
                            name="description1"
                            :record="editRecord.description1"
                            :handleChange="handleDescriptionChange"
                            :id="'description1-'+editRecord._id"
                            field="description1"
                        ></SharedEditor>         
                    </v-row>                                         
                    <v-row justify="space-between">
                        <div style="width: 100%; padding-bottom: 12px;">
                            <label :for="'description2-'+editRecord._id" class="v-label theme--light" style="left: 0px; right: auto; position: relative;">{{ loc("Failed Message") }}</label>
                        </div>                    
                        <SharedEditor
                            name="description2"
                            :record="editRecord.description2"
                            :handleChange="handleDescriptionChange"
                            :id="'description2-'+editRecord._id"
                            field="description2"
                        ></SharedEditor>         
                    </v-row>                                         
                    <div v-if="editRecord.section === 'Quiz'">
                        <v-row justify="space-between">
                            <v-col cols="8"><h2>Questions</h2></v-col>
                            <v-col cols="4"><v-btn @click="handleAction('addQuestion')" color="primary">Add Question</v-btn></v-col>
                        </v-row>
                        <v-row v-for="(item, idx) in editRecord.quiz" :key="'question-'+idx" :ref="'questionblock-'+idx" class="elevation-1 pa-3" >
                            <v-col cols="8" class='questionnumber text-h6' >Question {{ idx + 1 }}</v-col>
                            <v-col cols="4"><v-btn @click="handleAction('removeQuestion', {idx: idx, _id: editRecord._id})" color="primary">Remove Question</v-btn></v-col>
                            <v-col cols="12" class='px-0' >
                                <v-checkbox v-model="item.onexam" label="On Exam" class='px-0'></v-checkbox>
                            </v-col>
                            <v-col cols="12" >
                                <v-textarea
                                    outlined
                                    :name="'question-'+idx"
                                    label="Question"
                                    v-model="item.question"
                                ></v-textarea>
                            </v-col>

                            <v-col cols="12" >
                                <v-row class="font-weight-black"><v-col cols="10">Answers <v-btn @click="handleAction('addAnswer', {idx: idx, _id: editRecord._id})" class='ml-4' color="primary">Add Answer</v-btn></v-col><v-col cols="2">Correct ({{ item.iscorrect }})</v-col></v-row>
                                <v-radio-group v-model="item.iscorrect">
                                    <v-row v-for="(answer, adx) in item.answers" :key="adx" :id="'answers-'+adx">
                                        <v-col cols="1" class="pt-4 text-center font-weight-black">{{ Alpha[adx] }}</v-col>
                                        <v-col cols="9"><v-text-field :value="answer" class="pt-0" @change="answerChange($event, {idx: idx, adx: adx})"></v-text-field> </v-col>
                                        <v-col cols="1" ><v-radio :value="adx + 1"></v-radio></v-col> 
                                        <v-col cols="1" ><v-icon @click="handleAction('removeAnswer', {idx: idx, _id: editRecord._id, adx: adx})">delete</v-icon></v-col>
                                    </v-row>
                                </v-radio-group>
                            </v-col>

                        </v-row>
                        <v-row justify="space-between" v-if="editRecord.quiz && editRecord.quiz.length > 2">
                            <v-col cols="4"><v-btn @click="handleAction('addQuestion')" color="primary">Add Question</v-btn></v-col>
                        </v-row>
                    </div>
                </div>
                <div  v-else >
                    <v-row justify="space-between">
                        <div style="width: 100%; padding-bottom: 12px;">
                            <label :for="'description-'+editRecord._id" class="v-label theme--light" style="left: 0px; right: auto; position: relative;">{{ loc("Block Content") }}</label>
                        </div>                    
                        <SharedEditor
                            name="description"
                            :record="editRecord.description"
                            :handleChange="handleDescriptionChange"
                            :id="'description-'+editRecord._id"
                        ></SharedEditor>         
                    </v-row> 
                    <v-row justify="space-between">
                        <v-text-field
                            name="video"
                            v-model="editRecord.video"
                            :label="loc('Video or Image URL (if relevant)')"
                        ></v-text-field>
                        <v-select name="videoplacement" v-model="editRecord.videoplacement" class='ml-3'
                            :label="loc('Media Placement') "
                            :items="[{value:'first',text:'Media on the Left'},{value:'last',text:'Media on the Right'}]"
                        ></v-select>
                    </v-row>   
                    <v-row justify="space-between">
                        <div style="width: 100%; padding-bottom: 12px;">
                            <label :for="'question-'+editRecord._id" class="v-label theme--light" style="left: 0px; right: auto; position: relative;">{{ loc("Question") }}</label>
                        </div>                    
                        <SharedEditor
                            name="description"
                            :record="editRecord.question"
                            :handleChange="handleDescriptionChange"
                            :id="'question-'+editRecord._id"
                            field="question"
                        ></SharedEditor>         
                    </v-row>                           
                </div>              
                <v-row class='actionrow my-6' ref="bottomactionbar">
                    <v-btn small color="primary" dark @click="handleAction('save')" v-if="record &&  record._id" class="mr-1">{{ loc("Save Changes") }}</v-btn> 
                    <v-btn small color="primary" dark @click="handleAction('save', 'close')" class="mr-1">{{ loc("Save and Go to List") }}</v-btn>
                    <v-btn small color="primary" dark @click="handleAction('save', 'lesson')" class="mr-1">{{ loc("Save and Go to Lesson") }}</v-btn>
                    <v-btn small color="secondary" dark @click="handleAction('close')" class="mr-1">{{ loc("Cancel") }}</v-btn>
                </v-row>
            </v-card-text>
        </v-card>
        <div style="display:none">
            <h2>editRecord</h2>
            <pre>{{editRecord}}</pre>
            <h2>course</h2>
            <pre>{{course}}</pre>
            <h2>lesson</h2>
            <pre>{{lesson}}</pre> 
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import SharedEditor from './Shared_Editor';

import ContentBlockSurvey from './Content_Block_Survey.vue';

import AdminTitleBar from "./Admin_TitleBar";
import siteConfig from '../config/site.js';

export default {
    name: "AdminLessonMaterialsEdit",
    components: { AdminTitleBar, SharedEditor, ContentBlockSurvey },
    props: {
        course: { type: [Object, Boolean] },
        lesson: { type: [Object, Boolean] },
        record: { type: Object }
    },
    data() {
        return {
            titleBarActions: [
                    { text: "Close", key: "close" },
                    { text: "Go To Lesson", key: "gotolesson" },
                    { text: "Manage LEsson", key: "managelesson" }
            ],
            materialTypes: siteConfig.materialTypes,
            editRecord: this.record,
            Alpha: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
        }
    },
    computed: {
        ...mapState(["authError", "users", "courses", "lessons", "localize", "adminstore"]),
        languages: function(){
            let list = (this.adminstore && this.adminstore.siteconfig && this.adminstore.siteconfig.languages) || {en:"English"};
            let out = [];
            for(let k in list){
                out.push({ value: k, text: list[k] });
            }
            return out;
        }        
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        }, 
        handleAction: function(action, params){
            switch(action){
                case 'close': 
                    this.$emit("actionhandler", 'close', params);
                    break;
                case 'gotolesson':
                    this.$emit("actionhandler", 'gotolesson', this.record && this.record._id);
                    break;
                case 'addQuestion':
                    // console.log('addQuestion', this.editRecord.quiz);
                    if(!this.editRecord.quiz) this.$set(this.editRecord, 'quiz', []);
                    this.editRecord.quiz.push({question: "", iscorrect: 0, onexam: false, answers:[]});
                    this.$refs.bottomactionbar.scrollIntoView({behavior: 'smooth'});
                    break;
                case 'removeQuestion':
                    this.editRecord.quiz.splice(params.idx, 1);
                    break;
                case 'addAnswer':
                    this.editRecord.quiz[params.idx].answers.push("")
                    break;
                case 'removeAnswer':
                    this.editRecord.quiz[params.idx].answers.splice(params.adx, 1);
                    break;
                case 'surveySelect':
                    console.log('materials edit surveySelect', params)
                    this.editRecord.subid = params;
                    break;
                case 'save':
                    this.saveRecord(params);
                    break;
                default:
                    console.log("Admin Materials Edit action not found", action, params);
            }
        },
        saveRecord: function(params){
            // console.log("Materials Admin saverecord", params);

            // console.log("Saving materials", this.editRecord);

            this.$store.dispatch("lessons/saveMaterials", this.editRecord)
                .then((result) => {
                    // console.log("saveMaterials response", result);
                    if(params === "lesson"){
                        this.handleAction('gotolesson');
                    } else if(params === 'close') {
                        this.handleAction('close');
                    }
                })


        },
        answerChange: function(e, keys){
            // console.log(e, keys);
            this.editRecord.quiz[keys.idx].answers[keys.adx] = e;
        },
        handleDescriptionChange: function(val, field){
            // console.log(val);
            field = field || 'description';
            this.editRecord[field] = val;
        },
        handleSurveyActions: function(val){
            console.log("Admin materials edit handleSurveyActions", val);
            this.editRecord.subid = val;
        },  
    }
    
}
</script>