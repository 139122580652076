<template>
    <div>
        <v-row>
            <h2>Navigation - {{language}}</h2>
        </v-row>        
        <v-row class="mb-4">
            <v-col md="2" class="label">Language</v-col>
            <v-col md="9" class="value" ><v-select name='lang' v-model="language" :items="languages" style="width: 260px;" @change="toggleLanguage"/></v-col>
        </v-row>
        <v-row class="mb-4">
            <h3>{{ loc('This feature sets the whole list at once.  Icons must be from https://materialdesignicons.com.') }}</h3>
        </v-row>

        <v-row v-if="language && navlist && !navlist[language] " class="mb-4">
            <h3>{{ loc('This language does not have a list set yet, so it is using the defaults.') }}</h3>
        </v-row>
        <div v-if="list && listsdefs">
            <div v-for="(defitem, defkey) in listsdefs" v-bind:key="defkey" class="pt-10 pb-8" style="border-top: 2px solid #333">
                <v-row>
                    <h2>{{defitem.name}}</h2>
                </v-row>
                <template v-if="list[defitem.key] && list[defitem.key].length">
                    <v-row class="pa-6 adminnavitem" v-for="(item, idx) in list[defitem.key]" v-bind:key="item.key" :id="item.key" >
                        <v-col cols="12">
                            <v-row>
                                <v-col cols="12" md="2" class="label">{{ loc('Title') }}</v-col>
                                <v-col cols="12" md="10" class="value" v-if="!isEditing" >{{item.title}}</v-col>
                                <v-col cols="12" md="5" class="value pt-0" v-if="isEditing" ><v-text-field v-model="item.title"></v-text-field></v-col>
                                <v-col cols="12" md="5" class="adminaction" v-if="isEditing">
                                    <v-btn small color="error" @click="removeRecord(defitem.key, idx)" >{{ loc('Remove') }}</v-btn>
                                    <v-btn small color="primary" @click="addRecord(defitem.key, idx)" class="ml-4">{{ loc('Add Another After') }}</v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="2" class="label">{{ loc('URL') }}</v-col>
                                <v-col cols="12" md="10" class="value" v-if="!isEditing" >{{item.url}}</v-col>
                                <v-col cols="12" md="5" class="value  pt-0" v-if="isEditing" ><v-text-field v-model="item.url"></v-text-field></v-col>
                                <v-col cols="12" md="5" class="adminaction" v-if="isEditing"></v-col>
                            </v-row>
                            <v-row v-if="defitem.key !== 'top'">
                                <v-col cols="12" md="2" class="label">{{ loc('Icon') }}</v-col>
                                <v-col cols="12" md="10" class="value" v-if="!isEditing" >{{item.icon}}</v-col>
                                <v-col cols="12" md="5" class="value  pt-0" v-if="isEditing" ><v-text-field v-model="item.icon"></v-text-field></v-col>
                                <v-col cols="12" md="5" class="adminaction" v-if="isEditing"></v-col>
                            </v-row>     
                        </v-col>                       
                    </v-row>
                </template>
                <template v-else>
                    <div class="pa-6 adminnavitem" >
                        <v-col cols="12" md="5" class="adminaction" v-if="isEditing">
                            <v-btn small color="primary" @click="addRecord(defitem.key, 0)" class="ml-4">{{ loc('Add An Entry') }}</v-btn>
                        </v-col>
                    </div>
                </template>
            </div>
        </div>

        <v-row>
            <v-col cols="12">
                <v-btn class="mr-6" @click="toggleEditing" dark color="isEditing ? 'error' : 'primary'">{{ loc(isEditing ? 'Cancel' : 'Edit') }}</v-btn>
                <v-btn v-if="isEditing" @click="saveNav" color="primary">{{ loc('Save') }}</v-btn>
            </v-col>    
        </v-row>        
<!--             
        <div v-if="list && false" class="mt-6 pt-4" style="border-top: 1px solid #bbb;">
            <v-row>
                <h2>{{ loc('Top Navigation') }}</h2>
            </v-row>
            <template v-if="list.top">
                <div class="pa-6 adminnavitem" v-for="(item, idx) in list.top" v-bind:key="item.key" :id="item.key" >
                    <v-row>
                        <v-col cols="12" md="2" class="label">{{ loc('Title') }}</v-col>
                        <v-col cols="12" md="10" class="value" v-if="!isEditing" >{{item.title}}</v-col>
                        <v-col cols="12" md="5" class="value pt-0" v-if="isEditing" ><v-text-field v-model="item.title"></v-text-field></v-col>
                        <v-col cols="12" md="5" class="adminaction" v-if="isEditing"><v-btn small color="error" @click="removeRecord('top', idx)" >{{ loc('Remove') }}</v-btn><v-btn small color="primary" @click="addRecord('top', idx)" class="ml-4">{{ loc('Add Another After') }}</v-btn></v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="2" class="label">{{ loc('URL') }}</v-col>
                        <v-col cols="12" md="10" class="value" v-if="!isEditing" >{{item.url}}</v-col>
                        <v-col cols="12" md="5" class="value  pt-0" v-if="isEditing" ><v-text-field v-model="item.url"></v-text-field></v-col>
                        <v-col cols="12" md="5" class="adminaction" v-if="isEditing"></v-col>
                    </v-row>
                </div>
            </template>
            <v-row class="mt-5">
                <h2>{{ loc('Side Navigation') }}</h2>
            </v-row>
            <template v-if="list.left">
                <div class="pa-6 adminnavitem" v-for="(item, idx) in list.left" v-bind:key="item.key" :id="item.key" >
                    <v-row>
                        <v-col cols="12" md="2" class="label">{{ loc('Title') }}</v-col>
                        <v-col cols="12" md="10" class="value" v-if="!isEditing" >{{item.title}}</v-col>
                        <v-col cols="12" md="5" class="value pt-0" v-if="isEditing" ><v-text-field v-model="item.title"></v-text-field></v-col>
                        <v-col cols="12" md="5" class="adminaction" v-if="isEditing"><v-btn small color="error" @click="removeRecord('left', idx)">{{ loc('Remove') }}</v-btn><v-btn small color="primary" @click="addRecord('left', idx)" class="ml-4">{{ loc('Add Another After') }}</v-btn></v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="2" class="label">{{ loc('URL') }}</v-col>
                        <v-col cols="12" md="10" class="value" v-if="!isEditing" >{{item.url}}</v-col>
                        <v-col cols="12" md="5" class="value pt-0" v-if="isEditing" ><v-text-field v-model="item.url"></v-text-field></v-col>
                        <v-col cols="12" md="5" class="adminaction" v-if="isEditing"></v-col>

                    </v-row>
                </div>
            </template>


        </div> -->

        <!-- 
        <v-row class="mt-6" style="border-top: 1px solid #bbb;">
            <v-col cols="2" class="label">this.list (from local state)</v-col>
            <v-col cols="10" class="value" ><pre>{{list}}</pre></v-col>
        </v-row>
        -->
        <!-- <v-row class="mt-6" style="border-top: 1px solid #bbb;">
            <v-col cols="2" class="label">this.navlist (from props)</v-col>
            <v-col cols="10" class="value" ><pre>{{navlist}}</pre></v-col>
        </v-row>         -->
        <!--
        <v-row class="mt-6" style="border-top: 1px solid #bbb;">
            <v-col cols="2" class="label">this.navitems (from store)</v-col>
            <v-col cols="10" class="value" ><pre>{{navitems}}</pre></v-col>
        </v-row>
        <v-row>
            <v-col cols="2" class="label">Options (also from props)</v-col>
            <v-col cols="10" class="value" >{{options}}</v-col>
        </v-row> 
        -->
    </div>
</template>

<script>
    // @TODO Admins should be able to remove and add elements to the nav
import { mapState } from "vuex";
import { _size } from "lodash/size";

export default {
    name: "AdminConfigNav",
    components: {},
    props: {
        navlist: {
            type: Object,
            default: () => ({})
        },
        options: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            language: this.options.lang || 'en',
            list: false,
            isEditing: false,
        listsdefs: {
            left: {
                key: 'left',
                name: "Side Navigation"
            },
            top: {
                key: 'top',
                name: "Top Navigation"
            },
            footer1: {
                key: 'footer1',
                name: "Footer - Org Contacts"
            },
            footer2: {
                key: 'footer2',
                name: "Footer - Training",
                title: "Online Training"
            },
            footer3: {
                key: 'footer3',
                name: "Footer - About",
                title: "About"
            },
            footer4: {
                key: 'footer4',
                name: "Footer - Get Involved",
                title: "Get Involved"
            },
            social: {
                key: 'social',
                name: "Social Network Links",
                title: ""
            }
            
        }
        }
    },
    computed: {
        ...mapState(["localize","siteconfig", "navitems", "users"]),
        languages: function(){
            let l = [];
            for(let k in this.siteconfig.languages){
                l.push({text: this.siteconfig.languages[k], value: k});
            }
            return l;
        },
        
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },        
        initlist: function(opts) {
            // console.log("AdminConfigNav.initlist", opts)
            opts = opts || {};

            let l = {};
            if(this.navlist && this.navlist[this.language]){
                // if the object is present in the props, use it.
                // this is from the localized config.

                // this.list = this.navlist[this.language];
                if(!this.list || opts.reset) this.list = {};
                for(let k in this.navlist[this.language]){
                    if(!this.list[k]) this.list[k] = [];
                    for(let k1 in this.navlist[this.language][k]){
                        this.$set(this.list[k], k1, Object.assign({},this.navlist[this.language][k][k1]));
                    }
                }
            } else {
                // if the object isn't in the props, get it from the store.

                // this.list = Object.assign({}, this.navitems);
                if(!this.list || opts.reset) this.list = {};
                for(let k in this.navitems){
                    if(!this.list[k]) this.list[k] = [];
                    for(let k1 in this.navitems[k]){
                        this.$set(this.list[k], k1, Object.assign({}, this.navitems[k][k1]));
                    }
                }
            }
        },
        addRecord: function(space, idx) {
            // let x = this.list[space].slice();
            idx = (!this.list[space]) ? 0 : (this.list[space][idx]) ? idx + 1 : idx;
            if(!this.list[space]) this.list[space] = [];
            this.list[space].splice(idx, 0, { })
            this.$set(this.list[space][idx], 'title', '');
            this.$set(this.list[space][idx], 'url', '');
            this.$set(this.list[space][idx], 'key', space + Date.now().toString(36));
            this.$forceUpdate();
            console.log('inserted record', space, idx, this.list[space][idx])
        },
        removeRecord: function(space, idx) {
            this.list[space].splice(idx, 1);
            this.$forceUpdate();
            // console.log('removed record', space, idx)
        },
        saveNav: function() {
            
            this.$emit('handleAction', 'toggleLoading', true);
            let change = {
                navitems: this.siteconfig.navitems 
            };
            change.navitems[this.language] = this.list;
            // console.log("Gettin' Saved!", change);
            this.$store.dispatch('adminstore/setSiteConfig', change)
                .then((result) => {
                    setTimeout(() => {
                        this.$emit('handleAction', 'toggleLoading', false);
                        this.initlist({refresh: true});
                        this.isEditing = false;
                    }, 300);
                })

        },
        toggleEditing: function() {
            if(this.isEditing) this.initlist({reset: true});
            this.isEditing = !this.isEditing
        },
        toggleLanguage: function() {
            this.initlist({reset: true})
        }

    },
    mounted () {
        this.initlist();
    },
    watch: {
        '$route.params': {
            handler: function(s){
                // console.log('nav.watcher params triggered', s);
                this.init();
            },
            deep: true,
            immediate: false
        },
        navlist: {
            handler: function(s){
                // console.log('admin nav watcher triggered', s);
                this.initlist();
            },
            deep: true,
            immediate: false
        }
    }
}    
</script>

<style>
    .adminnavitem { border-top: 1px solid #CCCCCC; }
</style>    