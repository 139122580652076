<template>
    <div>
        <AdminTitleBar
            :title="loc('Edit Page Properties')"
            :actions="titleBarActions"
            v-on:actionhandler="handleAction"
        />         
        <v-card id="editcontentproperties" class="pb-2">
            <v-card-text class='contentform'>
                <v-row justify="space-between" v-if="isnew">
                    {{ loc("The page will not exist until you save it below.  Once saved, it is immediately available on the site.") }}
                </v-row>
                <v-row justify="space-between" v-if="isnew">
                    <v-text-field
                        name="ID"
                        v-model="record._id"
                        :label="loc('ID')"
                        :hint="loc('This is used in the default URL and the &quot;context&quot; for the page.')"
                    ></v-text-field>
                </v-row>                 
                <v-row justify="space-between">
                    <v-text-field
                        name="title"
                        v-model="record.title"
                        :label="loc('Title')"
                    ></v-text-field>
                </v-row>     
                <v-row justify="space-between" class="pb-2">
                    <v-select
                        name="lang"
                        v-model="record.lang"
                        :label="loc('Language')"
                        :items="languages"
                    ></v-select>
                </v-row>   
                <v-row justify="space-between" class="pb-2">
                    <v-text-field
                        name="description"
                        v-model="record.description"
                        :label="loc('Page Description')"
                        :hint="loc('This is used for the page meta tag')"
                        persistent-hint
                    ></v-text-field>
                </v-row>                 
                <v-row class="pt-3 pb-3">
                    <h2 >Page Styling (CSS)</h2>
                </v-row>  
                <v-row justify="space-between" class="pb-3">
                    <CodeEditor :data="record.style" :handleChange="handleStyle" mode="text/css" />
                </v-row>
                <v-divider class="mx-4 mt-3"></v-divider>                         
                <v-row class='actionrow mt-3'>
                    <v-btn small color="primary" dark @click="handleSave()" class="mr-1">{{ loc("Save Changes") }}</v-btn>
                    <v-btn small color="primary" dark @click="handleSave('close')" class="mr-1">{{ loc("Save and Go to List") }}</v-btn>
                    <v-btn small color="primary" dark @click="handleSave('page')" class="mr-1">{{ loc("Save and Go to Page") }}</v-btn>
                    <v-btn small color="secondary" dark @click="handleCancel('close')" class="mr-1">{{ loc("Cancel") }}</v-btn>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card class='geekydetails mt-12 elevation-2'>
            <pre>{{record}}</pre>
        </v-card>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import AdminTitleBar from "./Admin_TitleBar";
import CodeEditor from './Shared_Editor_Code';

export default {
    name: "AdminContentsEditPage",
    props: {
        // list: { type: Array },
        // editId: { type: String },
        contentRecord: { type: Object }
    },
    components: { AdminTitleBar, CodeEditor },
    data() {
        return {
            record: this.contentRecord,
            isnew: this.contentRecord._id === 'new',
            titleBarActions: [
                { text: "Close", key: "close" },
                { text: "Go to Page", key: "gotopage" }
            ], 
        }
    },
    computed: {
        ...mapState(['users', 'localize', 'adminstore']),
        languages: function(){
            let list = (this.adminstore && this.adminstore.siteconfig && this.adminstore.siteconfig.languages) || {en:"English"};
            let out = [];
            for(let k in list){
                out.push({ value: k, text: list[k] });
            }
            return out;
        }
    }, 
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },   
        handleAction: function(action, params){
            this.$emit("actionhandler", action, params);
        },
        handleDescriptionChange: function(val){
            console.log(val);
            this.record.description = val;
        },
        handleDescriptionClose: function(val){

        },
        handleSave: function(val){
            console.log("AdminContentEditPage.handleSave", val, this.record, this.record.context);
            if(val === 'page') val = "/" + this.record._id;
            this.record.context = this.record._id;
            if(!this.record.lang){
                this.record.lang = "en";
            }
            this.$emit("actionhandler", "save", this.record, val);
        },
        handleCancel: function(val){
            this.$emit("actionhandler", 'close', val)
        },
        handleStyle: function(val, a, b){
            // console.log('style field updated', a, b, val);
            this.record.style = val;
        }

    }
  
}
</script>