<template>
    <v-container>
        <div v-if="authError">
            {{ loc('Please Login') }}
        </div>
        <div v-else-if="!users.currentUser || !users.currentUser.isAdmin" >
            {{ loc("You do not have permission to do that.") }}
        </div>    
        <div v-else-if="isLoading">
            {{ loc('Loading...') }}
        </div>            
        <div v-else-if="vcrmId && manageRecord === 'vcrmIndividual'">
            <showVcrmIndividual
                :id="vcrmId"
                :userRecord="userRecord"
                v-on:actionhandler="handleAction"
                :stage="vcrmStage"
            />
        </div>
        <div v-else-if="manageRecord === 'userRecord'">
            <AdminUserEdit :userRecord="userRecord" v-on:actionhandler="handleAction" />
        </div>
        <div v-else-if="manageRecord === 'removeUser'">
            <AdminUserRemove :userRecord="userRecord" v-on:actionhandler="handleAction" />
        </div>
        <div v-else-if="users.currentUser">
            <AdminTitleBar
                :title="loc('Manage Users')"
                :actions="titleBarActions"
                v-on:actionhandler="handleAction"
            />
            <!-- <div v-if="!userArray || !userArray.length">User list is loading...</div> -->
            <v-row v-if="tablePrefs">
                <div v-for="(item, idx) in headers" v-bind:key="idx">
                    <v-checkbox style="min-width: 120px;"
                        :name="idx"
                        v-model="item.show"
                        :label="loc(item.text)"
                    ></v-checkbox>
                </div>
            </v-row>
            <div v-if="userArray">
                <div class="userlistwrapper">
                    <v-row class="searchrow">
                        <v-select :items="filters" v-model="filterList" label="Filters" class="ml-3" ></v-select>
                        <v-spacer></v-spacer>
                        <v-text-field
                            v-model="search"
                            append-icon="search"
                            :label="loc('Search within filtered list')"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-row>
                    <v-data-table
                        :items-per-page="itemsPerPage"
                        :headers="tableHeaders"
                        :footer-props="{ itemsPerPageOptions:[10,20,30,40,50,100,200] }"
                        :items="userArray"
                        :search="search"
                        class="userList elevation-1 my-8 pb2 "
                    >
                        <template v-slot:[`header.actions`]="{header}">
                            {{header.text}}
                            <span @click="tablePrefs=!tablePrefs" class="dtableactionicon"><v-icon small >edit</v-icon> {{ loc("Columns") }}</span>
                        </template>
                        <template v-slot:[`item.classcount`]="{ item }" class='countcolumn'>
                            {{ countthem(item.classes) }}
                        </template>
                        <template v-slot:[`item.certificationscount`]="{ item }" class='countcolumn'>
                            {{ countthem(item.certifications) }}
                        </template>
                        <template v-slot:[`item.actions`]="{ item }" class="actioncolumn">
                            <span class="manageusericons">
                                <v-btn small color="primary" dark @click="handleEdit(item)">{{ loc("Edit") }}</v-btn>
                                <v-btn small color="error" dark @click="handleRemove(item)">{{ loc("Remove") }}</v-btn>
                            </span>
                            <span v-if="showvcrm" class="managevcrmicons">
                                <v-btn small :color="(item.vcrmContactId) ? 'green' : 'secondary'" dark @click="handleVcrm(item)" >{{ loc("VCRM") }}</v-btn>
                            </span>
                        </template>
                    </v-data-table>
                </div>
            </div>
            <div v-else>
                {{ loc('No data yet.') }}
            </div>
        </div>
    </v-container>
</template>

<script>

import { mapState } from "vuex";
import config_system from '../config/system';
import AdminTitleBar from "./Admin_TitleBar";
import showVcrmIndividual from "./Admin_Integration_Virtuous_Contact";
import AdminUserEdit from "./Admin_Users_Edit";
import AdminUserRemove from "./Admin_Users_Remove";
import _filter from 'lodash/filter';
import { filterDate, filterId } from '../lib/filter';

export default {
    name: "AdminUsers",
    components: { AdminTitleBar, showVcrmIndividual, AdminUserEdit, AdminUserRemove },
    data() {
        return {
            isLoading: false,
            showvcrm : config_system.integrations.vcrm,
            itemsPerPage: 15,
            titleBarActions: [
                { text: "Export Users", key: "csvexport", "href": "/app_reports/users/users.csv" },
                { text: "Export Enrollment", key: "enrollmentexport", "href": "/app_reports/enrollment/enrollment.csv" },
                { text: "Refresh List", key: "refresh" },
                { text: "Add User", key: "add" }
            ],
            // dataview: 'cd',
            vcrmId: false,
            userId: false,
            userRecord: false,
            vcrmStage: false,
            search: "",
            filterList: 'Active',
            manageRecord: false,
            tablePrefs: false,
            headers: {
                _id: {
                    text: "ID",
                    value: "_id"
                },
                firstname: {
                    text: "First Name",
                    align: "start",
                    sortable: true,
                    value: "firstname",
                    show: true
                },
                lastname: {
                    text: "Last Name",
                    align: "start",
                    sortable: true,
                    value: "lastname",
                    show: true
                },
                email: {
                    text: "Email",
                    align: "start",
                    sortable: true,
                    value: "email",
                    show: true
                },
                created: {
                    text: "Created",
                    alight: "start",
                    sortable: true,
                    value: "created",
                    show: true
                },
                lastseen: {
                    text: "Last Seen",
                    alight: "start",
                    sortable: true,
                    value: "lastseen",
                    show: true
                },
                address1: {
                    text: "Address 1",
                    value: "address1"
                },
                // address2: {
                //     text: "Address 2",
                //     value: "address2"
                // },
                city: {
                    text: "City",
                    value: "city"
                },
                state: {
                    text: "State",
                    value: "state"
                },
                country: {
                    text: "Country",
                    value: "country"
                },
                gender: {
                    text: "Gender",
                    value: "gender"
                },
                // postal: {
                //     text: "Postal",
                //     value: "postal"
                // },
                lang: {
                    text: "Language",
                    value: "lang"
                },
                classcount: {
                    text: "Classes",
                    value: "classcount"
                },
                certificationscount: {
                    text: "Certifications",
                    value: "certificationscount"
                }
            },
            filters: [
                { value: "Active", text: "Active within past year"},
                { value: "Admins", text: "Administrators" },
                { value: "Certified", text: "Certified Users" },
                { value: "All", text: "Show All"}
            ]
        };
    },
    computed: {
        ...mapState(["authError", "users", "localize"]),
        tableHeaders: function() {
            var temp = Object.assign({}, this.headers);
            let columns = []
            for(let k in temp){
                if(temp[k].show){
                    temp[k].sortable = true;
                    temp[k].align = temp[k].align || "start";
                    columns.push(temp[k]);
                }
            }

            columns.push({ text: "Actions", sortable: false, value: "actions" });
            return columns;
        },
        userArray: function() {
            // console.log(Object.keys(this.users.list).slice(0,10));
            if(!this.users || !this.users.list) return [];
            let userList;
            switch(this.filterList){
                case 'All':
                    userList = Object.values(this.users.list);
                    break;
                case 'Admins':
                    userList = _filter(this.users.list, (o) => { return o.roles && (o.roles.cms || o.roles.admin); });
                    break;
                case 'Certified':
                    userList = _filter(this.users.list, (o) => {return o.certifications && Object.keys(o.certifications).length });
                    break;
                case 'Active':
                default:
                    {
                        let sincets = Date.now() - 31536000000;
                        let sincedate = filterDate(sincets);
                        userList = _filter(this.users.list, (o) => { 
                            return o.lastseen && ( o.lastseen > sincedate  ); });
                    }
                    break;
            }
            // for(let i=0;i<listlength;i++){
            //     userList[i].actions = "";
            // }
            return userList;
        }
    },
    methods: {
        getList: function() {
            if(this.isLoading){ console.log("Already is Loading (adminusers.getList"); return;}
            this.isLoading = true;
            let data = (this.filterList === "Active") ? {active:1} : {};
            this.$store.dispatch("users/getList", data)
                .then((result) => {
                    this.isLoading = false;
                    console.log("getList result:", result);
                    // console.log(this.$route.params)
                    // if(this.$route.params.id){
                    if(this.userId){
                        // console.log("Is this an id?", this.userId);
                        if(result[this.userId]){ 
                            this.userRecord = result[this.userId];
                            if(this.manageRecord) this.handleAction(this.manageRecord, this.userRecord);
                        }
                    }
                })
                .catch((e) => {
                    console.log("ERROR in getList", e);
                    this.isLoading = false;
                })
                ;
            // this.$store.dispatch("classes_getList");
            // this.$store.dispatch("courses_getList");
            // .then(() => {
            //     console.log(this.userList);
            // })
        },
        handleAction: function(action, params) {
            switch (action) {
                case "add":
                    this.handleAdd(params);
                    break;
                case "addBatch":
                    this.handleAddBatch(params);
                    break;
                case "close":
                    this.handleClose(params);
                    break;
                case "userRecord":
                case "edit":
                    this.handleEdit(params);
                    break;
                case "export":
                    this.handleExport(params);
                    break;
                case "refresh":
                    this.getList(params);
                    break;
                case "removeUser":
                    this.handleRemove(params);
                    break;
                case "removeUserConfirm":
                    this.handleRemoveConfirm(params);
                    break;
                case "syncRecords":
                    this.handleSyncRecords(params);
                    break;
            }
        },
        handleAdd: function(evt) {
          // console.log("handleAdd", evt);
            let record = {
                _id: "new",
                firstname: "",
                lastname: ""
            };
            this.userRecord = record;
            this.manageRecord = "userRecord";
        },
        handleAddBatch: function(evt) {
            let query = { _id: this.userRecord._id };
          // console.log("handleAddBatch", query);

            this.$store
                .dispatch("vcrm_postBatch", query)
                .then(result => {
                  // console.log("handleAddBatch result", result);
                })
                .catch(error => {
                  // console.log("Error in handleAddBatch", error);
                });
        },
        handleClose: function(evt) {
            console.log("handleClose", evt);
            this.vcrmId = false;
            this.userId = false;
            this.userRecord = false; 
            this.manageRecord = false;
            let newpath = "/admin/users";
            if(this.$route.path !== newpath){
                this.$router.push(newpath);
            }
        },
        handleEdit: function(record) {
          // console.log("handleEdit", record);
            if(record.record === "userRecord") {
                record = this.userRecord;
            } else {
                this.userRecord = record;
            }
            console.log("handleEdit", record);
            this.manageRecord = "userRecord";
            // console.log('route at handleEdit', this.$route)
            let newpath = "/admin/users/"+record._id;
            if(this.$route.path !== newpath){
                this.$router.push(newpath);
            }
        },
        handleExport: function(evt) {
            this.$store.dispatch("users/export");
          // console.log("handleExport", evt);
        },
        handleRemove: function(evt) {
          // console.log("handleReomve", evt);
            this.userRecord = evt;
            this.manageRecord = "removeUser";
        },
        handleRemoveConfirm: function(evt) {
            this.$store.dispatch("users/remove", evt );
            this.handleClose();
        },
        handleSyncRecords: function(evt, options) {
          // console.log("handleSyncRecords", evt, this.vcrmId, this.userRecord);

            let query = {};
            query._id = this.userRecord._id;
            if (this.userRecord && this.userRecord.vcrmIndividualId) {
                query.id = this.userRecord.vcrmIndividualId;
            } else if (this.userRecord && this.userRecord.email) {
                query.email = this.userRecord.email;
            } else if (this.vcrmId) {
                query.email = this.vcrmId;
            }
            if (!query._id || (!query.id && !query.email)) {
              // console.log("ERROR, missing required parameters", query);
                return false;
            }

            this.$store.dispatch("integrationVcrm/syncByEmail", query)
        },
        handleVcrm: function(record) {
          // console.log("handleVcrm", evt);
            this.vcrmId = record.vcrmIndividualId || record.email;
            // this.manageRecord = "vcrmIndividual";
            this.userRecord = record;
            let newpath = "/admin/users/"+record._id+"/vcrmIndividual";
            if(this.$route.path !== newpath){
                this.$router.push(newpath);
            }            
        },
        countthem: function(obj) {
            console.log(obj);
            if(!obj || typeof obj !== "object") return 0;
            return Object.keys(obj).length;
        },
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        }, 
        init: function () {
            console.log("adminUsers.init", this.$route.params)
            let path = this.$route.params.pathMatch;
            if(path && typeof path === "string" ){
                let p = path.split("/");
                if(p[0] && p[0] === filterId(p[0])) this.userId = p[0];
                this.manageRecord = (p[1] && p[1] === filterId(p[1])) ? p[1] : 'userRecord';
            }

            this.getList()
        }  
    },
    mounted() {
        this.init();        
    },
    watch: {
        '$route.params.id': {
            handler: function(){
                console.log("admin watcher triggered", this.$route.params);
                this.init()
            },
            deep: true,
            immediate: false
        },
        filterList: function(){ if(this.filterList === "All"){ this.getList(); } }
    }    
};
</script>

